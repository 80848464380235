export const languageMapping: { [key: string]: number } = {
  en: 1,
  fr: 2,
  de: 3,
  es: 1, // TO DO LATER
};

export const getLanguageNumber = (languageCode: string): number => {
  return languageMapping[languageCode] || 1;
};
