import React from "react";
import "./detail-card-double.scss";

interface IconProps {
    src: string;
    alt: string;
    onClick?: () => void;
}

type DetailCardIcon = IconProps | React.ReactElement;

interface DetailCardProps {
    hasHeader?: boolean;
    headerTitle?: string;
    hasSubtitle?: boolean;
    subtitleContent?: string;
    iconLeft?: DetailCardIcon | DetailCardIcon[] | null;
    iconRight?: DetailCardIcon | DetailCardIcon[] | null;
    iconSize?: "small" | "medium" | "big";
    children: React.ReactNode;
    themeColor: string;
    extraTopComponent?: React.ReactNode;
    bottomLeftInfo?: string;
    bottomRightInfo?: string;
}

interface DoubleDetailCardProps {
    leftCardProps: DetailCardProps;
    rightCardProps: DetailCardProps;
}

const renderIcons = (iconProp: DetailCardIcon | DetailCardIcon[], iconClass: string) => {
    const icons = Array.isArray(iconProp) ? iconProp : [iconProp];
    return icons.map((icon, index) => {
        if (React.isValidElement(icon)) {
            return <div key={index}>{icon}</div>;
        } else if (icon && typeof icon === "object" && "src" in icon && "alt" in icon) {
            return <img key={index} className={iconClass} src={icon.src} alt={icon.alt} onClick={icon.onClick}/>;
        }
        return null;
    });
};

const DetailCard: React.FC<DetailCardProps> = (props: DetailCardProps) => {
    const iconClass = `detail-card__icon icon--${props.iconSize || "medium"}`;

    return (
        <div className="detail-card">
            {props.extraTopComponent && <div className="detail-card__extra-component">{props.extraTopComponent}</div>}
            {props.hasHeader && (
                <div className="detail-card__header">
                    {props.iconLeft && <div
                        className="detail-card__icon-container--left">{renderIcons(props.iconLeft, iconClass)}</div>}
                    {props.headerTitle && <div className="detail-card__title">{props.headerTitle}</div>}
                    {props.iconRight && <div
                        className="detail-card__icon-container--right">{renderIcons(props.iconRight, iconClass)}</div>}
                </div>
            )}
            {props.hasSubtitle && props.subtitleContent && (
                <div className="detail-card__subtitle" style={{color: props.themeColor}}>
                    {props.subtitleContent}
                </div>
            )}
            <div className="detail-card__content">{props.children}</div>
            <div className="detail-card__footer">
                {props.bottomLeftInfo && <div>{props.bottomLeftInfo}</div>}
                {props.bottomRightInfo && <div>{props.bottomRightInfo}</div>}
            </div>
        </div>
    );
};

const DoubleDetailCard: React.FC<DoubleDetailCardProps> = ({leftCardProps, rightCardProps}) => {
    return (
        <div className="double-detail-card">
            <div className="double-detail-card__left">
                <DetailCard {...leftCardProps} />
            </div>
            <div className="double-detail-card__right">
                <DetailCard {...rightCardProps} />
            </div>
        </div>
    );
};

export default DoubleDetailCard;
