import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";

import {useAnalytics} from "../analytics-context";

import "./activities-analytics.scss";
import DetailCard from "../../../components/detailCard/detail-card";
import {getLanguageNumber} from "../../../helper/common-helpers/language-helper";
import {ActivitiesAnalyticsProps} from "../../../interfaces/analytics-interfaces/activities-interface";
import {useHistoryData} from "../../../hooks/use-history-data";
import {
    CategoryGameIconDetails,
    CategoryVideoIconDetails,
} from "../../../constants/recommendation-constants";
import {useHistorySkillProgressData} from "../../../hooks/use-history-skill-progress-data";
import {MergedSkillContentData} from "../../../interfaces/analytics-interfaces/history-interface";
import {
    filterAndSortSkillActivitiesData,
    getCompletedGameAndGameSkillData,
    getCorrectQuizAndMediaSkillData,
    getGameVsMediaProgress,
} from "../../../helper/analytics-helpers/activities-helper";
import SkillProgressBar from "../../../components/skill-progress-bar/skill-progress-bar";
import {SkillWithItemCount} from "../../../interfaces/skill-map-interfaces/skill-map-interface";
import AdvancedProgressBar from "../../../components/progress-bar/advanced-progress-bar";
import {
    MedalOrangeIconDetails,
    MedalBlueIconDetails,
} from "../../../constants/activities-constants";
import AnalyticsLoading from "../../../components/analytics/analytics-loading";

const ActivitiesAnalytics: React.FC<ActivitiesAnalyticsProps> = (
    props: ActivitiesAnalyticsProps
) => {
    const {t, i18n} = useTranslation();
    const actualLanguage = i18n.language;
    const languageNumber = getLanguageNumber(actualLanguage);
    const {isLoading, error, contentStatisticsData, fetchContentStatistics} =
        useAnalytics();

    const [filteredSkillProgressData, setFilteredSkillProgressData] = useState<
        MergedSkillContentData[]
    >([]);
    const [gameVsMediaProgress, setGameVsMediaProgress] = useState<{
        gamePercentage: number;
        mediaPercentage: number;
    }>({gamePercentage: 0, mediaPercentage: 0});
    const [quizAndMediaSkillData, setQuizAndMediaSkillData] = useState<any>({});
    const [completedGameAndGameSkillData, setCompletedGameAndGameSkillData] =
        useState<any>({});

    const {gamePercentage, mediaPercentage} = gameVsMediaProgress;
    const {correctQuizPercentage, mediaSkills} = quizAndMediaSkillData;
    const {completedGamesPercentage, gameSkills} =
        completedGameAndGameSkillData;

    const {isProcessingHistoryData, historyData} = useHistoryData({
        childrenData: props.currentChildSelected,
        actualLanguage: languageNumber,
        dateLimit: props.dateFilter,
    });

    const {isProcessingHistorySkillProgressData, historySkillProgressData} =
        useHistorySkillProgressData({
            childrenData: props.currentChildSelected,
            actualLanguage: languageNumber,
        });

    useEffect(() => {
        fetchContentStatistics();
    }, []);

    useEffect(() => {
        if (historyData.length > 0) {
            const gameProgress = getGameVsMediaProgress(historyData);
            setGameVsMediaProgress(gameProgress);
        }
    }, [historyData]);

    useEffect(() => {
        if (historyData.length > 0 && historySkillProgressData.length > 0) {
            const sortedFilteredData = filterAndSortSkillActivitiesData(
                historyData,
                historySkillProgressData
            );

            setFilteredSkillProgressData(sortedFilteredData);
        }
    }, [historyData, historySkillProgressData]);

    useEffect(() => {
        if (historyData.length > 0 && filteredSkillProgressData.length > 0) {
            const quizData = getCorrectQuizAndMediaSkillData(
                historyData,
                filteredSkillProgressData
            );
            setQuizAndMediaSkillData(quizData);

            const gameData = getCompletedGameAndGameSkillData(
                historyData,
                filteredSkillProgressData
            );
            setCompletedGameAndGameSkillData(gameData);
        }
    }, [historyData, filteredSkillProgressData]);

    const mapMediaSkillToSkill = (
        mediaSkillData: MergedSkillContentData,
        itemCount: number
    ): SkillWithItemCount => ({
        id: mediaSkillData.skillId,
        name: mediaSkillData.contentCategory,
        progress: mediaSkillData.skillProgress,
        level: mediaSkillData.skillLevel,
        itemCount,
    });

    const renderProgressBars = (
        progressData: Array<MergedSkillContentData> = [],
        themeColor: string
    ) =>
        progressData.map((data, index) => {
            const skillData = mapMediaSkillToSkill(data, data.itemCount);
            const uniqueKey = `sk-id-${skillData.id}-name-${skillData.name}-content-${data.contentId}`;
            const colorToUse = themeColor || "#823d90";

            return (
                <SkillProgressBar
                    key={uniqueKey}
                    value={skillData.progress}
                    themeColor={colorToUse}
                    skillData={skillData}
                    showEyeIcon={false}
                    barWidth={"small"}
                />
            );
        });

    const renderLoadingOrError = () => {
        if (error) return <p style={{color: "red"}}>Error: {error.message}</p>;
        if (
            isLoading ||
            isProcessingHistoryData ||
            isProcessingHistorySkillProgressData
        ) {
            return <AnalyticsLoading/>;
        }
        if (historyData.length === 0) {
            return (
                <div style={{textAlign: "center", margin: "20px 0"}}>
                    <p>{t(`analyticsPages.activities_page.no_activity_period`)}</p>
                </div>
            );
        }
        return null;
    };

    return (
        <>
            {renderLoadingOrError() || (
                <>
                    {/* Activities Card */}
                    <div className="analytics__stats-content--card">
                        <DetailCard
                            hasHeader={true}
                            headerTitle={t(`analyticsPages.activities_page.video_vs_game`)}
                            iconLeft={[CategoryVideoIconDetails]}
                            iconRight={[CategoryGameIconDetails]}
                            themeColor="#823d90"
                        >
                            <AdvancedProgressBar
                                completed={Number(mediaPercentage?.toFixed(2))}
                                barSize="medium"
                                leftbgcolor="#e84320"
                                rightbgcolor="#345fab"
                                bottomLeftTextColor="#e84320"
                                bottomRightTextColor="#345fab"
                                worldMedian={
                                    contentStatisticsData.object[0].mediaVsGamePlayedMed ||
                                    contentStatisticsData.object[0].mediaVsGamePlayedAvg
                                }
                                infoBottomLeft={
                                    <>
                                        <p>
                                            {t(`analyticsPages.activities_page.video`)}
                                            {Math.round(mediaPercentage)}%
                                        </p>
                                    </>
                                }
                                infoBottomRight={
                                    <>
                                        <p>
                                            {t(`analyticsPages.activities_page.game`)}
                                            {Math.round(gamePercentage)}%
                                        </p>
                                    </>
                                }
                            />
                        </DetailCard>
                    </div>

                    {/* Quiz Performance Card */}
                    <div className="analytics__stats-content--card">
                        <DetailCard
                            hasHeader={true}
                            headerTitle={t(`analyticsPages.activities_page.quiz_performance`)}
                            iconLeft={[CategoryVideoIconDetails]}
                            iconRight={[MedalOrangeIconDetails]}
                            themeColor="#823d90"
                        >
                            <AdvancedProgressBar
                                completed={Number(correctQuizPercentage?.toFixed(2))}
                                barSize="medium"
                                leftbgcolor="#e84320"
                                bottomLeftTextColor="#e84320"
                                infoBottomLeft={
                                    <>
                                        <p>
                                            {t(`analyticsPages.activities_page.quiz_correct`)}
                                            {Math.round(correctQuizPercentage)}%
                                        </p>
                                    </>
                                }
                            />
                            <div className="detail-card__content--progress-bars">
                                <p className="detail-card__content--progress-bars--header">
                                    {/* {t(`analyticsPages.activities_page.details`)} */}
                                </p>
                                {renderProgressBars(mediaSkills, "#ff5733")}
                            </div>
                        </DetailCard>
                    </div>

                    {/* Game Performance Card */}
                    <div className="analytics__stats-content--card">
                        <DetailCard
                            hasHeader={true}
                            headerTitle={t(`analyticsPages.activities_page.game_performance`)}
                            iconLeft={[CategoryGameIconDetails]}
                            iconRight={[MedalBlueIconDetails]}
                            themeColor="#823d90"
                        >
                            <AdvancedProgressBar
                                completed={completedGamesPercentage?.toFixed(2)}
                                barSize="medium"
                                leftbgcolor="#345fab"
                                bottomLeftTextColor="#345fab"
                                infoBottomLeft={
                                    <>
                                        <p>
                                            {t(`analyticsPages.activities_page.game_completed`)}
                                            {Math.round(completedGamesPercentage)}%
                                        </p>
                                    </>
                                }
                            />
                            <div className="detail-card__content--progress-bars">
                                <p className="detail-card__content--progress-bars--header">
                                    {/* {t(`analyticsPages.activities_page.details`)} */}
                                </p>
                                {renderProgressBars(gameSkills, "#345fab")}
                            </div>
                        </DetailCard>
                    </div>
                </>
            )}
        </>
    );
};

export default ActivitiesAnalytics;
