import React, { useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { ResetPasswordParams } from "../../interfaces/auth-interfaces/auth-interface";
import URLS from "../../config/api-urls";

import "./auth.scss";

type FormValues = {
  password: string;
  confirmPassword: string;
};

const ReinitPassword: React.FC = () => {
  const {
    token: tokenURL,
    email: emailURL,
    username: userName,
  } = useParams<ResetPasswordParams>();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>();

  const [isChildReset, setIsChildReset] = useState<boolean>(!!userName);
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    setIsLoading(true);
    setErrorMessage("");

    if (data.password !== data.confirmPassword) {
      setErrorMessage(t("forms.error.passwords_not_matching"));
      setIsLoading(false);
      return;
    }

    const apiUrl = isChildReset
      ? URLS.POST_ReinitPasswordChild
      : URLS.POST_ReinitPasswordParent;

    try {
      await axios.post(apiUrl, {
        tokenreset: tokenURL,
        email: emailURL,
        username: isChildReset ? userName : undefined,
        password: data.password,
      });
      setIsSuccessful(true);
      setErrorMessage("");
    } catch (error: any) {
      setErrorMessage(error.response?.data || t("forms.error.server_error"));
    }

    setIsLoading(false);
  };

  return (
    <div className="auth__container">
      <h2 className="auth__title">{t("resetPasswordPage.title")}</h2>
      <p className="auth__info-paragraph">
        {t("resetPasswordPage.info_account")} {emailURL}
        <br />
        {isChildReset && (
          <>
            {t("resetPasswordPage.info_child")} {userName}
          </>
        )}
      </p>
      {!isSuccessful ? (
        <form className="auth__form" onSubmit={handleSubmit(onSubmit)}>
          <label className="auth__label">
            {t("resetPasswordPage.new_password_label")}
            <input
              type="password"
              {...register("password", {
                required: true,
                maxLength: 32,
                minLength: 4,
              })}
              className="auth__input"
            />
          </label>
          {errors.password && (
            <p className="auth__error-message">
              {t("forms.error.invalid_password")}
            </p>
          )}

          <label className="auth__label">
            {t("resetPasswordPage.confirm_password_label")}
            <input
              type="password"
              {...register("confirmPassword", {
                required: true,
                maxLength: 32,
                minLength: 4,
              })}
              className="auth__input"
            />
          </label>
          {errors.confirmPassword && (
            <p className="auth__error-message">
              {t("forms.error.invalid_confirm_password")}
            </p>
          )}

          <button
            type="submit"
            className="auth__submit-button"
            disabled={isLoading}
          >
            {isLoading
              ? t("resetPasswordPage.submit_button_loading")
              : t("resetPasswordPage.submit_button_default")}
          </button>
        </form>
      ) : (
        <div className="auth__server-response">
          {t("resetPasswordPage.server_response_success")}
        </div>
      )}

      {errorMessage && <p className="auth__error-message">{t(errorMessage)}</p>}
    </div>
  );
};

export default ReinitPassword;
