import React, { useEffect, useMemo, useState } from "react";

import "./recommendation-table.scss";
import {
  StarBlueDetails,
  StarRoseDetails,
  StarYellowDetails,
} from "../../../constants/skill-map-constants";
import {
  FeedBackIconDetails,
  ShowMoreIconDetails,
  CategoryGameIconDetails,
  CategoryVideoIconDetails,
} from "../../../constants/recommendation-constants";
import {
  aggregateSkillsByLevel1Id,
  getThemeDetails,
} from "../../../helper/analytics-helpers/recommendations-helpers/recommendations-helper";
import {
  ActivityProps,
  ContentsWithSkillsAndPercentage,
  IconButtonProps,
  RecommendationTableProps,
  StarProps,
  TableRowProps,
} from "../../../interfaces/recommendations-interfaces/recommendations-interface";
import RecommendationPopup from "./recommendation-popup";

const Star: React.FC<StarProps> = ({ number, iconDetails }) => {
  return (
    <div className="star-container">
      <img src={iconDetails.src} alt={iconDetails.alt} className="star-image" />
      <span className="star-number">{number}</span>
    </div>
  );
};

const Activity: React.FC<ActivityProps> = (props: ActivityProps) => {
  return (
    <div className="activity-info-container">
      <img
        src={props.categoryIcon.src}
        alt={props.categoryIcon.alt}
        className="category-icon"
      />
      <div>
        <span className="activity-info-container__title">{props.title}</span>
        <span className="activity-info-container__category">
          {props.category}
        </span>
      </div>
    </div>
  );
};

const IconButton: React.FC<IconButtonProps> = (props: IconButtonProps) => {
  return (
    <button className="icon-button" onClick={props.onClick}>
      <img
        src={props.iconDetails.src}
        alt={props.iconDetails.alt}
        className="icon-button__image"
        style={props.isRecommendationSent ? { opacity: "0.25" } : {}}
      />
    </button>
  );
};

const TableRow: React.FC<TableRowProps> = ({
  starNumbers,
  activityTitle,
  activityCategory,
  activityCategoryIcon,
  activityFeedback,
  contentId,
  currentChildSelected,
  referentData,
}) => {
  const skillIdToStarDetails = {
    1: StarBlueDetails,
    2: StarRoseDetails,
    3: StarYellowDetails,
  };
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [popupData, setPopupData] = useState(null);
  const [isRecommendationSent, setIsRecommendationSent] = useState(false);

  // const feedbackColor = getFeedbackColor(activityFeedback); // now wanted for now

  const themeDetails = getThemeDetails(activityCategoryIcon.alt);
  const sendRecommendationIconDetails = themeDetails.iconDetails;
  const themeColor = themeDetails.color;

  return (
    <tr className="recommendation-table__row">
      <td className="recommendation-table__cell--star">
        {starNumbers.map((skill) => {
          const starDetails = skillIdToStarDetails[skill.skillLevel1Id];
          return (
            <Star
              key={skill.skillLevel1Id}
              number={skill.skillPoint}
              iconDetails={starDetails}
            />
          );
        })}
      </td>
      <td className="recommendation-table__cell--activity">
        <Activity
          title={activityTitle}
          category={activityCategory}
          categoryIcon={activityCategoryIcon}
        />
      </td>
      <td className="recommendation-table__cell" style={{ color: "green" }}>
        {activityFeedback}%
      </td>
      <td className="recommendation-table__cell--recommend">
        <IconButton
          iconDetails={sendRecommendationIconDetails}
          isRecommendationSent={isRecommendationSent}
          onClick={() => {
            if (!isRecommendationSent) {
              setIsPopupVisible(true);
              setPopupData({
                contentId,
                childId: currentChildSelected.childId,
                activityTitle,
                activityCategory,
                activityCategoryIcon,
                themeColor,
              });
            }
          }}
        />
        {isPopupVisible && (
          <RecommendationPopup
            setIsPopupVisible={setIsPopupVisible}
            setIsRecommendationSent={setIsRecommendationSent}
            popupData={popupData}
            currentChildSelected={currentChildSelected}
            referentData={referentData}
          />
        )}
      </td>
    </tr>
  );
};

const RecommendationTable: React.FC<RecommendationTableProps> = (
  props: RecommendationTableProps
) => {
  const [rowsToShow, setRowsToShow] = useState(props.rowsToInitiallyShow);
  const [aggregatedContents, setAggregatedContents] = useState<
    ContentsWithSkillsAndPercentage[]
  >([]);

  useEffect(() => {
    if (props.contents && props.contentsFeedback) {
      const newAggregatedContents = props.contents.map((content) => {
        const matchingFeedback = props.contentsFeedback.find(
          (feedback) => feedback.contentId === content.contentId
        );

        const percentage = matchingFeedback ? matchingFeedback.percentage : 0;

        return {
          ...content,
          percentage,
          skills: content.skills
            ? aggregateSkillsByLevel1Id(content.skills)
            : [],
        };
      });
      newAggregatedContents.sort((a, b) => b.percentage - a.percentage);
      setAggregatedContents(newAggregatedContents);
    }
  }, [props.contents, props.contentsFeedback]);

  const slicedAggregatedContents = useMemo(
    () => aggregatedContents.slice(0, rowsToShow),
    [aggregatedContents, rowsToShow]
  );

  return (
    <table className="recommendation-table">
      <thead className="recommendation-table__head">
        <tr className="recommendation-table__row">
          <th className="recommendation-table__header">Skills</th>
          <th className="recommendation-table__header">Name</th>
          <th className="recommendation-table__header">
            <img
              className="recommendation-table__header--icon"
              src={FeedBackIconDetails.src}
              alt={FeedBackIconDetails.alt}
            />
          </th>
          <th className="recommendation-table__header">Sent</th>
        </tr>
      </thead>
      <tbody className="recommendation-table__body">
        {slicedAggregatedContents.map((content, index) => (
          <TableRow
            key={index}
            starNumbers={content.skills || []}
            activityTitle={content.name ?? "N/A"}
            activityCategory={content.category ?? "N/A"}
            activityCategoryIcon={
              content.format === "Games" || content.format === "Game"
                ? CategoryGameIconDetails
                : CategoryVideoIconDetails
            }
            activityFeedback={content.percentage ?? 0}
            contentId={content.contentId}
            currentChildSelected={props.currentChildSelected}
            referentData={props.referentData}
          />
        ))}
      </tbody>
      {rowsToShow < props.contents?.length && (
        <tfoot className="recommendation-table__foot">
          <tr>
            <td colSpan={4}>
              <IconButton
                iconDetails={ShowMoreIconDetails}
                onClick={() => {
                  setRowsToShow((prev) => prev + props.rowsToAdd);
                }}
              />
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default RecommendationTable;
