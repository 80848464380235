import { MenuItem } from "../../interfaces/analytics-interfaces/analytics-interface";

export const getCurrentMenuData = (pathname: string, menuItems: MenuItem[]) => {
  const currentMenuItem = menuItems.find((item) =>
    pathname.includes(item.route)
  );
  return { currentMenuItem };
};

export const getFormattedDate = () => {
  const currentDate = new Date();
  return `${currentDate.toLocaleString("en-US", {
    month: "long",
  })} ${currentDate.getDate()}, ${currentDate.getFullYear()}`;
};
