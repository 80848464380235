import React, { useState } from "react";
import "./skill-map-tabs.scss";

type ButtonInfo = {
  label: string;
  level: number;
  parentId: number | null;
  imageSrc: string;
  activeImageSrc: string;
  size: "small" | "medium";
};

interface SkillMapTabsProps {
  buttons: ButtonInfo[];
  updateSelectedParentIdByLevel: (
    level: number,
    parentId: number | null
  ) => void;
  selectedParentIdByLevel: { [key: number]: number | null };
}

const SkillMapTabs: React.FC<SkillMapTabsProps> = ({
  buttons,
  updateSelectedParentIdByLevel,
  selectedParentIdByLevel,
}) => {
  const isActiveButton = (button: ButtonInfo) => {
    const parentId = selectedParentIdByLevel[1];

    if (parentId === undefined && button.parentId === null) {
      return true;
    }

    return parentId === button.parentId;
  };

  const formatLabel = (label: string | undefined) => {
    if (!label) {
      return "";
    }
    const newText = label.trim().replace(/^\d+\s?- /, "");
    return newText.toUpperCase();
  };

  const handleButtonClick = (level: number, parentId: number | null) => {
    updateSelectedParentIdByLevel(level, parentId);
  };

  return (
    <div className="skill-map-container">
      <div className="skill-map-container__button-container">
        {buttons.map((button, index) => (
          <div
            key={index}
            className={`skill-map-container__button-wrapper skill-map-container__button-wrapper--${button.size}`}
            onClick={() => handleButtonClick(button.level, button.parentId)}
          >
            <img
              className={`skill-map-container__button-wrapper__image ${
                isActiveButton(button)
                  ? "skill-map-container__button-wrapper__image--active"
                  : ""
              }`}
              src={
                isActiveButton(button) ? button.activeImageSrc : button.imageSrc
              }
              alt={button.label}
            />
            {button.size === "medium" && (
              <span className="skill-map-container__button-wrapper__label">
                {formatLabel(button.label)}
              </span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SkillMapTabs;
