import React from "react";
import { Link, useNavigate } from "react-router-dom";

import burgerIconWhite from "../../resources/images/icons/menu_blanc.png";

import "./analytics-menu.scss";
import { useTranslation } from "react-i18next";

interface AnalyticsMenuProps {
  items: {
    label: string;
    route: string;
    iconWhite: string;
    iconViolet: string;
  }[];
  toggleBurgerMenu?: () => void;
  handleHomeClick?: () => void;
}

const AnalyticsMenu: React.FC<AnalyticsMenuProps> = ({
  items,
  toggleBurgerMenu,
  handleHomeClick,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="analytics-menu-home">
        <button className="burger-btn" onClick={toggleBurgerMenu}>
          <img src={burgerIconWhite} alt="Menu Burger Icon" />
        </button>
        <h2 className="menu-title" onClick={handleHomeClick}>
          {t(`analyticsPages.analytics_menu_items.home`)}
        </h2>
      </div>

      <ul className="menu-list">
        {items.map((item, index) => (
          <li key={index} className="menu-item">
            <Link
              to={item.route}
              className="menu-link"
              onClick={toggleBurgerMenu}
            >
              <img
                src={item.iconWhite}
                alt={`${item.label} Icon`}
                className="menu-icon"
              />
              {t(`analyticsPages.analytics_menu_items.${item.label}`)}
            </Link>
          </li>
        ))}
      </ul>
    </>
  );
};

export default AnalyticsMenu;
