import React, { useState, useEffect } from 'react';
import FilerobotImageEditor, { TABS, TOOLS, FilerobotImageEditorConfig } from 'react-filerobot-image-editor';
import './image-editor.scss';

const ImageEditor: React.FC = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize(); // Check initial size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const pixelRatio = isMobile ? window.devicePixelRatio : 1;

    const config: FilerobotImageEditorConfig = {
        source: 'https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg',
        onSave: (editedImageObject, designState) => console.log('saved', editedImageObject, designState),
        onClose: () => console.log('Editor closed'),
        tabsIds: [TABS.ADJUST, TABS.ANNOTATE, TABS.FILTERS, TABS.RESIZE],
        defaultTabId: TABS.ADJUST,
        defaultToolId: TOOLS.CROP,
        savingPixelRatio: pixelRatio,
        previewPixelRatio: pixelRatio,
    };

    return (
        <div className={`custom-editor-container ${isMobile ? 'mobile' : ''}`}>
            <FilerobotImageEditor {...config} />
        </div>
    );
};

export default ImageEditor;
