import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";

import "./account-validation.scss";

import URLS from "../../config/api-urls";
import ResetPassword from "./reinit-password";
import SingleForm from "../../components/forms/single-form/single-form";
import SelectForm from "../../components/forms/select-form/select-form";
import DayCounter from "../../components/forms/day-counter/day-counter";

interface IFormattedData {
  Achievements: number;
  Needs: number;
  JourneyReport: number;
}

interface IUseParams {
  token: string;
  referentId: string;
  email: string;
  childId: string;
  username: string;
}

export interface IFormConfig {
  labelText: string;
  inputType: string;
  inputValue: string | number | boolean | null;
  setValue: React.Dispatch<React.SetStateAction<string | number | boolean>>;
  isRequired: boolean;
  isEditable?: boolean;
}

interface ISelectFormConfig {
  labelText: string;
  inputValue: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  choices: string[];
  isRequired: boolean;
}

interface IDayCounter {
  day: string;
  inputValue: number;
  setDaysCount: React.Dispatch<React.SetStateAction<Record<string, number>>>;
}

interface IFormattedDays {
  [day: string]: number;
  limited: number;
}

interface IReportEnum {
  [key: string]: number;
}

interface IFormData {
  tokenCreation: string;
  referentId: string;
  email: string;
  password: string;
  childId: string;
  userName: string;
  access: IFormattedDays;
  notification: IFormattedData;
}

interface IResponseData {
  data?: {
    statusMessage: string;
  };
  [key: string]: any;
}

const AccountValidation = () => {
  // CAREFUL -- WORK IN PROGRESS -- FIRST BASIC VERSION

  const { t, i18n } = useTranslation();

  const {
    token: tokenURL,
    referentId: referentIdURL,
    email: emailURL,
    childId: childIdURL,
    username: usernameURL,
  } = useParams();
  const tokenCookie = Cookies.get("connected");
  const usernameCookie = Cookies.get("username");
  const childIdCookie = Cookies.get("childId");

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [responseData, setResponseData] = useState<IResponseData | null>(null);
  const [isSubmitOK, setIsSubmitOK] = useState<boolean>(false);
  // const [password, setPassword] = useState("");

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const formatDaysCounter = (daysCount, childAccess) => {
    let formattedData = {};
    if (childAccess === "Limited") {
      formattedData = {
        limited: 1,
      };
      for (const day in daysCount) {
        formattedData[`${day}Hour`] = daysCount[day];
      }
    } else if (childAccess === "Unlimited") {
      formattedData = {
        limited: 0,
      };
      for (const day in daysCount) {
        formattedData[`${day}Hour`] = 0;
      }
    }
    return formattedData;
  };

  const formatNotifications = (childAchievements, childNeeds, childReport) => {
    const ReportEnum = {
      Weekly: 1,
      Monthly: 2,
      Yearly: 3,
      Never: 0,
    };

    let formattedData: IFormattedData = {
      Achievements: 0,
      Needs: 0,
      JourneyReport: 0,
    };

    formattedData.Achievements = childAchievements ? 1 : 0;
    formattedData.Needs = childNeeds ? 1 : 0;
    formattedData.JourneyReport = ReportEnum[childReport] || 0;

    return formattedData;
  };

  const formatDataBeforeSubmit = (data) => {
    if (parentPassword === confirmParentPassword) {
      setIsLoading(true);
      setErrorMessage("");

      const formattedData = {
        tokenCreation: tokenURL,
        referentId: referentIdURL,
        email: emailURL,
        password: parentPassword,
        childId: childIdURL,
        userName: usernameURL,
        access: formatDaysCounter(daysCount, childAccess),
        notification: formatNotifications(
          childAchievements,
          childNeeds,
          childReport
        ),
      };

      // setLoginData(formattedData);

      onSubmit(formattedData);
    } else {
      setIsLoading(true);
      setErrorMessage("Les mots de passe ne correspondent pas !");
      setIsLoading(false);
    }
  };

  // const setCookies = () => {
  //   Cookies.set("connected", responseData.data.object.tokenConnection, {
  //     expires: 7,
  //   });
  // };

  const onSubmit = (data) => {
    axios
      .post(URLS.POST_NewParent, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setResponseData(response);
        setIsLoading(false);
        setIsSubmitOK(true);
        // setCookies();
      })
      .catch((error) => {
        setResponseData(error);
        setIsLoading(false);
        setIsSubmitOK(false);
      });
  };

  const [parentEmail, setParentEmail] = useState("");
  const [parentPassword, setParentPassword] = useState("");
  const [confirmParentPassword, setConfirmParentPassword] = useState("");
  const formDataParent = [
    {
      labelText: "parent_email",
      inputType: "text",
      inputValue: parentEmail ? parentEmail : emailURL,
      setValue: setParentEmail,
      isRequired: true,
      isEditable: false,
    },
    {
      labelText: "parent_password",
      inputType: "password",
      inputValue: parentPassword,
      setValue: setParentPassword,
      isRequired: true,
      isEditable: true,
    },
    {
      labelText: "confirm_parent_password",
      inputType: "password",
      inputValue: confirmParentPassword,
      setValue: setConfirmParentPassword,
      isRequired: true,
      isEditable: true,
    },
  ];

  const [userName, setUserName] = useState("");
  const formDataChild = [
    {
      labelText: "child_username",
      inputType: "text",
      inputValue: userName || usernameURL,
      setValue: setUserName,
      isRequired: true,
      isEditable: false,
    },
  ];

  const [childAccess, setChildAccess] = useState("");
  const selectAccessData = {
    labelText: "child_access",
    inputValue: childAccess,
    setValue: setChildAccess,
    choices: ["Unlimited", "Limited"],
    isRequired: true,
  };

  const [daysCount, setDaysCount] = useState({
    Monday: 2,
    Tuesday: 2,
    Wednesday: 2,
    Thursday: 2,
    Friday: 2,
    Saturday: 6,
    Sunday: 6,
  });
  const days = Object.keys(daysCount);
  const [childAchievements, setChildAchievements] = useState("");
  const [childNeeds, setChildNeeds] = useState("");
  const [childReport, setChildReport] = useState("");
  const selectNotificationData = [
    {
      labelText: "child_achievements",
      inputValue: childAchievements,
      setValue: setChildAchievements,
      choices: ["On", "Off"],
      isRequired: true,
    },
    {
      labelText: "child_needs",
      inputValue: childNeeds,
      setValue: setChildNeeds,
      choices: ["On", "Off"],
      isRequired: true,
    },
    {
      labelText: "child_reports",
      inputValue: childReport,
      setValue: setChildReport,
      choices: ["Never", "Weekly", "Monthly", "Yearly"],
      isRequired: true,
    },
  ];

  // CAREFUL -- WORK IN PROGRESS -- FIRST BASIC VERSION
  return (
    <div className="validation-page">
      {!isLoading &&
      !errorMessage &&
      responseData?.data?.statusMessage ===
        "the parent was succesfully created" ? (
        <p>Account successfully configurated !</p>
      ) : (
        <div className="validation-page__container">
          <h1>{t("settingsPage.title")}</h1>
          <div className="validation-page__container-inputs">
            <form onSubmit={handleSubmit(formatDataBeforeSubmit)}>
              <div className="validation-page__form-container">
                <div className="validation-page__form-parent">
                  <h2>{t("settingsPage.title_parents")}</h2>
                  {formDataParent.map((formField, index) => (
                    <SingleForm
                      key={index}
                      {...formField}
                      register={register}
                      errors={errors}
                    />
                  ))}
                </div>

                <div className="validation-page__form-child">
                  <h2>{t("settingsPage.title_child")}</h2>
                  {formDataChild.map((formField, index) => (
                    <SingleForm
                      key={index}
                      {...formField}
                      register={register}
                      errors={errors}
                    />
                  ))}
                </div>
              </div>

              <div className="validation-page__select-container">
                <div className="validation-page__select-container-left">
                  <SelectForm {...selectAccessData} />

                  {childAccess === "Limited" && (
                    <div className="validation-page__day-counters">
                      {days.map((day, index) => (
                        <DayCounter
                          key={index}
                          day={day}
                          inputValue={daysCount[day]}
                          setDaysCount={setDaysCount}
                        />
                      ))}
                    </div>
                  )}
                </div>

                <div className="validation-page__select-container-right">
                  {selectNotificationData.map((selectField, index) => (
                    <SelectForm key={index} {...selectField} />
                  ))}
                </div>
              </div>

              {errorMessage && (
                <p className="validation-page__error-message">{errorMessage}</p>
              )}
              <input
                className="validation-page__submit-button"
                type="submit"
                value="Valider"
              />
            </form>
            {isLoading && <p>Loading...</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountValidation;
