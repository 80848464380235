import { useEffect, useState } from "react";

import { useAnalytics } from "../pages/analytics/analytics-context";
import {
  ScreenTimeData,
  UseScreenTimeDataInput,
  UseScreenTimeDataOutput,
} from "../interfaces/analytics-interfaces/screen-time-interface";
import { calculateScreenTimeDetails } from "../helper/analytics-helpers/screen-time-helper";

export const useScreenTimeData = (
  props: UseScreenTimeDataInput
): UseScreenTimeDataOutput => {
  const { childSessionTimeData, fetchChildSessionTime } = useAnalytics();

  const [allRequestsFetched, setAllRequestsFetched] = useState<boolean>(false);
  const [isProcessingScreenTimeData, setIsProcessingScreenTimeData] =
    useState<boolean>(false);

  const [screenTimeData, setScreenTimeData] = useState<ScreenTimeData>();

  useEffect(() => {
    Promise.all([fetchChildSessionTime(props.childrenData.childId)]).then(
      () => {
        setAllRequestsFetched(true);
      }
    );
  }, []);

  useEffect(() => {
    if (allRequestsFetched && childSessionTimeData) {
      setIsProcessingScreenTimeData(true);

      const screenTimeData = calculateScreenTimeDetails(
        childSessionTimeData,
        props.dateLimit
      );

      setScreenTimeData(screenTimeData);
      setIsProcessingScreenTimeData(false);
    }
  }, [allRequestsFetched, childSessionTimeData, props.dateLimit]);

  return {
    isProcessingScreenTimeData:
      isProcessingScreenTimeData || !allRequestsFetched,
    screenTimeData,
  };
};
