import { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import bcrypt from "bcryptjs";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

import URLS from "../../config/api-urls";

import "./signup.scss";

import * as countries from "../../resources/data/countries.js";
import boyIcon from "../../resources/images/little-boy.png";
import girlIcon from "../../resources/images/little-girl.png";
import MonthSelect from "../../components/date/month-select";
import { regexEmail, regexUsername } from "../../constants/regex-constants";

function Signup() {
  const { t, i18n } = useTranslation();

  const [signupData, setSignupData] = useState("");
  const [userList, setUserList] = useState(null);
  const [responseData, setResponseData] = useState({});
  const [gender, setGender] = useState("O");
  const [isError, setIsError] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const formatDataForSignup = (data) => {
    const { username, password, email, country } = data;

    const formattedData = {
      username,
      password,
      email,
      dateofbirth: `${data.birthdayYear}-${data.birthdayMonth}-${data.birthdayDay}`,
      country,
      gender,
    };
    setSignupData(formattedData);

    onSubmit(formattedData);
  };

  const onSubmit = async (data) => {
    axios
      .post(URLS.POST_NewChild, data, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        setResponseData(response);
        setIsError(false);
      })
      .catch((error) => {
        if (error.response.status === 400) {
          setResponseData({
            status: error.response.status,
            statusText: error.response.statusText,
            data: { statusMessage: t("forms.error.server_error") },
          });
        } else {
          setResponseData(error);
        }
        setIsError(true);
      });
  };

  return (
    <div className="signup-page">
      <div className="signup-page__container">
        <h2>{t("signupPage.signup")}</h2>
        <div className="signup-page__container-inputs">
          <form onSubmit={handleSubmit(formatDataForSignup)}>
            <label>{t("signupPage.username")}</label>
            <div className="signup-page__inputs signup-page__inputs--username">
              <input
                className={`signup-page__input ${
                  errors.username && "signup-page__input--error"
                }`}
                {...register("username", {
                  required: true,
                  maxLength: 45,
                  minLength: 4,
                  pattern: regexUsername,
                })}
              />
              {errors.username && (
                <p className="auth__error-message">
                  {t("forms.error.invalid_username")}
                </p>
              )}
            </div>

            <label>{t("signupPage.password")}</label>
            <div className="signup-page__inputs signup-page__inputs--password">
              <input
                type={"password"}
                className={`signup-page__input ${
                  errors.password && "signup-page__input--error"
                }`}
                {...register("password", {
                  required: true,
                  maxLength: 32,
                  minLength: 4,
                })}
              />
              {errors.password && (
                <p className="auth__error-message">
                  {t("forms.error.password_required")}
                </p>
              )}
            </div>

            <label>{t("signupPage.parent_email")}</label>
            <div className="signup-page__inputs signup-page__inputs--mail">
              <input
                className={`signup-page__input ${
                  errors.email && "signup-page__input--error"
                }`}
                {...register("email", {
                  required: true,
                  maxLength: 100,
                  minLength: 4,
                  pattern: regexEmail,
                })}
              />
              {errors.email && (
                <p className="auth__error-message">
                  {t("forms.error.invalid_email")}
                </p>
              )}
            </div>

            <label>{t("signupPage.birthday")}</label>
            <div className="signup-page__inputs signup-page__birthday-inputs">
              <div className="signup-page__birthday-day">
                <select
                  className={`signup-page__input ${
                    errors.birthdayDay && "signup-page__input--error"
                  }`}
                  // id="birthdayDay"
                  {...register("birthdayDay", { required: true })}
                >
                  <option value="" disabled>
                    {t("signupPage.birthday_date.day")}
                  </option>
                  {/* generate 31 days */}
                  {Array.from(Array(31).keys()).map((day) => {
                    return (
                      <option value={day + 1} key={day}>
                        {day + 1}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className="signup-page__birthday-month">
                <select
                  className={`signup-page__input ${
                    errors.birthdayMonth && "signup-page__input--error"
                  }`}
                  // id="birthdayMonth"
                  {...register("birthdayMonth", { required: true })}
                >
                  <option value="" disabled>
                    {t("signupPage.birthday_date.month")}
                  </option>
                  <MonthSelect register={register} />
                </select>
              </div>

              <div className="signup-page__birthday-year">
                <select
                  className={`signup-page__input ${
                    errors.birthdayYear && "signup-page__input-error"
                  }`}
                  // id="birthdayYear"
                  {...register("birthdayYear", { required: true })}
                >
                  <option value="" disabled>
                    {t("signupPage.birthday_date.year")}
                  </option>
                  {/* generate 100 years */}
                  {Array.from(Array(100).keys()).map((year) => {
                    return (
                      <option value={2021 - year} key={year}>
                        {2021 - year}
                      </option>
                    );
                  })}
                </select>
              </div>
            </div>

            <label>
              {t("signupPage.country")} {t("forms.label.optional")}
            </label>
            <div className="signup-page__inputs signup-page__country-inputs">
              <select
                className={`signup-page__input ${
                  errors.country && "signup-page__input-error"
                }`}
                // id="country"
                {...register("country", { required: false })}
              >
                <option value="" disabled="">
                  {t("signupPage.country")}
                </option>
                {countries.default.map((country) => {
                  return (
                    <option value={country.code3} key={country.id}>
                      {country.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <label>
              {t("signupPage.gender")} {t("forms.label.optional")}
            </label>
            <div className="signup-page__inputs signup-page__gender-inputs">
              <div className="signup-page__gender-choice">
                <label
                  className={
                    gender === "B"
                      ? "signup-page__label-child-choice--selected"
                      : "signup-page__label-child-choice--not-selected"
                  }
                  onClick={() => setGender(gender === "B" ? "O" : "B")}
                >
                  {t("signupPage.boy")}
                  <div className="signup-page__child-choice">
                    <img src={boyIcon} alt="boy icon" />
                  </div>
                </label>

                <label
                  className={
                    gender === "G"
                      ? "signup-page__label-child-choice--selected"
                      : "signup-page__label-child-choice--not-selected"
                  }
                  onClick={() => setGender(gender === "G" ? "O" : "G")}
                >
                  {t("signupPage.girl")}
                  <div className="signup-page__child-choice">
                    <img src={girlIcon} alt="girl icon" />
                  </div>
                </label>
              </div>
            </div>

            {Object.keys(errors).length !== 0 && (
              <p className="signup-page__error-message">
                {t("forms.error.incorrect_field")}
              </p>
            )}

            <input
              type="submit"
              className="signup-page__submit-button"
              value={t("signupPage.signup")}
            />

            {Object.keys(responseData).length === 0 ? null : ( // si response existe, on affiche le message de réponse
              <div
                className={`signup-page__submit-message${
                  isError ? " error" : ""
                }`}
              >
                <div>
                  {/* Réponse du serveur : */}
                  {/* <p>
                    {responseData?.status} - {responseData?.statusText}
                  </p> */}
                  <p>{responseData?.data?.statusMessage}</p>
                </div>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
}

export default Signup;
