import React, { useState, FormEvent } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import URLS from "../../config/api-urls";

import "./auth.scss";

const ResendParentConfigMail: React.FC = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [serverMessage, setServerMessage] = useState<string | null>(null);
  const [responseStatus, setResponseStatus] = useState<
    "success" | "error" | null
  >(null);

  const handleInputChange = () => {
    if (responseStatus === "error") {
      setServerMessage(null);
      setResponseStatus(null);
    }
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const response = await axios.post(URLS.POST_ResendParentEmail, { email });
      setServerMessage(response.data.statusMessage);
      setResponseStatus(response.data.status === 0 ? "error" : "success");
    } catch (error: any) {
      setServerMessage(
        error.response?.data.statusMessage || t("forms.error.server_error")
      );
      setResponseStatus("error");
    }

    setIsLoading(false);
  };

  return (
    <div className="auth__container">
      <h2 className="auth__title">{t("resendParentConfigMail.title")}</h2>
      {responseStatus !== "success" ? (
        <form className="auth__form" onSubmit={handleSubmit}>
          <label className="auth__label">
            {t("resendParentConfigMail.email_label")}
            <input
              type="email"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
                handleInputChange();
              }}
              className="auth__input"
              required
            />
          </label>
          <div className="auth__actions">
            {!serverMessage && (
              <button
                type="submit"
                className="auth__submit-button"
                disabled={isLoading}
              >
                {isLoading
                  ? t("resendParentConfigMail.submit_button_loading")
                  : t("resendParentConfigMail.submit_button_default")}
              </button>
            )}
          </div>
        </form>
      ) : (
        <div className="auth__server-response">{serverMessage}</div>
      )}

      {serverMessage && responseStatus === "error" && (
        <div className={`auth__server-response error-border`}>
          {serverMessage}
        </div>
      )}
    </div>
  );
};

export default ResendParentConfigMail;
