import { IconProps } from "../components/detailCard/detail-card";
import MedalBlueIcon from "../resources/images/icons/coupe_bleue.png";
import MedalOrangeIcon from "../resources/images/icons/coupe_rouge.png";

export const MedalBlueIconDetails: IconProps = {
  src: MedalBlueIcon,
  alt: "DrMedalopdown Icon",
};

export const MedalOrangeIconDetails: IconProps = {
  src: MedalOrangeIcon,
  alt: "Medal Icon",
};

export const MEDIA_FORMATS = [
  "Videos",
  "Video",
  "Photo",
  "Images",
  "Image",
  "Gifs",
];
export const GAME_FORMATS = ["Games", "Game"];
