/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import {
  SkillLevel1ProgressData,
  SkillLevel2ProgressData,
  SkillLevel3ProgressData,
} from "../interfaces/skill-map-interfaces/skill-map-interface";
import { useAnalytics } from "../pages/analytics/analytics-context";
import {
  calculateSkillLevel3Progress,
  calculateSkillLevel2Progress,
  calculateSkillLevel1Progress,
} from "../helper/analytics-helpers/skill-map-helpers/skill-map-helper";
import {ChildBaseModel} from "../interfaces/child-interfaces/child-interface";

interface UseSkillProgressInput {
  childrenData: ChildBaseModel;
  actualLanguage: number;
}

interface UseSkillProgressOutput {
  isProcessingSkillProgressData: boolean;
  skillLevel1ProgressData: SkillLevel1ProgressData[];
  skillLevel2ProgressData: SkillLevel2ProgressData[];
  skillLevel3ProgressData: SkillLevel3ProgressData[];
}

export const useSkillProgressData = (
  props: UseSkillProgressInput
): UseSkillProgressOutput => {
  const {
    fetchSkillsLevel1,
    fetchSkillsLevel2,
    fetchSkillsLevel3,
    fetchChildSkillHistory,
    skillsLevel1Data,
    skillsLevel2Data,
    skillsLevel3Data,
    childSkillHistoryData,
  } = useAnalytics();
  const [allRequestsFetched, setAllRequestsFetched] = useState(false);
  const [isSkillLevel3Calculated, setIsSkillLevel3Calculated] = useState(false);
  const [isSkillsProcessing, setIsSkillsProcessing] = useState(true);
  const [skillLevel1ProgressData, setSkillLevel1ProgressData] =
    useState<SkillLevel1ProgressData[]>();
  const [skillLevel2ProgressData, setSkillLevel2ProgressData] =
    useState<SkillLevel2ProgressData[]>();
  const [skillLevel3ProgressData, setSkillLevel3ProgressData] =
    useState<SkillLevel3ProgressData[]>();

  const updateSkillData = (
    newData: any[],
    currentData: any[],
    setDataFunction: React.Dispatch<React.SetStateAction<any[]>>
  ) => {
    if (newData.length || !currentData || !currentData.length) {
      setDataFunction(newData);
    }
  };

  useEffect(() => {
    Promise.all([
      fetchSkillsLevel1(props.actualLanguage),
      fetchSkillsLevel2(props.actualLanguage),
      fetchSkillsLevel3(props.actualLanguage),
      fetchChildSkillHistory(props.childrenData.childId),
    ]).then(() => {
      setAllRequestsFetched(true);
    });
  }, [props.childrenData, props.actualLanguage]);

  useEffect(() => {
    if (
      allRequestsFetched &&
      skillsLevel1Data &&
      skillsLevel2Data &&
      skillsLevel3Data
    ) {
      setIsSkillsProcessing(true);
      const newSkillLevel3ProgressData = calculateSkillLevel3Progress({
        childSkillHistoryData,
        skillsLevel3Data,
        allRequestsFetched,
        isSkillLevel3Calculated,
        setIsSkillLevel3Calculated,
      });
      updateSkillData(
        newSkillLevel3ProgressData,
        skillLevel3ProgressData,
        setSkillLevel3ProgressData
      );

      const newSkillLevel2ProgressData = calculateSkillLevel2Progress({
        skillsLevel2Data,
        skillLevel3ProgressData: newSkillLevel3ProgressData,
        isSkillLevel3Calculated,
        allRequestsFetched,
      });
      updateSkillData(
        newSkillLevel2ProgressData,
        skillLevel2ProgressData,
        setSkillLevel2ProgressData
      );

      const newSkillLevel1ProgressData = calculateSkillLevel1Progress({
        skillsLevel1Data,
        skillsLevel2Data,
        skillLevel2ProgressData: newSkillLevel2ProgressData,
        isSkillLevel3Calculated,
        allRequestsFetched,
      });
      updateSkillData(
        newSkillLevel1ProgressData,
        skillLevel1ProgressData,
        setSkillLevel1ProgressData
      );

      setIsSkillsProcessing(false);
    }
  }, [
    allRequestsFetched,
    childSkillHistoryData,
    skillsLevel1Data,
    skillsLevel2Data,
    skillsLevel3Data,
  ]);

  return {
    isProcessingSkillProgressData: isSkillsProcessing || !allRequestsFetched,
    skillLevel1ProgressData,
    skillLevel2ProgressData,
    skillLevel3ProgressData,
  };
};
