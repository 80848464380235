import URLS from "../../config/api-urls";

export interface NewChildRecommendationBody {
  ChildId: number;
  ContentId: number;
  Imposed: boolean;
}

export const postNewChildRecommendation = async (
  body: NewChildRecommendationBody
): Promise<any> => {
  const url = URLS.POST_NewChildRecommendation;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
};

export function handleSendRecommendationButtonClick(
  contentId: number,
  childId: number,
  imposed: boolean
): Promise<any> {
  const body: NewChildRecommendationBody = {
    ChildId: childId,
    ContentId: contentId,
    Imposed: imposed,
  };

  return postNewChildRecommendation(body);
}
