import React, { FC, CSSProperties } from "react";
import { RotatingTriangles } from "react-loader-spinner";

const generateCenteredStyle = (): CSSProperties => {
  return {
    textAlign: "center",
    alignSelf: "center",
  };
};

interface AnalyticsLoadingProps {
  size?: "small" | "medium" | "big";
}

export const AnalyticsLoading: FC<AnalyticsLoadingProps> = (props) => {
  const centeredStyle = generateCenteredStyle();

  const getSize = (): number => {
    switch (props.size) {
      case "small":
        return 30;
      case "medium":
        return 50;
      case "big":
        return 80;
      default:
        return 80;
    }
  };

  const dimension = getSize();

  return (
    <div style={centeredStyle}>
      <RotatingTriangles
        visible={true}
        height={dimension.toString()}
        width={dimension.toString()}
        ariaLabel="rotating-triangels-loading"
        wrapperClass="rotating-triangels-wrapper"
        colors={["#018cd5", "#de2781", "#f19700"]}
      />
    </div>
  );
};

export default AnalyticsLoading;
