import "./skill-progress-bar.scss";

import ProgressBar from "../progress-bar/progress-bar";
import { getDetailIconByThemeColor } from "../../helper/analytics-helpers/skill-map-helpers/theme-helper";
import { SkillWithItemCount } from "../../interfaces/skill-map-interfaces/skill-map-interface";

interface SkillProgressBarProps {
  value: number;
  skillData: SkillWithItemCount;
  themeColor?: string;
  onClick?: () => void;
  showEyeIcon?: boolean;
  barWidth?: "small" | "medium" | "big";
}

const SkillProgressBar: React.FC<SkillProgressBarProps> = ({
  value,
  skillData,
  themeColor,
  onClick,
  showEyeIcon = true,
  barWidth = "medium",
}) => {
  if (value === null || value === undefined || !skillData) {
    return null;
  }

  const detailIcon = getDetailIconByThemeColor(themeColor);

  const handleDetailClick = () => {
    if (onClick) {
      onClick();
    }
  };

  let maxValue = 100;
  // for later - maybe
  // if (value < 10) {
  //   maxValue = 10;
  // } else if (value < 50) {
  //   maxValue = 50;
  // } else {
  //   maxValue = 100;
  // }

  return (
    <div className="skill-progress">
      <div className="skill-progress__name">
        <p className="skill-progress__name--title">{skillData.name}</p>
        <p className="skill-progress__name--count">
          {skillData.itemCount != null && `(${skillData.itemCount})`}
        </p>
      </div>
      <div className="skill-progress__container">
        <div className="skill-progress__progress-bar">
          <ProgressBar
            bgcolor={themeColor}
            completed={value}
            maxValue={maxValue}
            barWidth={barWidth}
          />
        </div>
        <span className="skill-progress__label">{value.toFixed(1)}%</span>

        {showEyeIcon && skillData.level < 3 && (
          <button
            className="skill-progress__button"
            onClick={() => handleDetailClick()}
          >
            <img
              src={detailIcon}
              alt="Detail"
              className="skill-progress__button-img"
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default SkillProgressBar;
