import moment from "moment";
import { ScreenTimeData } from "../../interfaces/analytics-interfaces/screen-time-interface";
import { ProcessedSkillData } from "../../hooks/use-skill-progress-period-data";

export const getTodayMetrics = (screenTimeData: ScreenTimeData | undefined) => {
  const todayDate = moment().format("YYYY-MM-DD");
  const todaySessionTime =
    screenTimeData?.dailyDetails[todayDate]?.sessionTimeInMinutes;
  const todaySessions = screenTimeData?.dailyDetails[todayDate]?.sessions;

  return {
    todaySessionTime:
      typeof todaySessionTime === "number" ? todaySessionTime : 0,
    todaySessions: typeof todaySessions === "number" ? todaySessions : 0,
  };
};

const getLastNItems = (array: { point: number; date: string }[], n: number) => {
  return array.slice(-n);
};

export const calculateTotalPoints = (
  data: ProcessedSkillData,
  periodLimit: number
) => {
  const totalPoints: { [key: number]: number } = {};

  Object.keys(data).forEach((level) => {
    const combinedData = data[+level].points
      .map((point: number, index: number) => ({
        point,
        date: data[+level].timeFrame[index],
      }))
      .sort((a, b) => moment(b.date).diff(moment(a.date)));

    const relevantData = getLastNItems(combinedData, periodLimit);

    if (relevantData.length === 1) {
      totalPoints[+level] = relevantData[0].point;
    } else if (relevantData.length > 1) {
      totalPoints[+level] = Math.abs(
        relevantData[0].point - relevantData[relevantData.length - 1].point
      );
    }
  });

  return totalPoints;
};
