import { useEffect, useState } from "react";

import { useAnalytics } from "../pages/analytics/analytics-context";
import { processSkillDataByTimeFrame } from "../helper/analytics-helpers/progress-helper";
import {ChildBaseModel} from "../interfaces/child-interfaces/child-interface";

export interface SkillProgressPeriodInput {
  childrenData: ChildBaseModel;
  dateLimit: string;
}

export interface SkillProgressPeriodOutput {
  isProcessingSkillProgressPeriodData: boolean;
  skillProgressPeriodData: ProcessedSkillData;
}

export interface ProcessedSkillData {
  [skillLevel1Id: number]: {
    timeFrame: string[];
    points: number[];
  };
}

export const useSkillProgressPeriodData = (
  props: SkillProgressPeriodInput
): SkillProgressPeriodOutput => {
  const { childSkillHistoryData, fetchChildSkillHistory } = useAnalytics();

  const [allRequestsFetched, setAllRequestsFetched] = useState(false);
  const [
    isProcessingSkillProgressPeriodData,
    setIsProcessingSkillProgressPeriodData,
  ] = useState(false);

  const [skillProgressPeriodData, setSkillProgressPeriodData] =
    useState<any>(false);

  useEffect(() => {
    Promise.all([fetchChildSkillHistory(props.childrenData.childId)]).then(
      () => {
        setAllRequestsFetched(true);
      }
    );
  }, [props.childrenData]);

  useEffect(() => {
    if (allRequestsFetched && childSkillHistoryData) {
      setIsProcessingSkillProgressPeriodData(true);

      const processedData = processSkillDataByTimeFrame(
        childSkillHistoryData.object,
        props.dateLimit
      );

      setSkillProgressPeriodData(processedData);
      setIsProcessingSkillProgressPeriodData(false);
    }
  }, [allRequestsFetched, childSkillHistoryData, props.dateLimit]);

  return {
    isProcessingSkillProgressPeriodData:
      isProcessingSkillProgressPeriodData || !allRequestsFetched,
    skillProgressPeriodData,
  };
};
