import {
  Skill,
  GetHeaderTitleProps,
  SkillLevel1ProgressData,
  SkillLevel2ProgressData,
  SkillLevel3ProgressData,
} from "../../../interfaces/skill-map-interfaces/skill-map-interface";

import BackArrowBlueIcon from "../../../resources/images/icons/retour_bleu.png";
import BackArrowRoseIcon from "../../../resources/images/icons/retour_rose.png";
import BackArrowYellowIcon from "../../../resources/images/icons/retour_jaune.png";
import { useMemo } from "react";
import {
  CalculateSkillLevel3ProgressParameters,
  CalculateSkillLevel2ProgressParameters,
  CalculateSkillLevel1ProgressParameters,
} from "../../../interfaces/skill-map-interfaces/calculate-skill-level-progress-interface";

export const filterDataByLevelAndParentId = (
  selectedParentIdByLevel: Record<number, number>,
  data: Skill[],
  isMobileScreenSize: boolean
): Skill[] => {
  let availableLevels = Object.keys(selectedParentIdByLevel)
    .map((level) => parseInt(level))
    .sort((a, b) => b - a);

  let displayLevel = availableLevels.length === 0 ? 1 : availableLevels[0] + 1;

  return data.filter((data) => {
    let parentId;
    if (data.level < displayLevel) {
      if (isMobileScreenSize) return false;
      for (let i = displayLevel - 1; i > 0; i--) {
        parentId = selectedParentIdByLevel[i];
        if (data["parentId"] && parentId && data["parentId"] === parentId) {
          return true;
        }
      }
      return false;
    }

    if (data.level > displayLevel) return false;

    parentId = selectedParentIdByLevel[displayLevel - 1];

    if (data["parentId"] && parentId) {
      return data["parentId"] === parentId;
    }
    return true;
  });
};

export const getHeaderTitle = ({
  skillData,
  level,
  id,
}: GetHeaderTitleProps): string => {
  return (
    skillData
      .find((s) => s.id === id && s.level === level)
      ?.name.toUpperCase() ?? "MAIN SKILLS"
  );
};

export const getBackArrowIcon = (
  selectedParentIdByLevel: Record<number, number>
) => {
  const parentId = selectedParentIdByLevel[1];

  if (parentId === 1) {
    return BackArrowBlueIcon;
  }
  if (parentId === 2) {
    return BackArrowRoseIcon;
  }
  if (parentId === 3) {
    return BackArrowYellowIcon;
  }
  return null;
};

export const calculateSkillLevel3Progress = (
  parameters: CalculateSkillLevel3ProgressParameters
): SkillLevel3ProgressData[] => {
  const {
    childSkillHistoryData,
    skillsLevel3Data,
    allRequestsFetched,
    isSkillLevel3Calculated,
    setIsSkillLevel3Calculated,
  } = parameters;

  if (!childSkillHistoryData || !skillsLevel3Data || !allRequestsFetched) {
    return [];
  }

  if (allRequestsFetched && !isSkillLevel3Calculated) {
    if (childSkillHistoryData.object && skillsLevel3Data.object) {
      const aggregatedData: {
        [key: number]: { points: number; name: string; parentId: number };
      } = {};

      skillsLevel3Data.object.forEach(({ id, name, parentId }) => {
        if (id !== undefined && name !== undefined && parentId !== undefined) {
          aggregatedData[id] = { name: name, parentId: parentId, points: 0 };
        }
      });

      childSkillHistoryData.object.forEach(({ skillLevel3Id, points }) => {
        if (aggregatedData[skillLevel3Id]) {
          // vérifie que l'identifiant existe
          aggregatedData[skillLevel3Id].points += points;
        }
      });

      const aggregatedArray = Object.keys(aggregatedData).map((idStr) => {
        const id = parseInt(idStr);
        return {
          id,
          parentId: aggregatedData[id].parentId,
          name: aggregatedData[id].name,
          points: aggregatedData[id].points,
          progress: Math.min(aggregatedData[id].points, 100), // Max 100
          level: 3,
        };
      });

      setIsSkillLevel3Calculated(true);
      return aggregatedArray;
    }
  }
  setIsSkillLevel3Calculated(false);
  return [];
};

export const calculateSkillLevel2Progress = (
  parameters: CalculateSkillLevel2ProgressParameters
): SkillLevel2ProgressData[] => {
  const {
    skillsLevel2Data,
    skillLevel3ProgressData,
    isSkillLevel3Calculated,
    allRequestsFetched,
  } = parameters;

  if (!skillsLevel2Data || !skillLevel3ProgressData || !allRequestsFetched) {
    return [];
  }

  if (allRequestsFetched && !isSkillLevel3Calculated) {
    const skillLevel2ProgressObj: { [key: number]: number } = {};

    skillLevel3ProgressData.forEach(({ points, parentId }) => {
      if (!skillLevel2ProgressObj[parentId]) {
        skillLevel2ProgressObj[parentId] = 0;
      }
      skillLevel2ProgressObj[parentId] += points;
    });

    const skillLevel2Arr = skillsLevel2Data.object.map(
      ({ id, name, parentId }) => {
        const numberOfChildSkills = skillLevel3ProgressData.filter(
          ({ parentId }) => parentId === id
        ).length;
        return {
          id: id,
          name: name,
          parentId: parentId,
          progress: numberOfChildSkills
            ? skillLevel2ProgressObj[id] / numberOfChildSkills
            : 0,
          level: 2,
        };
      }
    );

    return skillLevel2Arr;
  }
  return [];
};

export const calculateSkillLevel1Progress = (
  parameters: CalculateSkillLevel1ProgressParameters
): SkillLevel1ProgressData[] => {
  const {
    skillsLevel1Data,
    skillsLevel2Data,
    skillLevel2ProgressData,
    isSkillLevel3Calculated,
    allRequestsFetched,
  } = parameters;

  if (
    !skillsLevel1Data ||
    !skillsLevel2Data ||
    !skillLevel2ProgressData ||
    !allRequestsFetched
  ) {
    return [];
  }

  if (allRequestsFetched && !isSkillLevel3Calculated) {
    const skillLevel1ProgressObj: { [key: number]: number } = {};

    skillLevel2ProgressData.forEach(({ progress, id }) => {
      const parentId = skillsLevel2Data.object.find(
        (skill) => skill.id === id
      )?.parentId;
      if (parentId !== undefined) {
        if (!skillLevel1ProgressObj[parentId]) {
          skillLevel1ProgressObj[parentId] = 0;
        }
        skillLevel1ProgressObj[parentId] += progress;
      }
    });

    const skillLevel1Arr = skillsLevel1Data.object.map(({ id, name }) => {
      const numberOfChildSkills = skillLevel2ProgressData.filter(
        (skill) =>
          skillsLevel2Data.object.find((s) => s.id === skill.id)?.parentId ===
          id
      ).length;
      return {
        id: id,
        name: name,
        progress: numberOfChildSkills
          ? skillLevel1ProgressObj[id] / numberOfChildSkills
          : 0,
        level: 1,
      };
    });

    return skillLevel1Arr;
  }
  return [];
};
