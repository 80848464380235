import Cookies from "js-cookie";

export const setAuthCookies = (token: string, referentId: string): void => {
    Cookies.set("tokenSession", token, { expires: 7, secure: true });
    Cookies.set("referentID", referentId, { expires: 7, secure: true });
};

export const removeAuthCookies = (): void => {
    Cookies.remove("tokenSession");
    Cookies.remove("referentID");
};