import URLS from "../../config/api-urls";

export interface SkillsRequestBody {
  Level: number;
  LanguageId: number;
}

export const fetchSkillsApi = async (body: SkillsRequestBody): Promise<any> => {
  const url = URLS.POST_Skills;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

export const fetchSkillsStatisticsApi = async (): Promise<any> => {
  const url = URLS.POST_GetSkillsStatistics;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: null,
    });
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

export const fetchContentSkillsApi = async (): Promise<any> => {
  const url = URLS.POST_GetContentSkills;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: null,
    });
    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};
