import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import "./title-switch.scss";

interface TitleSwitchProps {
  name: string;
  isTitle?: boolean;
  isSwitchHidden?: boolean;
  onSwitchChange?: (isActive: boolean) => void;
  sectionChecked?: boolean;
  isDisabled?: boolean;
}

const TitleSwitch: React.FC<TitleSwitchProps> = (props: TitleSwitchProps) => {
  const [checked, setChecked] = useState<boolean>(props.sectionChecked);

  useEffect(() => {
    setChecked(props.sectionChecked);
  }, [props.sectionChecked]);

  const handleChange = (newChecked: boolean) => {
    setChecked(newChecked);
    props.onSwitchChange && props.onSwitchChange(newChecked);
  };

  return (
    <div className="titleswitch__container">
      <div
        className={`titleswitch__container__name${
          props.isTitle ? "--title" : ""
        }`}
      >
        <p>{props.name}</p>
      </div>

      {props.isSwitchHidden ? null : (
        <div className="titleswitch__container__switch">
          <Switch
            onChange={handleChange}
            checked={checked}
            height={16}
            width={40}
            disabled={props.isDisabled}
          />
        </div>
      )}
    </div>
  );
};

export default TitleSwitch;
