import URLS from "../../config/api-urls";
import {ChildIdRequestBody} from "./child-api";

export interface RewardsRequestBody {
    LanguageId: number;
}

// General rewards API calls
export const fetchAvatarsApi = async (): Promise<any> => {
    const url = URLS.POST_GetAvatars;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
        });

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const fetchStickersApi = async (body: RewardsRequestBody
): Promise<any> => {
    const url = URLS.POST_GetStickers;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const fetchItemsApi = async (body: RewardsRequestBody
): Promise<any> => {
    const url = URLS.POST_GetItems;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const fetchBadgesApi = async (body: RewardsRequestBody
): Promise<any> => {
    const url = URLS.POST_GetBadges;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const fetchFriendsApi = async (body: RewardsRequestBody
): Promise<any> => {
    const url = URLS.POST_GetFriends;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        return await response.json();
    } catch (error) {
        throw error;
    }
};


// Child rewards API calls
export const fetchChildAvatarRewardsApi = async (
    body: ChildIdRequestBody
): Promise<any> => {
    const url = URLS.POST_GetChildAvatarRewards;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const fetchChildBadgeRewardsApi = async (
    body: ChildIdRequestBody
): Promise<any> => {
    const url = URLS.POST_GetChildBadgeRewards;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const fetchChildItemRewardsApi = async (
    body: ChildIdRequestBody
): Promise<any> => {
    const url = URLS.POST_GetChildItemRewards;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const fetchChildStickerRewardsApi = async (
    body: ChildIdRequestBody
): Promise<any> => {
    const url = URLS.POST_GetChildStickerRewards;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const fetchChildFriendsApi = async (
    body: ChildIdRequestBody
): Promise<any> => {
    const url = URLS.POST_GetChildFriends;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        return await response.json();
    } catch (error) {
        throw error;
    }
};

// Gold-related API calls
export const fetchChildTotalGoldsApi = async (
    body: ChildIdRequestBody
): Promise<any> => {
    const url = URLS.POST_GetChildTotalGolds;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        return await response.json();
    } catch (error) {
        throw error;
    }
};

export const fetchChildGoldBalancesApi = async (
    body: ChildIdRequestBody
): Promise<any> => {
    const url = URLS.POST_GetChildGoldBalances;
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(body),
        });

        return await response.json();
    } catch (error) {
        throw error;
    }
};