import { useEffect, useState } from "react";
import {
  HistorySkillProgressDataInput,
  HistorySkillProgressDataOutput,
  MergedSkillContentData,
} from "../interfaces/analytics-interfaces/history-interface";
import { useSkillProgressData } from "./use-skill-progress-data";
import { SkillLevel2ProgressData } from "../interfaces/skill-map-interfaces/skill-map-interface";
import { useContentsSkillData } from "./use-contents-skill-data";

export const useHistorySkillProgressData = (
  props: HistorySkillProgressDataInput
): HistorySkillProgressDataOutput => {
  const [
    isProcessingHistorySkillProgressData,
    setIsProcessingHistorySkillProgressData,
  ] = useState<boolean>(false);
  const [mergedData, setMergedData] = useState<MergedSkillContentData[]>([]);

  const { skillLevel2ProgressData } = useSkillProgressData({
    childrenData: props.childrenData,
    actualLanguage: props.actualLanguage,
  });

  const { isProcessingContentsSkillData, contentsWithSkills } =
    useContentsSkillData({
      childrenData: props.childrenData,
      actualLanguage: props.actualLanguage,
      filterActivitiesAlreadyDone: false,
    });

  const generateUniqueSkillKey = (skill: SkillLevel2ProgressData): string => {
    return `${skill.id}-${skill.level}`;
  };

  useEffect(() => {
    let skillProgressData: SkillLevel2ProgressData[] = [];

    if (skillLevel2ProgressData) {
      skillProgressData = [...skillLevel2ProgressData];
    }
    if (
      !isProcessingContentsSkillData &&
      skillProgressData.length > 0 &&
      contentsWithSkills.length > 0
    ) {
      setIsProcessingHistorySkillProgressData(true);
      const newMergedData: MergedSkillContentData[] = [];
      const skillLookup: { [key: string]: SkillLevel2ProgressData } = {};

      // Create a lookup object for quick matching
      skillProgressData.forEach((skill) => {
        const key = generateUniqueSkillKey(skill);
        skillLookup[key] = skill;
      });

      // Merge the data
      contentsWithSkills.forEach((content) => {
        content.skills.forEach((skill) => {
          ["2"].forEach((level) => {
            const key = `${skill[`skillLevel${level}Id`]}-${level}`;
            const matchingSkill = skillLookup[key];
            if (matchingSkill) {
              newMergedData.push({
                contentId: content.contentId,
                contentName: content.name,
                contentCategory: content.category,
                contentFormat: content.format,
                skillId: matchingSkill.id,
                skillName: matchingSkill.name,
                skillProgress: matchingSkill.progress,
                skillLevel: matchingSkill.level,
                skillParentId: matchingSkill.parentId,
              });
            }
          });
        });
      });

      setMergedData(newMergedData);
      setIsProcessingHistorySkillProgressData(false);
    }
  }, [
    skillLevel2ProgressData,
    contentsWithSkills,
    isProcessingContentsSkillData,
  ]);

  return {
    isProcessingHistorySkillProgressData:
      isProcessingHistorySkillProgressData || isProcessingContentsSkillData,
    historySkillProgressData: mergedData,
  };
};
