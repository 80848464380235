import React, {useMemo} from "react";
import {RotatingTriangles} from "react-loader-spinner";
import {useTranslation} from "react-i18next";

import {useAnalytics} from "../analytics-context";

import "./recommendations.scss";
import "../analytics.scss";

import DetailCard from "../../../components/detailCard/detail-card";
import {
    StarBlueDetails,
    StarRoseDetails,
    StarYellowDetails,
} from "../../../constants/skill-map-constants";
import RecommendationTable from "../../../components/analytics/recommendation/recommendation-table";
import {getLanguageNumber} from "../../../helper/common-helpers/language-helper";
import {useRecommendationData} from "../../../hooks/use-recommendation-data";
import {useContentsSkillData} from "../../../hooks/use-contents-skill-data";
import {useContentFeedbackAverage} from "../../../hooks/use-content-feedback-average";
import {
    ChildBaseModel,
} from "../../../interfaces/child-interfaces/child-interface";
import {ReferentBaseModel} from "../../../interfaces/referent-interfaces/parents-interface";

interface RecommendationsProps {
    currentChildSelected: ChildBaseModel | null;
    referentData: ReferentBaseModel | null;
}

const Recommendations: React.FC<RecommendationsProps> = (
    props: RecommendationsProps
) => {
    const {t, i18n} = useTranslation();
    const actualLanguage = i18n.language;
    const languageNumber = getLanguageNumber(actualLanguage);
    const {isLoading, error} = useAnalytics();

    const rowsToInitiallyShow = 2;
    const rowsToAdd = 4;

    const {isProcessingRecommendationData, skillsToWorkOn} =
        useRecommendationData({
            childrenData: props.currentChildSelected,
            actualLanguage: languageNumber,
        });

    const {isProcessingContentsSkillData, contentsWithSkills} =
        useContentsSkillData({
            childrenData: props.currentChildSelected,
            actualLanguage: languageNumber,
            filterActivitiesAlreadyDone: true,
        });

    const {isProcessingContentFeedbackAverage, contentsFeedback} =
        useContentFeedbackAverage();

    const groupedContents = useMemo(() => {
        if (!skillsToWorkOn || !contentsWithSkills) {
            return [];
        }

        return skillsToWorkOn
            .map((skill) => ({
                skill,
                contents: contentsWithSkills.filter((content) =>
                    content.skills.some((s) => s.skillLevel2Id === skill.id)
                ),
            }))
            .filter((group) => group.contents.length > 0);
    }, [skillsToWorkOn, contentsWithSkills]);

    return (
        <>
            <h3 style={{color: "#823d90", margin: "0 12px"}}>
                {t("analyticsPages.recommendation_page.recommendation_title")}
            </h3>
            {error ? <p style={{color: "red"}}>Error: {error.message}</p> : null}
            {isLoading ||
            isProcessingContentsSkillData ||
            isProcessingRecommendationData ||
            isProcessingContentFeedbackAverage ? (
                <div style={{alignSelf: "center"}}>
                    <RotatingTriangles
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="rotating-triangels-loading"
                        wrapperClass="rotating-triangels-wrapper"
                        colors={["#018cd5", "#de2781", "#f19700"]}
                    />
                </div>
            ) : (
                groupedContents.slice(0, 5).map((group, index) => (
                    <div className="analytics__stats-content--card" key={index}>
                        <DetailCard
                            hasHeader={true}
                            headerTitle={group.skill.name}
                            iconLeft={[
                                [StarBlueDetails, StarRoseDetails, StarYellowDetails][
                                group.skill.parentId - 1
                                    ],
                            ]}
                            themeColor="#823d90"
                        >
                            <RecommendationTable
                                contents={group.contents}
                                contentsFeedback={contentsFeedback}
                                rowsToInitiallyShow={rowsToInitiallyShow}
                                rowsToAdd={rowsToAdd}
                                currentChildSelected={props.currentChildSelected}
                                referentData={props.referentData}
                            />
                        </DetailCard>
                    </div>
                ))
            )}
        </>
    );
};

export default Recommendations;
