import URLS from "../../config/api-urls";

export interface ChildSkillHistoryRequestBody {
  ChildId: number;
}

export const fetchChildSkillHistoryApi = async (
  body: ChildSkillHistoryRequestBody
): Promise<any> => {
  const url = URLS.POST_ChildSkillHistory;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};
