import React from "react";

import "./detail-card.scss";

export interface IconProps {
  src: string;
  alt: string;
  onClick?: () => void;
}

type DetailCardIcon = IconProps | React.ReactElement; // update wytopia v0.69.0

interface DetailCardProps {
  hasHeader?: boolean;
  headerTitle?: string;
  hasSubtitle?: boolean;
  subtitleContent?: string;
  iconLeft?: DetailCardIcon | DetailCardIcon[] | null; // update wytopia v0.69.0
  iconRight?: DetailCardIcon | DetailCardIcon[] | null; // update wytopia v0.69.0
  iconSize?: "small" | "medium" | "big";
  children: React.ReactNode;
  themeColor: string;
  extraTopComponent?: React.ReactNode;
  bottomLeftInfo?: string;
  bottomRightInfo?: string;
}

const DetailCard: React.FC<DetailCardProps> = (props: DetailCardProps) => {
  const iconClass = `detail-card__icon icon--${props.iconSize || "medium"}`;

  const renderIcons = (iconProp: DetailCardIcon | DetailCardIcon[]) => {
    const icons = Array.isArray(iconProp) ? iconProp : [iconProp]; // update wytopia v0.69.0
    return icons.map((icon, index) => {
      if (React.isValidElement(icon)) {
        // update wytopia v0.69.0
        return (
            <div key={index}>
              {icon}
            </div>
        );
      } else if (icon && typeof icon === 'object' && 'src' in icon && 'alt' in icon) { // update wytopia v0.69.0
        return (
            <img
                key={index}
                className={iconClass}
                src={icon.src}
                alt={icon.alt}
                onClick={icon.onClick}
            />
        );
      } else {
        return null;
      }
    });
  };

  return (
      <div className="detail-card">
        {props.extraTopComponent && (
            <div className="detail-card__extra-component">
              {props.extraTopComponent}
            </div>
        )}
        {props.hasHeader && (
            <div className="detail-card__header">
              {props.iconLeft && (
                  <div className="detail-card__icon-container--left">
                    {renderIcons(props.iconLeft)} {/* update wytopia v0.69.0 */}
                  </div>
              )}

              {props.headerTitle && (
                  <div className="detail-card__title">{props.headerTitle}</div>
              )}

              {props.iconRight && (
                  <div className="detail-card__icon-container--right">
                    {renderIcons(props.iconRight)} {/* update wytopia v0.69.0 */}
                  </div>
              )}
            </div>
        )}
        {props.hasSubtitle && props.subtitleContent && (
            <div
                className="detail-card__subtitle"
                style={{ color: props.themeColor }}
            >
              {props.subtitleContent}
            </div>
        )}
        <div className="detail-card__content">{props.children}</div>

        <div className="detail-card__footer">
          {props.bottomLeftInfo ? <div>{props.bottomLeftInfo}</div> : null}
          {props.bottomRightInfo ? <div>{props.bottomRightInfo}</div> : null}
        </div>
      </div>
  );
};

export default DetailCard;
