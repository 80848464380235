import URLS from "../../config/api-urls";

export interface ReferentAccountDetailsRequestBody {
  referentId: number;
  token: string;
}

export const fetchReferentAccountDetailsApi = async (
  body: ReferentAccountDetailsRequestBody
): Promise<any> => {
  const url = URLS.POST_GetReferent;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    return await response.json();
  } catch (error) {
    throw error;
  }
};
