import React from "react";

interface ProgressBarProps {
  bgcolor?: string;
  completed: number;
  maxValue?: number;
  barWidth?: "small" | "medium" | "big";
}

const ProgressBar: React.FC<ProgressBarProps> = ({
  bgcolor,
  completed,
  maxValue = 100,
  barWidth = "medium",
}) => {
  const convertBarWidthToStyleValue = (
    barWidth: "small" | "medium" | "big"
  ) => {
    switch (barWidth) {
      case "small":
        return "5px";
      case "medium":
        return "10px";
      case "big":
        return "15px";
      default:
        return "10px";
    }
  };

  const containerStyles = {
    height: convertBarWidthToStyleValue(barWidth),
    width: "100%",
    backgroundColor: "#e0e0de",
    borderRadius: 50,
  };

  const fillerStyles: React.CSSProperties = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: bgcolor ? bgcolor : "#823d90",
    borderRadius: "inherit",
    textAlign: "right" as "right",
    transition: "width 1s ease-in-out",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}></div>
    </div>
  );
};

export default ProgressBar;
