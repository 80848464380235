import { useEffect, useState } from "react";
import { useAnalytics } from "../pages/analytics/analytics-context";
import {
  HistoryDataInput,
  HistoryDataOutput,
  HistoryData,
} from "../interfaces/analytics-interfaces/history-interface";
import {
  filterAndFormatData,
  getDateLimit,
  groupDataByContentId,
  mergeWithChildData,
} from "../helper/analytics-helpers/history-helper";

export const useHistoryData = (props: HistoryDataInput): HistoryDataOutput => {
  const {
    fetchContents,
    fetchChildGameProgresses,
    fetchChildActivityTime,
    fetchChildQuizAnswers,
    fetchChildFeedbacks,
    fetchGameSettings,
    fetchChildContentProgresses,
    contentsData,
    childGameProgressesData,
    childActivityTimeData,
    childQuizAnswersData,
    childFeedbacksData,
    gameSettingsData,
    childContentProgressesData,
  } = useAnalytics();

  const [allRequestsFetched, setAllRequestsFetched] = useState<boolean>(false);
  const [historyData, setHistoryData] = useState<HistoryData[]>([]);
  const [isProcessingHistoryData, setIsProcessingHistoryData] =
    useState<boolean>(false);

  useEffect(() => {
    Promise.all([
      fetchContents(props.actualLanguage),
      fetchChildGameProgresses(props.childrenData.childId),
      fetchChildActivityTime(props.childrenData.childId),
      fetchChildQuizAnswers(props.childrenData.childId),
      fetchChildFeedbacks(props.childrenData.childId),
      fetchGameSettings(),
      fetchChildContentProgresses(props.childrenData.childId),
    ]).then(() => {
      setAllRequestsFetched(true);
    });
  }, [props.childrenData, props.actualLanguage]);

  useEffect(() => {
    if (
      allRequestsFetched &&
      childActivityTimeData &&
      childQuizAnswersData &&
      childFeedbacksData &&
      gameSettingsData &&
      childGameProgressesData &&
      childContentProgressesData &&
      contentsData &&
      props.childrenData.childId
    ) {
      setIsProcessingHistoryData(true);

      const dataGroupedByContentId = groupDataByContentId(
        contentsData,
        props.childrenData.childId
      );

      const dataGroupedWithChildId = mergeWithChildData(
        dataGroupedByContentId,
        childActivityTimeData,
        childQuizAnswersData,
        childFeedbacksData,
        gameSettingsData,
        childGameProgressesData
      );
      
      const filteredChildHistoryData = filterAndFormatData(
        dataGroupedWithChildId,
        childContentProgressesData,
        props.dateLimit
      );

      setHistoryData(filteredChildHistoryData);
      setIsProcessingHistoryData(false);
    }
  }, [
    allRequestsFetched,
    childActivityTimeData,
    childContentProgressesData,
    childFeedbacksData,
    childGameProgressesData,
    childQuizAnswersData,
    contentsData,
    gameSettingsData,
    props.childrenData.childId,
    props.dateLimit,
  ]);

  return {
    isProcessingHistoryData: isProcessingHistoryData || !allRequestsFetched,
    historyData,
  };
};
