import StarBlue from "../resources/images/icons/etoile_bleue.png";
import StarRose from "../resources/images/icons/etoile_rose.png";
import StarYellow from "../resources/images/icons/etoile_jaune.png";
import { FilterType } from "../interfaces/skill-map-interfaces/skill-map-interface";
import { IconProps } from "../components/detailCard/detail-card";

import ButtonAll from "../resources/images/icons/menu_skills.png";
import ButtonBlue from "../resources/images/icons/button_blue.png";
import ButtonRose from "../resources/images/icons/button_rose.png";
import ButtonYellow from "../resources/images/icons/button_yellow.png";
import ButtonAllGrey from "../resources/images/icons/menu_skills_grey_dark.png";
import ButtonGrey from "../resources/images/icons/button_grey_dark.png";

export const StarBlueDetails: IconProps = { src: StarBlue, alt: "Star Blue" };
export const StarRoseDetails: IconProps = { src: StarRose, alt: "Star Rose" };
export const StarYellowDetails: IconProps = {
  src: StarYellow,
  alt: "Star Yellow",
};

export const IconMap: Record<FilterType, IconProps[]> = {
  Level1: [StarBlueDetails, StarRoseDetails, StarYellowDetails],
  Level1Skill1: [StarBlueDetails],
  Level1Skill2: [StarRoseDetails],
  Level1Skill3: [StarYellowDetails],
};

export const DefaultIcons = [
  StarBlueDetails,
  StarRoseDetails,
  StarYellowDetails,
];

export {
  ButtonAll,
  ButtonAllGrey,
  ButtonBlue,
  ButtonGrey,
  ButtonRose,
  ButtonYellow,
};
