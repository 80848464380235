import React, { useEffect, useMemo, useState } from "react";

import "./history-table.scss";
import { HistoryTableProps } from "../../../interfaces/analytics-interfaces/history-interface";
import {
  CategoryGameIconDetails,
  CategoryVideoIconDetails,
  ShowMoreIconDetails,
} from "../../../constants/recommendation-constants";
import { IconButtonProps } from "../../../interfaces/recommendations-interfaces/recommendations-interface";
import HistoryTableRow from "./history-table-row";

const IconButton: React.FC<IconButtonProps> = (props: IconButtonProps) => {
  return (
    <button className="icon-button" onClick={props.onClick}>
      <img
        src={props.iconDetails.src}
        alt={props.iconDetails.alt}
        className="icon-button__image"
        style={props.isRecommendationSent ? { opacity: "0.25" } : {}}
      />
    </button>
  );
};

const HistoryTable: React.FC<HistoryTableProps> = (
  props: HistoryTableProps
) => {
  const [rowsToShow, setRowsToShow] = useState(props.rowsToInitiallyShow);

  return (
    <table className="recommendation-table">
      <thead className="recommendation-table__head">
        <tr className="recommendation-table__row">
          <th className="recommendation-table__header">Name</th>
          <th className="recommendation-table__header">Time (min)</th>
          <th className="recommendation-table__header">Status</th>
          <th className="recommendation-table__header">Goal</th>
          <th className="recommendation-table__header">Feedback</th>
        </tr>
      </thead>
      <tbody className="recommendation-table__body">
        {props.historyData.slice(0, rowsToShow).map((record, index) => {
          let activityGoal: string;
          if (
            record.activityFormat === "Game" ||
            record.activityFormat === "Games"
          ) {
            activityGoal = `${record.childGameProgressLevel ?? "0"}/${
              record.activityGameLevelToReach ?? "0"
            }`;
          } else {
            activityGoal = `${record.childQuizCorrectAnswers ?? "0"}/${
              record.activityQuizExpectedAnswers ?? "0"
            }`;
          }

          return (
            <HistoryTableRow
              key={index}
              activityTitle={record.activityTitle}
              activityFormat={record.activityFormat}
              activityCategoryIcon={
                record.activityFormat === "Games"
                  ? CategoryGameIconDetails
                  : CategoryVideoIconDetails
              }
              activityTime={record.childActivityTime}
              activityStatus={record.activityStatus}
              activityGoal={activityGoal}
              childactivityFeedback={record.childActivityFeedback ?? 0}
            />
          );
        })}
      </tbody>
      {rowsToShow < props.historyData?.length && (
        <tfoot className="recommendation-table__foot">
          <tr>
            <td colSpan={4}>
              <IconButton
                iconDetails={ShowMoreIconDetails}
                onClick={() => {
                  setRowsToShow((prev) => prev + props.rowsToAdd);
                }}
              />
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  );
};

export default HistoryTable;
