import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import "./legal-pages.scss";

interface ContentItem {
    name: string | null;
    format: string;
    ip_title: string | null;
    ip_editor_producer: string | null;
    ip_date_release: string | null;
}

const ContentAttribution: React.FC = () => {
    const { t } = useTranslation();
    const [videoContents, setVideoContents] = useState<ContentItem[]>([]);
    const [gameContents, setGameContents] = useState<ContentItem[]>([]);
    const [musicContents, setMusicContents] = useState<ContentItem[]>([]);

    const [videoLimit, setVideoLimit] = useState<number>(10);
    const [gameLimit, setGameLimit] = useState<number>(10);
    const [musicLimit, setMusicLimit] = useState<number>(10);

    useEffect(() => {
        fetch(process.env.PUBLIC_URL + '/contents/content-attribution-data.json')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }
                return response.json();
            })
            .then((data: ContentItem[]) => {
                const videos = data
                    .filter(item => item.format === 'Video')
                    .sort((a, b) => (a.name || '').localeCompare(b.name || ''));

                const games = data
                    .filter(item => item.format === 'Game')
                    .sort((a, b) => (a.name || '').localeCompare(b.name || ''));

                const musics = data
                    .filter(item => item.format === 'Music')
                    .sort((a, b) => (a.name || '').localeCompare(b.name || ''));

                setVideoContents(videos);
                setGameContents(games);
                setMusicContents(musics);
            })
            .catch(error => console.error('Error fetching data:', error));
    }, []);

    const loadMoreVideos = () => setVideoLimit(prev => prev + 10);
    const loadMoreGames = () => setGameLimit(prev => prev + 10);
    const loadMoreMusics = () => setMusicLimit(prev => prev + 10);

    return (
        <div className="content-page--legal">
            <h1>{t('contentAttribution.title')}</h1>
            <h3>{t('contentAttribution.effectiveDate')}</h3>
            <h3>{t('contentAttribution.lastUpdated')}</h3>

            <p>{t('contentAttribution.intro1')}</p>

            <p>
                {t('contentAttribution.intro2')}
                <a href={`mailto:${t('contentAttribution.email')}`}>{t('contentAttribution.email')}</a>.
            </p>

            <h2>{t('contentAttribution.sections.videos')}</h2>
            <table className="content-attribution-table">
                <thead>
                <tr>
                    <th>{t('contentAttribution.tableHeaders.title')}</th>
                    <th>{t('contentAttribution.tableHeaders.producer')}</th>
                    <th>{t('contentAttribution.tableHeaders.releaseDate')}</th>
                </tr>
                </thead>
                <tbody>
                {videoContents.slice(0, videoLimit).map((video, index) => (
                    <tr key={index}>
                        <td>{video.ip_title || video.name}</td>
                        <td>{video.ip_editor_producer || t('contentAttribution.unknownProducer')}</td>
                        <td>{video.ip_date_release || t('contentAttribution.unknownDate')}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            {videoContents.length > videoLimit && (
                <button className="load-more-button" onClick={loadMoreVideos}>{t('contentAttribution.buttons.loadMore')}</button>
            )}

            <h2>{t('contentAttribution.sections.games')}</h2>
            <table className="content-attribution-table">
                <thead>
                <tr>
                    <th>{t('contentAttribution.tableHeaders.title')}</th>
                    <th>{t('contentAttribution.tableHeaders.producer')}</th>
                    <th>{t('contentAttribution.tableHeaders.releaseDate')}</th>
                </tr>
                </thead>
                <tbody>
                {gameContents.slice(0, gameLimit).map((game, index) => (
                    <tr key={index}>
                        <td>{game.ip_title || game.name}</td>
                        <td>{game.ip_editor_producer || t('contentAttribution.unknownProducer')}</td>
                        <td>{game.ip_date_release || t('contentAttribution.unknownDate')}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            {gameContents.length > gameLimit && (
                <button className="load-more-button" onClick={loadMoreGames}>{t('contentAttribution.buttons.loadMore')}</button>
            )}

            <h2>{t('contentAttribution.sections.music')}</h2>
            <table className="content-attribution-table">
                <thead>
                <tr>
                    <th>{t('contentAttribution.tableHeaders.title')}</th>
                    <th>{t('contentAttribution.tableHeaders.artist')}</th>
                    <th>{t('contentAttribution.tableHeaders.releaseDate')}</th>
                </tr>
                </thead>
                <tbody>
                {musicContents.slice(0, musicLimit).map((music, index) => (
                    <tr key={index}>
                        <td>{music.ip_title || music.name}</td>
                        <td>{music.ip_editor_producer || t('contentAttribution.unknownArtist')}</td>
                        <td>{music.ip_date_release || t('contentAttribution.unknownDate')}</td>
                    </tr>
                ))}
                </tbody>
            </table>
            {musicContents.length > musicLimit && (
                <button className="load-more-button" onClick={loadMoreMusics}>{t('contentAttribution.buttons.loadMore')}</button>
            )}
        </div>
    );
}

export default ContentAttribution;
