import { IconProps } from "../components/detailCard/detail-card";
import SendRecoRedIcon from "../resources/images/icons/message_rouge.png";
import SendRecoBlueIcon from "../resources/images/icons/message_bleu.png";
import FeedBackIcon from "../resources/images/icons/smiley-green.png";
import ShowMoreIconViolet from "../resources/images/icons/plus-violet.png";
import CategoryGameIcon from "../resources/images/icons/jeux_bleu.png";
import CategoryVideoIcon from "../resources/images/icons/video_rouge.png";
import CloseIcon from "../resources/images/icons/fermer_violet.png";
import SuggestIcon from "../resources/images/icons/proposer_blanc.png";
import ImposeIcon from "../resources/images/icons/imposer_blanc.png";
import FamilyIcon from "../resources/images/avatars/Groupe_512.png";

export const SendIconRedDetails: IconProps = {
  src: SendRecoRedIcon,
  alt: "Send Icon",
};

export const SendIconBlueDetails: IconProps = {
  src: SendRecoBlueIcon,
  alt: "Send Icon",
};

export const FeedBackIconDetails: IconProps = {
  src: FeedBackIcon,
  alt: "FeedBack Icon",
};

export const ShowMoreIconDetails: IconProps = {
  src: ShowMoreIconViolet,
  alt: "Show More Icon",
};

export const CategoryGameIconDetails: IconProps = {
  src: CategoryGameIcon,
  alt: "Category Game Icon",
};

export const CategoryVideoIconDetails: IconProps = {
  src: CategoryVideoIcon,
  alt: "Category Video Icon",
};

export const CloseIconDetails: IconProps = {
  src: CloseIcon,
  alt: "Close Icon",
};

export const SuggestIconDetails: IconProps = {
  src: SuggestIcon,
  alt: "Suggest Icon",
};

export const ImposeIconDetails: IconProps = {
  src: ImposeIcon,
  alt: "Impose Icon",
};

export const FamilyIconDetails: IconProps = {
  src: FamilyIcon,
  alt: "Family Icon",
};

export const CATEGORIES = {
  1: "Animals",
  2: "Business Simulation",
  3: "Coding - programming",
  4: "Compliance - Precision - Observation",
  5: "Cooking",
  6: "Creative - Artistic activity",
  7: "Creative - Artistic game",
  8: "Cultural awarness - Museums",
  9: "Disguise - Make up",
  10: "Famous people",
  11: "Famous places",
  12: "Foreign language",
  13: "General Culture",
  14: "Geometry - Shapes",
  15: "Language - Words",
  16: "Logic - Brain",
  17: "Memory",
  18: "Music - Dancing",
  19: "Nature wonders",
  20: "Numbers - Calculation",
  21: "Plants",
  22: "Puzzle - Assembling",
  23: "Real Life extension",
  24: "Science - Experiences",
  25: "Self awareness",
  26: "Social Responsibility",
  27: "Tutorials - How to",
  28: "Team work",
  29: "Strategy - Tactic",
  30: "World citizen awareness",
  31: "Arabic",
  32: "Blues",
  33: "Chinese",
  34: "Classic",
  35: "Electronics",
  36: "French",
  37: "German",
  38: "Gospel",
  39: "HipHop",
  40: "Italian",
  41: "Japanese",
  42: "Jazz",
  43: "Korean",
  44: "Latin",
  45: "Lounge",
  46: "Metal",
  47: "Pop",
  48: "Rap",
  49: "Reggae",
  50: "Rock",
  51: "Spanish",
  52: "World",
  53: "Sport",
};
