import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./confirmation-popup.scss";
import { CloseIconDetails } from "../../../constants/recommendation-constants";
import {ConfirmationPopupProps} from "../../../interfaces/settings-interfaces/settings-analytics-interface";

const ConfirmationPopup: React.FC<ConfirmationPopupProps> = ({
                                                               childId,
                                                               referentData,
                                                               onClose,
                                                               onConfirmDelete,
                                                             }) => {
  const { t } = useTranslation();
  const [childName, setChildName] = useState("");
  const [childToken, setChildToken] = useState("");

  useEffect(() => {
      const foundChild = referentData?.children?.find(
        (child) => child.childId === childId
    );

    if (foundChild) {
      setChildName(foundChild.userName || "");
      setChildToken(foundChild.token || "");
    }
  }, [childId, referentData]);

  return (
      <div className="confirmation-popup">
        <div className="confirmation-popup__background"></div>
        <div className="confirmation-popup__container">
          <button
              className="confirmation-popup__header__close"
              onClick={onClose}
          >
            <img src={CloseIconDetails.src} alt={CloseIconDetails.alt} />
          </button>
          <div className="recommendation-popup__header">
            <p>{t("settingsPage.delete_popup.delete_title")}</p>
          </div>
          <div className="confirmation-popup__infos">
            <p>
              {t("settingsPage.delete_popup.confirm_delete_child_part1")}
              {childName}
              {t("settingsPage.delete_popup.confirm_delete_child_part2")}
            </p>
          </div>
          <div className="confirmation-popup__choices">
            <button
                className="confirmation-popup__choices--cancel"
                onClick={onClose}
            >
              {t("settingsPage.delete_popup.cancel_delete_btn")}
            </button>
            <button
                className="confirmation-popup__choices--impose"
                onClick={(e) => onConfirmDelete(e, childId, childToken)}
            >
              {t("settingsPage.delete_popup.confirm_delete_btn")}
            </button>
          </div>
        </div>
      </div>
  );
};

export default ConfirmationPopup;
