import { IconProps } from "../components/detailCard/detail-card";
import CircleGreenIcon from "../resources/images/icons/circle-green.png";
import CircleYellowIcon from "../resources/images/icons/circle-yellow.png";
import CircleRedIcon from "../resources/images/icons/circle-red.png";
import CircleGreyIcon from "../resources/images/icons/circle-grey.png";

export const CircleGreenIconDetails: IconProps = {
  src: CircleGreenIcon,
  alt: "circle green Icon",
};

export const CircleYellowIconDetails: IconProps = {
  src: CircleYellowIcon,
  alt: "circle yellow Icon",
};

export const CircleRedIconDetails: IconProps = {
  src: CircleRedIcon,
  alt: "circle red Icon",
};

export const CircleGreyIconDetails: IconProps = {
  src: CircleGreyIcon,
  alt: "circle grey Icon",
};
