import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import "./day-counter.scss";

const DayCounter = (props) => {
  const { t, i18n } = useTranslation();
  const { day, inputValue, setDaysCount, isRequired } = props;
  const [count, setCount] = useState(inputValue);

  const handleIncrement = (event) => {
    event.preventDefault();

    setCount(count + 1);
    setDaysCount((prevState) => {
      return {
        ...prevState,
        [day]: prevState[day] + 1,
      };
    });
  };

  const handleDecrement = (event) => {
    event.preventDefault();

    setCount(count - 1);
    setDaysCount((prevState) => {
      return {
        ...prevState,
        [day]: prevState[day] - 1,
      };
    });
  };

  return (
    <div className="day-counter">
      <div className="day-name">{t("days." + day.toLowerCase())}</div>
      <button onClick={handleDecrement} disabled={count <= 2}>
        -
      </button>
      <span className="count">{count}</span>
      <button onClick={handleIncrement} disabled={count >= 6}>
        +
      </button>
    </div>
  );
};

export default DayCounter;
