import axios from "axios";
import isEqual from "lodash/isEqual";
import Cookies from "js-cookie";
import { ReferentBaseModel } from "../../../../interfaces/referent-interfaces/parents-interface";
import URLS from "../../../../config/api-urls";

/**
 * Updates the parent information, including password.
 */
export const updateParent = async (formData: ReferentBaseModel, referentData: ReferentBaseModel) => {
    console.log("Starting parent update...");
    const parentPayload = {
        email: formData.email,
        password: formData.password,
        token: referentData.token,
    };

    console.log("Parent payload to be sent:", parentPayload);

    try {
        const response = await axios.post(URLS.POST_UpdateParent, parentPayload, {
            headers: { "Content-Type": "application/json" },
        });

        console.log("Parent update response from server:", response.data);

        if (response.data && response.data.statusMessage.includes("success")) {
            Cookies.set("tokenSession", response.data.object.token);
            console.log("Parent update successful. New token:", response.data.object.token);
            return response.data.object;
        }

        throw new Error("Failed to update parent data");
    } catch (error) {
        console.error("Error during parent update:", error);
        throw error;
    }
};


/**
 * Updates a child's settings such as sleep modes and screen time.
 */
export const updateChild = async (child) => {
    console.log(`Starting child update for childId ${child.childId}...`);

    const childPayload = {
        ChildId: child.childId,
        UserName: child.userName,
        Gender: child.gender,
        Email: child.email,
        DateofBirth: child.dateofBirth,
        Country: child.country,
        IsActive: child.isActive,
        IsDelete: child.isDelete,
        AvatarId: child.avatarId,
        Token: child.token,
        Access: child.access,
    };

    console.log(`Child payload for childId ${child.childId} to be sent:`, childPayload);

    try {
        const response = await axios.post(URLS.POST_UpdateChild, childPayload, {
            headers: { "Content-Type": "application/json" },
        });

        console.log(`Child update response from server for childId ${child.childId}:`, response.data);

        if (response.data && response.data.statusMessage.includes("success")) {
            return response.data.object; // Return updated child data
        }

        throw new Error(`Failed to update child data for childId ${child.childId}`);
    } catch (error) {
        console.error(`Error during child update for childId ${child.childId}:`, error);
        throw error;
    }
};


/**
 * Handles the full submission process for updating parent and child settings.
 */
export const handleSubmit = async (
    formData: ReferentBaseModel,
    initialFormData: ReferentBaseModel,
    referentData: ReferentBaseModel,
    updateReferentData: (data: ReferentBaseModel) => void
) => {
    console.log("[handleSubmit] Starting submission process...");
    console.log("[handleSubmit] Initial formData:", formData);
    console.log("[handleSubmit] Initial referentData:", referentData);

    try {
        let updatedReferentData = { ...referentData };
        let referentDataChanged = false;

        // Handle parent updates (e.g., password change)
        if (formData.password && formData.password !== initialFormData.password) {
            console.log("[handleSubmit] Detected parent password change. Updating...");
            const updatedParentData = await updateParent(formData, referentData);
            updatedReferentData = { ...updatedReferentData, ...updatedParentData };
            referentDataChanged = true;
        } else {
            console.log("[handleSubmit] No changes detected in parent data.");
        }

        // Handle child updates (e.g., sleep modes, screen time)
        const childUpdatePromises = formData.children.map((child) => {
            const initialChild = initialFormData.children.find((c) => c.childId === child.childId);

            if (
                !isEqual(child.access.maximumScreenTimes, initialChild.access.maximumScreenTimes) ||
                !isEqual(child.access.sleepModes, initialChild.access.sleepModes)
            ) {
                console.log(`[handleSubmit] Changes detected for childId ${child.childId}. Adding to update queue...`);
                return updateChild(child);
            }

            console.log(`[handleSubmit] No changes detected for childId ${child.childId}.`);
            return null;
        });

        await Promise.all(childUpdatePromises.filter(Boolean));

        // Update children in referentData with data from formData
        formData.children.forEach((updatedChild) => {
            const childIndex = updatedReferentData.children.findIndex(
                (c) => c.childId === updatedChild.childId
            );

            if (childIndex !== -1) {
                console.log(`[handleSubmit] Updating local referentData for childId ${updatedChild.childId}...`);
                updatedReferentData.children[childIndex] = updatedChild;
            }
        });

        // Update referentData context if there are any changes
        if (referentDataChanged || childUpdatePromises.filter(Boolean).length > 0) {
            console.log("[handleSubmit] Applying updates to referentData context...");
            updateReferentData(updatedReferentData);
        } else {
            console.log("[handleSubmit] No updates to apply to referentData context.");
        }

        console.log("[handleSubmit] Submission process completed successfully.");
        return true; // Success
    } catch (error) {
        console.error("[handleSubmit] Error during submission process:", error);
        throw error;
    }
};

