import { useEffect, useState } from "react";

import { useAnalytics } from "../pages/analytics/analytics-context";
import {
  UseContentsSkillInput,
  UseContentsSkillOutput,
  ContentsWithSkills,
} from "../interfaces/recommendations-interfaces/recommendations-interface";
import {
  filterContentsWithoutLocationId,
  groupContentsWithSkills,
} from "../helper/analytics-helpers/recommendations-helpers/recommendations-helper";

export const useContentsSkillData = ({
  childrenData,
  actualLanguage,
  filterActivitiesAlreadyDone,
}: UseContentsSkillInput): UseContentsSkillOutput => {
  const {
    contentsData,
    contentSkillsData,
    childContentProgressesData,
    fetchContents,
    fetchContentSkills,
    fetchChildContentProgresses,
  } = useAnalytics();

  const [allRequestsFetched, setAllRequestsFetched] = useState<boolean>(false);
  const [isProcessingContentsSkillData, setIsProcessingContentsSkillData] =
    useState<boolean>(false);
  const [contentsWithSkills, setContentsWithSkills] = useState<
    ContentsWithSkills[]
  >([]);

  useEffect(() => {
    Promise.all([
      fetchContentSkills(), // data of which skill is worked on each content
      fetchContents(actualLanguage), // data of contents to get name, category ...
      fetchChildContentProgresses(childrenData.childId),
    ]).then(() => {
      setAllRequestsFetched(true);
    });
  }, [childrenData]);

  useEffect(() => {
    if (
      allRequestsFetched &&
      contentsData &&
      contentSkillsData &&
      childContentProgressesData
    ) {
      setIsProcessingContentsSkillData(true);

      let finalContents = contentsData.object;

      if (filterActivitiesAlreadyDone && childContentProgressesData.object) {
        const contentIdsToBeRemoved = childContentProgressesData.object.reduce(
          (acc, progress) => {
            if (progress.progressStatus === 1) {
              acc.add(progress.contentId);
            }
            return acc;
          },
          new Set<number>()
        );

        finalContents = contentsData.object.filter((content) => {
          return !contentIdsToBeRemoved.has(content.contentId);
        });
      }

      const groupedData = groupContentsWithSkills(
        finalContents,
        contentSkillsData.object
      );

      const groupedDataFiltred = filterContentsWithoutLocationId(groupedData);

      setIsProcessingContentsSkillData(false);
      setContentsWithSkills(groupedDataFiltred);
    }
  }, [
    allRequestsFetched,
    contentSkillsData,
    contentsData,
    childContentProgressesData,
    filterActivitiesAlreadyDone,
  ]);

  return {
    isProcessingContentsSkillData:
      isProcessingContentsSkillData || !allRequestsFetched,
    contentsWithSkills,
  };
};
