import { Bar } from "react-chartjs-2";

import { humanReadableDate } from "../../../helper/analytics-helpers/progress-helper";
import {
  generateBarChartConfig,
  generateBarChartOptions,
} from "../../../helper/chart-helper/chart-helper";

interface ProgressionBarChartProps {
  colors: string[];
  labels: string[];
  dataset: number[];
  dateFilter: string;
}

const ProgressionBarChart = (props: ProgressionBarChartProps) => {
  const humanReadableLabels = props?.labels?.map((label) =>
    humanReadableDate(label, props?.dateFilter)
  );

  const dataset = props?.dataset;

  const data = generateBarChartConfig(
    humanReadableLabels,
    dataset,
    props?.colors
  );
  const options = generateBarChartOptions(dataset);

  return (
    <div>
      <Bar data={data} options={options} />
    </div>
  );
};

export default ProgressionBarChart;
