import React from "react";

import "./progress-skill-table.scss";
import {
  StarBlueDetails,
  StarRoseDetails,
  StarYellowDetails,
} from "../../../constants/skill-map-constants";
import { updateSkillHistoryThemeColor } from "../../../helper/analytics-helpers/history-helper";
import { useTranslation } from "react-i18next";

interface SkillsTableProps {
  totalPointsWeek: { [key: number]: number };
  totalPointsMonth: { [key: number]: number };
  totalPointsYear: { [key: number]: number };
}

const ProgressSkillTable = (props: SkillsTableProps) => {
  const { t } = useTranslation();

  return (
    <table className="progress-skill-table">
      <thead className="progress-skill-table__header">
        <tr>
          <th className="progress-skill-table__header-cell">
            {t("analyticsPages.summary_page.skill")}
          </th>
          <th className="progress-skill-table__header-cell">
            {t("analyticsPages.summary_page.this_week")}
          </th>
          <th className="progress-skill-table__header-cell">
            {t("analyticsPages.summary_page.this_month")}
          </th>
          <th className="progress-skill-table__header-cell">
            {t("analyticsPages.summary_page.this_year")}
          </th>
        </tr>
      </thead>
      <tbody>
        {Object.keys(props.totalPointsWeek).map((level) => {
          const starColor = updateSkillHistoryThemeColor(+level);

          return (
            <tr key={level} className="progress-skill-table__row">
              <td className="progress-skill-table__cell progress-skill-table__cell--highlight">
                <img
                  src={
                    starColor === "#59c0d1"
                      ? StarBlueDetails.src
                      : starColor === "#e8318b"
                      ? StarRoseDetails.src
                      : StarYellowDetails.src
                  }
                  alt={`Level ${level}`}
                />
              </td>
              <td className="progress-skill-table__cell">
                +{props.totalPointsWeek[+level] || 0}
              </td>
              <td className="progress-skill-table__cell">
                +{props.totalPointsMonth[+level] || 0}
              </td>
              <td className="progress-skill-table__cell">
                +{props.totalPointsYear[+level] || 0}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ProgressSkillTable;
