import { useEffect, useState } from "react";
import { useAnalytics } from "../pages/analytics/analytics-context";
import {
    UseRewardDataInput,
    UseRewardDataOutput,
    AvatarReward,
    BadgeReward,
    ItemReward,
    StickerReward,
    FriendReward,
} from "../interfaces/rewards-interfaces/rewards-interface";

export const useRewardData = ({
                                  childId,
                                  actualLanguage,
                              }: UseRewardDataInput): UseRewardDataOutput => {
    const {
        isLoading,
        error,
        // Rewards Data
        childAvatarRewardsData,
        childBadgeRewardsData,
        childItemRewardsData,
        childStickerRewardsData,
        childFriendsRewardsData,
        avatarsData,
        badgesData,
        itemsData,
        stickersData,
        friendsData,
        // Gold Data
        childGoldBalancesData,
        childTotalGoldsData,

        // Fetch Functions
        fetchAvatars,
        fetchBadges,
        fetchItems,
        fetchStickers,
        fetchFriends,
        fetchChildAvatarRewards,
        fetchChildBadgeRewards,
        fetchChildItemRewards,
        fetchChildStickerRewards,
        fetchChildFriendsRewards,
        fetchChildGoldBalances,
        fetchChildTotalGolds,
    } = useAnalytics();

    const [isFetching, setIsFetching] = useState(false);
    const [allRequestsFetched, setAllRequestsFetched] = useState<boolean>(false);
    const [isProcessingRewardData, setIsProcessingRewardData] =
        useState<boolean>(true);

    // State to hold all available rewards
    const [contentRewards, setContentRewards] = useState<{
        avatars: AvatarReward[];
        badges: BadgeReward[];
        items: ItemReward[];
        stickers: StickerReward[];
        friends: FriendReward[];
    }>({
        avatars: [],
        badges: [],
        items: [],
        stickers: [],
        friends: [],
    });

    // State to hold child's unlocked rewards
    const [childRewards, setChildRewards] = useState<{
        unlockedAvatars: AvatarReward[];
        unlockedBadges: BadgeReward[];
        unlockedItems: ItemReward[];
        unlockedStickers: StickerReward[];
        unlockedFriends: FriendReward[];
    }>({
        unlockedAvatars: [],
        unlockedBadges: [],
        unlockedItems: [],
        unlockedStickers: [],
        unlockedFriends: [],
    });

    // Gold data
    const [goldEarned, setGoldEarned] = useState<number>(0);
    const [goldUsed, setGoldUsed] = useState<number>(0);
    const [percentageCompleted, setPercentageCompleted] = useState<number>(0);

    useEffect(() => {
        if (!isFetching && childId) {
            setIsFetching(true);
            Promise.all([
                // Fetch all rewards
                fetchAvatars(),
                fetchBadges(actualLanguage),
                fetchItems(actualLanguage),
                fetchStickers(actualLanguage),
                fetchFriends(actualLanguage),
                // Fetch child's unlocked rewards and gold data
                fetchChildAvatarRewards(childId),
                fetchChildBadgeRewards(childId),
                fetchChildItemRewards(childId),
                fetchChildStickerRewards(childId),
                fetchChildFriendsRewards(childId),
                fetchChildGoldBalances(childId),
                fetchChildTotalGolds(childId),
            ]).then(() => {
                setAllRequestsFetched(true);
                setIsFetching(false);
            });
        }
    }, [childId]);

    useEffect(() => {
        if (allRequestsFetched && !isLoading && !error) {
            setIsProcessingRewardData(true);

            // Access the data from the 'object' property
            const avatars = avatarsData?.object || [];
            const badges = badgesData?.object || [];
            const items = itemsData?.object || [];
            const stickers = stickersData?.object || [];
            const friends = friendsData?.object || [];

            const childAvatars = childAvatarRewardsData?.object || [];
            const childBadges = childBadgeRewardsData?.object || [];
            const childItems = childItemRewardsData?.object || [];
            const childStickers = childStickerRewardsData?.object || [];
            const childFriends = childFriendsRewardsData?.object || [];

            // Map unlocked reward IDs for quick lookup
            const unlockedAvatarIds = new Set(
                childAvatars.map((reward) => reward.rewardId)
            );
            const unlockedBadgeIds = new Set(
                childBadges.map((reward) => reward.rewardId)
            );
            const unlockedItemIds = new Set(
                childItems.map((reward) => reward.rewardId)
            );
            const unlockedStickerIds = new Set(
                childStickers.map((reward) => reward.rewardId)
            );
            const unlockedFriendIds = new Set(
                childFriends.map((reward) => reward.friendId)
            );

            // Filter total rewards to get unlocked rewards
            const unlockedAvatars = avatars.filter((avatar) =>
                unlockedAvatarIds.has(avatar.avatarID)
            );
            const unlockedBadges = badges.filter((badge) =>
                unlockedBadgeIds.has(badge.id)
            );
            const unlockedItems = items.filter((item) =>
                unlockedItemIds.has(item.id)
            );
            const unlockedStickers = stickers.filter((sticker) =>
                unlockedStickerIds.has(sticker.id)
            );
            const unlockedFriends = friends.filter((friend) =>
                unlockedFriendIds.has(friend.id)
            );

            setContentRewards({
                avatars,
                badges,
                items,
                stickers,
                friends,
            });

            setChildRewards({
                unlockedAvatars,
                unlockedBadges,
                unlockedItems,
                unlockedStickers,
                unlockedFriends,
            });

            // Calculate total rewards
            const totalRewards =
                avatars.length +
                badges.length +
                items.length +
                stickers.length +
                friends.length;

            const totalUnlockedRewards =
                unlockedAvatars.length +
                unlockedBadges.length +
                unlockedItems.length +
                unlockedStickers.length +
                unlockedFriends.length;

            const percentageCompleted = totalRewards > 0
                ? Math.round((totalUnlockedRewards / totalRewards) * 100)
                : 0;

            setPercentageCompleted(percentageCompleted);

            // Calculate gold used and earned
            const goldUsed = childGoldBalancesData?.object
                ?.filter((balance) => balance.cost < 0)
                .reduce((total, balance) => total + balance.cost, 0) || 0;

            const totalGoldEarned = childTotalGoldsData?.object || 0;
            const goldEarned = totalGoldEarned - Math.abs(goldUsed);

            setGoldUsed(goldUsed);
            setGoldEarned(goldEarned);

            setIsProcessingRewardData(false);
        }
    }, [
        allRequestsFetched,
        isLoading,
        error,
        childAvatarRewardsData,
        childBadgeRewardsData,
        childItemRewardsData,
        childStickerRewardsData,
        childFriendsRewardsData,
        avatarsData,
        badgesData,
        itemsData,
        stickersData,
        friendsData,
        childGoldBalancesData,
        childTotalGoldsData,
    ]);

    return {
        isProcessingRewardData:
            isFetching || isProcessingRewardData || !allRequestsFetched || isLoading,
        contentRewards,
        childRewards,
        goldEarned,
        goldUsed,
        percentageCompleted,
        error,
    };
};
