import React, { useState } from "react";
import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";

import URLS from "../../config/api-urls";
import "./auth.scss";
import { regexEmail, regexUsername } from "../../constants/regex-constants";

type FormData = {
  email: string;
  username?: string;
};

const ForgotPassword: React.FC = () => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [responseStatus, setResponseStatus] = useState<
    "success" | "error" | null
  >(null);
  const [serverMessage, setServerMessage] = useState<string | null>(null);
  const [currentTab, setCurrentTab] = useState<"enfant" | "parent">("enfant");

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setIsLoading(true);
    let dataToSend = {};
    let urlToUse: string;

    if (currentTab === "enfant") {
      dataToSend = {
        Email: data.email,
        Username: data.username,
      };
      urlToUse = URLS.POST_AskResetPasswordChild;
    } else {
      dataToSend = {
        Email: data.email,
      };
      urlToUse = URLS.POST_AskResetPasswordParent;
    }

    try {
      const response = await axios.post(urlToUse, dataToSend);
      setServerMessage(response.data.statusMessage);
      setResponseStatus(response.data.status === 0 ? "error" : "success");
      setErrorMessage(null);
    } catch (error: any) {
      setServerMessage(
        error.response?.data.statusMessage || t("forms.error.server_error")
      );
      setResponseStatus("error");
    }

    setIsLoading(false);
  };

  return (
    <div className="auth">
      <div className="auth__container">
        <h2 className="auth__title">{t("forgotPasswordPage.title")}</h2>
        {responseStatus !== "success" && (
          <>
            <div className="auth__tabs">
              <button
                className={currentTab === "enfant" ? "active" : ""}
                onClick={() => setCurrentTab("enfant")}
              >
                {t("forgotPasswordPage.child_tab")}
              </button>
              <button
                className={currentTab === "parent" ? "active" : ""}
                onClick={() => setCurrentTab("parent")}
              >
                {t("forgotPasswordPage.parent_tab")}
              </button>
            </div>

            <form className="auth__form" onSubmit={handleSubmit(onSubmit)}>
              <label className="auth__label">
                {t("forgotPasswordPage.parent_email_label")}
                <input
                  type="email"
                  {...register("email", {
                    required: t("forms.error.email_required"),
                    pattern: {
                      value: regexEmail,
                      message: t("forms.error.invalid_email"),
                    },
                  })}
                  className="auth__input"
                />
                {errors.email?.message && (
                  <p className="auth__error-message">
                    {String(errors.email.message)}
                  </p>
                )}
              </label>

              {currentTab === "enfant" && (
                <label className="auth__label">
                  {t("forgotPasswordPage.child_username_label")}
                  <input
                    type="text"
                    {...register("username", {
                      required: t("forms.error.username_required"),
                      pattern: {
                        value: regexUsername,
                        message: t("forms.error.invalid_username"),
                      },
                    })}
                    className="auth__input"
                  />
                  {errors.username?.message && (
                    <p className="auth__error-message">
                      {String(errors.username.message)}
                    </p>
                  )}
                </label>
              )}

              <div className="auth__actions">
                {!serverMessage && (
                  <button
                    type="submit"
                    className="auth__submit-button"
                    disabled={isLoading}
                  >
                    {isLoading
                      ? t("forgotPasswordPage.loading")
                      : t("forgotPasswordPage.send_reset_email")}
                  </button>
                )}
              </div>
            </form>
          </>
        )}
        {serverMessage && (
          <div
            className={`auth__server-response ${
              responseStatus === "error" ? "error-border" : ""
            }`}
          >
            {serverMessage}
          </div>
        )}
        {errorMessage && <p className="auth__error-message">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default ForgotPassword;
