import React, {useContext, useEffect, useState} from "react";
import axios from "axios";
import {useTranslation} from "react-i18next";
import ConfirmationPopup from "../../../components/analytics/general/confirmation-popup";
import URLS from "../../../config/api-urls";
import "./settings-security.scss";

import {SettingsSecurityProps} from "../../../interfaces/settings-interfaces/settings-analytics-interface";
import {CheckIconDetails} from "../../../constants/settings-analytics-constants";
import {AuthContext} from "../../auth/auth-context";
import {setAuthCookies} from "../../../helper/auth-helpers/cookie-helper";
import {useNavigate} from "react-router";
import {ResponseDataUpdateReferent} from "../../../interfaces/auth-interfaces/auth-interface";

const SettingsSecurity: React.FC<SettingsSecurityProps> = ({
                                                               referentData,
                                                               setReferentData,
                                                           }) => {
    const {t} = useTranslation();
    const authContext = useContext(AuthContext);
    const navigate = useNavigate();

    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showWarningPopup, setShowWarningPopup] = useState(false);
    const [selectedChildId, setSelectedChildId] = useState<number | null>(null);

    const [newPassword, setNewPassword] = useState<string>("");
    const [errors, setErrors] = useState<{ newPassword?: string }>({});
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

    const validatePassword = (password: string) => {
        const validationErrors: { newPassword?: string } = {};
        if (password && (password.length < 4 || password.length > 32)) {
            validationErrors.newPassword = t("forms.error.invalid_password");
        }
        return validationErrors;
    };

    useEffect(() => {
        const validationErrors = validatePassword(newPassword);
        setErrors(validationErrors);
    }, [newPassword, t]);

    const handleDeleteClick = (
        event: React.MouseEvent<HTMLButtonElement>,
        childId: number
    ) => {
        event.preventDefault();

        if (referentData.children.length <= 1) {
            setShowWarningPopup(true); // Prevent deletion if only one child exists
        } else {
            setSelectedChildId(childId);
            setShowDeleteConfirm(true);
        }
    };

    const handleClosePopup = () => {
        setShowDeleteConfirm(false);
        setSelectedChildId(null);
    };

    const handleConfirmDelete = async (
        event: React.MouseEvent<HTMLButtonElement>,
        childId: number
    ) => {
        event.preventDefault();

        try {
            const child = referentData.children.find((c) => c.childId === childId);
            if (child && child.token) {
                const data = {ChildId: childId, Token: child.token};
                await axios.post(URLS.POST_DeleteChild, data, {
                    headers: {"Content-Type": "application/json"},
                });

                const updatedChildren = referentData.children.filter(
                    (c) => c.childId !== childId
                );

                setReferentData({
                    ...referentData,
                    children: updatedChildren,
                });

                handleClosePopup();
            }
        } catch (error) {
            // console.error("Error during deletion:", error);
        }
    };

    const handlePasswordChange = async () => {
        const validationErrors = validatePassword(newPassword);
        setErrors(validationErrors);

        if (Object.keys(validationErrors).length > 0) {
            return;
        }

        try {
            setIsSubmitting(true);

            const data = {
                email: referentData.email,
                password: newPassword,
                token: referentData.token,
            };

            const response = await axios.post<ResponseDataUpdateReferent>(URLS.POST_UpdateParent, data, {
                headers: { "Content-Type": "application/json" },
            });

            const responseData = response.data;

            if (responseData.status === 1 && responseData.statusMessage.includes("the update was successfull")) {
                const { token, referentId } = responseData.object;
                setAuthCookies(token, referentId.toString());

                authContext.setReferentData(responseData.object);
                authContext.setIsAuthenticated(true);

                setNewPassword("");
                setErrors({});
            } 
        } catch (error) {
            setErrors({ newPassword: t("forms.error.server_error") });
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="auth__analytics-settings-security">
            <form className="auth__analytics-settings-security__form">
                <div className="auth__analytics-settings-security__child-list">
                    <label className="auth__analytics-settings-security__label">
                        {t("analyticsPages.settings_page.child_list")}
                    </label>
                    {referentData.children.map((child) => (
                        <div
                            key={child.childId}
                            className="auth__analytics-settings-security__child-list--container"
                        >
                            <input
                                type="text"
                                value={child.userName}
                                disabled
                                className="auth__analytics-settings-security__input"
                            />
                            <button
                                onClick={(e) => handleDeleteClick(e, child.childId)}
                                disabled={referentData.children.length === 1}
                            >
                                {t("analyticsPages.settings_page.delete")}
                            </button>
                        </div>
                    ))}
                    {showDeleteConfirm && selectedChildId && (
                        <ConfirmationPopup
                            childId={selectedChildId}
                            referentData={referentData}
                            onClose={handleClosePopup}
                            onConfirmDelete={(e) => handleConfirmDelete(e, selectedChildId)}
                        />
                    )}
                </div>

                <label className="auth__analytics-settings-security__label">
                    {t("analyticsPages.settings_page.parent_email")}
                    <input
                        type="text"
                        value={referentData.email}
                        autoComplete="none"
                        disabled
                        className="auth__analytics-settings-security__input"
                    />
                </label>

                <label className="auth__analytics-settings-security__label">
                    {t("analyticsPages.settings_page.parent_password")}
                    <input
                        type="text"
                        name="newPassword"
                        autoComplete="new-password"
                        className={`auth__analytics-settings-security__input ${
                            errors.newPassword
                                ? "auth__analytics-settings-security__input--error"
                                : ""
                        }`}
                        value={newPassword}
                        onChange={(e) => {
                            setNewPassword(e.target.value);
                        }}
                    />
                    {errors.newPassword && (
                        <p className="auth__analytics-settings-security__error">
                            {errors.newPassword}
                        </p>
                    )}
                </label>

                {/* Show the save button only if the newPassword field is not empty */}
                {newPassword && (
                    <div className="auth__analytics-settings-security__password-submit-btn">
                        <button
                            type="button"
                            onClick={handlePasswordChange}
                            disabled={!newPassword || !!errors.newPassword}
                            className={
                                !newPassword || !!errors.newPassword ? "disabled" : "enabled"
                            }
                        >
                            <img src={CheckIconDetails.src} alt={CheckIconDetails.alt}/>
                            <p>{t("analyticsPages.settings_page.confirm_button")}</p>
                        </button>
                    </div>
                )}
            </form>
        </div>
    );
};

export default SettingsSecurity;
