import { useEffect, useState } from "react";

import { useAnalytics } from "../pages/analytics/analytics-context";
import { useSkillProgressData } from "./use-skill-progress-data";
import { findMostLaggingLevel2Skills } from "../helper/analytics-helpers/recommendations-helpers/recommendations-helper";
import {
  UseRecommendationInput,
  UseRecommendationOutput,
  SkillsRecommendedToWork,
} from "../interfaces/recommendations-interfaces/recommendations-interface";

export const useRecommendationData = ({
  childrenData,
  actualLanguage,
}: UseRecommendationInput): UseRecommendationOutput => {
  const { isLoading, skillsStatisticsData, fetchSkillsStatistics } =
    useAnalytics();

  const [isFetching, setIsFetching] = useState(false);
  const [allRequestsFetched, setAllRequestsFetched] = useState<boolean>(false);
  const [isProcessingRecommendationData, setIsProcessingRecommendationData] =
    useState<boolean>(true);
  const [skillsToWorkOn, setSkillsToWorkOn] = useState<
    SkillsRecommendedToWork[]
  >([]);

  useEffect(() => {
    if (!isFetching) {
      setIsFetching(true);
      Promise.all([
        fetchSkillsStatistics(), // data of points average and median of skillLevel3 of all world childs
      ]).then(() => {
        setAllRequestsFetched(true);
        setIsFetching(false);
      });
    }
  }, []);

  const { skillLevel2ProgressData, skillLevel3ProgressData } =
    useSkillProgressData({
      childrenData: childrenData,
      actualLanguage: actualLanguage,
    });

  useEffect(() => {
    if (
      allRequestsFetched &&
      skillLevel2ProgressData &&
      skillLevel3ProgressData &&
      skillsStatisticsData
    ) {
      setIsProcessingRecommendationData(true);

      const mostLaggingLevel2Skills = findMostLaggingLevel2Skills(
        skillLevel2ProgressData,
        skillLevel3ProgressData,
        skillsStatisticsData
      );

      const topFiveSkillsToWorkOn = mostLaggingLevel2Skills.slice(0, 5);

      setIsProcessingRecommendationData(false);

      setSkillsToWorkOn(mostLaggingLevel2Skills);
    }
  }, [
    allRequestsFetched,
    skillLevel2ProgressData,
    skillLevel3ProgressData,
    skillsStatisticsData,
  ]);

  return {
    isProcessingRecommendationData:
      isFetching ||
      isProcessingRecommendationData ||
      !allRequestsFetched ||
      isLoading,
    skillsToWorkOn,
  };
};
