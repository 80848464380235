import moment from "moment";
import {
  DailyDetail,
  ScreenTimeData,
} from "../../interfaces/analytics-interfaces/screen-time-interface";
import {
  ChildSessionTimeModel,
  ChildSessionTimeObject,
} from "../../interfaces/child-interfaces/child-interface";

export const calculateScreenTimeDetails = (
  childSessionTime: ChildSessionTimeModel,
  dateLimit: string,
  years: number = 1
): ScreenTimeData => {
  const totals = { totalSessionTimeInSeconds: 0, totalVisits: 0 };
  let dailyDetails = generateDataForDateRange(
    moment.utc().subtract(years, "years").startOf("day"),
    moment.utc().endOf("day")
  );

  if (!childSessionTime || !childSessionTime.object) {
    return;
  }

  childSessionTime.object.forEach((session) => {
    const sessionDate = moment
      .utc(session.startDateSession)
      .format("YYYY-MM-DD");
    if (dailyDetails[sessionDate]) {
      calculateDailyDetails(session, dailyDetails);
    } else {
      // handle error
    }
    calculateTotals(session, totals);
  });

  const { averageTimeInMinutes, averageSessions, totalSessions } =
    calculateAverages(dailyDetails, totals.totalVisits, dateLimit);

  const totalSessionTimeInMinutes = Math.ceil(
    totals.totalSessionTimeInSeconds / 60
  );

  return {
    childId: childSessionTime.object[0]?.childId || 0,
    totalSessionTimeInMinutes,
    totalSessions,
    periodFilter: dateLimit,
    averageTimeInMinutes,
    averageSessions,
    dailyDetails,
  };
};

const generateDataForDateRange = (start, end) => {
  const dailyDetails = {};
  for (let date = moment(start); date.isBefore(end); date.add(1, "days")) {
    const dateString = date.format("YYYY-MM-DD");
    dailyDetails[dateString] = { sessionTimeInMinutes: 0, sessions: 0 };
  }
  return dailyDetails;
};

const calculateDailyDetails = (
  session: ChildSessionTimeObject,
  dailyDetails: { [date: string]: DailyDetail }
) => {
  const startDate = moment(session.startDateSession);
  const dayKey = startDate.format("YYYY-MM-DD");
  dailyDetails[dayKey].sessionTimeInMinutes += Math.ceil(
    session.sessionTime / 60
  );
  dailyDetails[dayKey].sessions += 1;
};

const calculateTotals = (
  session: ChildSessionTimeObject,
  totals: { totalSessionTimeInSeconds: number; totalVisits: number }
) => {
  totals.totalSessionTimeInSeconds += session.sessionTime;
  totals.totalVisits++;
};

const calculateAverages = (
  dailyDetails: { [date: string]: DailyDetail },
  totalVisits: number,
  dateFilter: string
) => {
  let startDate;
  switch (dateFilter) {
    case "lastWeek":
      startDate = moment().subtract(6, "days");
      break;
    case "lastMonth":
      startDate = moment().subtract(1, "month").startOf("day");
      break;
    case "lastYear":
      startDate = moment().subtract(1, "year").startOf("day");
      break;
    default:
      startDate = moment().subtract(6, "days");
  }

  const endDate = moment().endOf("day");
  const relevantDays = Object.keys(dailyDetails).filter((date) => {
    const momentDate = moment(date);
    return momentDate.isBetween(startDate, endDate, undefined, "[]");
  });

  const totalSessionTime = relevantDays.reduce((total, date) => {
    return total + dailyDetails[date].sessionTimeInMinutes;
  }, 0);

  const totalDays = relevantDays.length;
  const averageTimeInMinutes = totalDays > 0 ? totalSessionTime / totalDays : 0;
  const averageSessions = totalDays > 0 ? totalVisits / totalDays : 0;

  return {
    averageTimeInMinutes: parseFloat(averageTimeInMinutes.toFixed(1)),
    averageSessions: parseFloat(averageSessions.toFixed(1)),
    totalSessions: totalDays,
  };
};

export const calculateCustomMaxValue = (dateFilter, currentValue) => {
  let multiplier;
  switch (dateFilter) {
    case "lastWeek":
      multiplier = 7;
      break;
    case "lastMonth":
      multiplier = 30;
      break;
    case "lastYear":
      multiplier = 365;
      break;
    default:
      multiplier = 7;
  }
  return currentValue * multiplier;
};
