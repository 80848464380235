import React from 'react';
import './reward-container.scss';

interface RewardItem {
    id: number | string;
    name: string;
    imageSrc: string;
    showName?: boolean;
}

interface RewardContainerProps {
    items: RewardItem[];
    noRewardsText?: string;
}

const RewardContainer: React.FC<RewardContainerProps> = ({ items, noRewardsText }) => {
    return (
        <div className="reward-container">
            {items.length > 0 ? (
                <div className="reward-container__scroll">
                    {items.map((item) => (
                        <div key={item.id} className="reward-item">
                            <img src={item.imageSrc} alt={item.name} />
                            {item.showName !== false && <p>{item.name.replace(/_/g, ' ').charAt(0).toUpperCase() + item.name.replace(/_/g, ' ').slice(1)}</p>}
                        </div>
                    ))}
                </div>
            ) : (
                <p className="no-rewards-text">{noRewardsText || 'no rewards to show.'}</p>
            )}
        </div>
    );
};

export default RewardContainer;
