import React, { useEffect, useState } from "react";
import { RotatingTriangles } from "react-loader-spinner";
import { useTranslation } from "react-i18next";

import { useAnalytics } from "../analytics-context";

import "./history-analytics.scss";
import DetailCard from "../../../components/detailCard/detail-card";
import { getLanguageNumber } from "../../../helper/common-helpers/language-helper";
import HistoryTable from "../../../components/analytics/history/history-table";
import {
  HistoryAnalyticsProps,
  MergedSkillContentData,
} from "../../../interfaces/analytics-interfaces/history-interface";
import { useHistoryData } from "../../../hooks/use-history-data";
import {
  CategoryGameIconDetails,
  CategoryVideoIconDetails,
} from "../../../constants/recommendation-constants";
import { useHistorySkillProgressData } from "../../../hooks/use-history-skill-progress-data";
import SkillProgressBar from "../../../components/skill-progress-bar/skill-progress-bar";
import { SkillLevel2ProgressData } from "../../../interfaces/skill-map-interfaces/skill-map-interface";
import {
  filterAndSortSkillHistoryData,
  transformToSkillFormat,
  updateSkillHistoryThemeColor,
} from "../../../helper/analytics-helpers/history-helper";

const HistoryAnalytics: React.FC<HistoryAnalyticsProps> = (
  props: HistoryAnalyticsProps
) => {
  const { t, i18n } = useTranslation();
  const actualLanguage = i18n.language;
  const languageNumber = getLanguageNumber(actualLanguage);
  const { isLoading, error } = useAnalytics();

  const rowsToInitiallyShow = 10;
  const rowsToAdd = 10;

  const [filteredSkillProgressData, setFilteredSkillProgressData] = useState<
    MergedSkillContentData[]
  >([]);

  const { isProcessingHistoryData, historyData } = useHistoryData({
    childrenData: props.currentChildSelected,
    actualLanguage: languageNumber,
    dateLimit: props.dateFilter,
  });

  const { isProcessingHistorySkillProgressData, historySkillProgressData } =
    useHistorySkillProgressData({
      childrenData: props.currentChildSelected,
      actualLanguage: languageNumber,
    });

  useEffect(() => {
    if (historyData.length > 0 && historySkillProgressData.length > 0) {
      setFilteredSkillProgressData([]);

      const [sortedFilteredData, totalProgress] = filterAndSortSkillHistoryData(
        historyData,
        historySkillProgressData
      );

      const adjustedData = sortedFilteredData.map((skill) => ({
        ...skill,
        skillProgress: (skill.skillProgress / totalProgress) * 100,
      }));

      setFilteredSkillProgressData(adjustedData);
    }
  }, [historyData, historySkillProgressData, props.dateFilter]);

  useEffect(() => {
    setFilteredSkillProgressData([]);
  }, [props.currentChildSelected]);

  const renderProgressBars = (
    progressData: Array<SkillLevel2ProgressData> = []
  ) =>
    progressData.map((data, index) => {
      const uniqueKey = `sk-id-${data.id}-name-${data.name}`;
      const colorToUse = updateSkillHistoryThemeColor(data.parentId);
      return (
        <SkillProgressBar
          key={uniqueKey}
          value={data.progress}
          themeColor={colorToUse}
          skillData={data}
          showEyeIcon={false}
        />
      );
    });

  return (
    <>
      {error ? <p style={{ color: "red" }}>Error: {error.message}</p> : null}

      {isLoading || isProcessingHistoryData ? (
        <div style={{ alignSelf: "center" }}>
          <RotatingTriangles
            visible={true}
            height="80"
            width="80"
            ariaLabel="rotating-triangels-loading"
            wrapperClass="rotating-triangels-wrapper"
            colors={["#018cd5", "#de2781", "#f19700"]}
          />
        </div>
      ) : historyData.length === 0 ? (
        <div style={{ textAlign: "center", margin: "20px 0" }}>
          <p>{t(`analyticsPages.history_page.no_activity_period`)}</p>
        </div>
      ) : (
        <>
          <div className="analytics__stats-content--card">
            <DetailCard
              hasHeader={true}
              headerTitle={t(`analyticsPages.history_page.activities`)}
              iconLeft={[CategoryGameIconDetails, CategoryVideoIconDetails]}
              themeColor="#823d90"
            >
              <HistoryTable
                rowsToInitiallyShow={rowsToInitiallyShow}
                rowsToAdd={rowsToAdd}
                currentChildSelected={props.currentChildSelected}
                historyData={historyData}
              />
            </DetailCard>
          </div>

          <div className="analytics__stats-content--card">
            <DetailCard
              hasHeader={true}
              headerTitle={t(`analyticsPages.history_page.skill_activities`)}
              themeColor="#823d90"
            >
              {!isProcessingHistorySkillProgressData &&
              filteredSkillProgressData.length > 0 ? (
                <div className="detail-card__content--progress-bars">
                  {renderProgressBars(
                    filteredSkillProgressData.map(transformToSkillFormat)
                  )}
                </div>
              ) : null}
            </DetailCard>
          </div>
        </>
      )}
    </>
  );
};

export default HistoryAnalytics;
