import { Link } from "react-router-dom";
import "./footer-public.scss";

function FooterPublic() {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
      <footer className="footer-public">
        <div className="footer-public-container">
          <div className="row">
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>
          <div className="row">
            <div className="col-md-6 copyright-col">
              <p className="copyright">
                Copyright &copy; Wytopia. All rights reserved.
              </p>
            </div>
            <div className="col-md-6 links-col">
              <div className="footer-links">
                <Link className="legal-pages" to="/terms" onClick={scrollToTop}>
                  Terms of Use
                </Link>
                <Link className="legal-pages" to="/privacy" onClick={scrollToTop}>
                  Privacy Policy
                </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
  );
}

export default FooterPublic;
