import React from "react";

import "./advanced-progress-bar.scss";
import { useTranslation } from "react-i18next";

interface AdvancedProgressBarProps {
  completed: number;
  maxValue?: number;
  customMaxValue?: number;
  worldMedian?: number;
  barSize?: "small" | "medium" | "big";
  leftbgcolor?: string;
  rightbgcolor?: string;
  infoTopLeft?: JSX.Element;
  infoTopRight?: JSX.Element;
  infoBottomLeft?: JSX.Element;
  infoBottomRight?: JSX.Element;
  topLeftTextColor?: string;
  topRightTextColor?: string;
  bottomLeftTextColor?: string;
  bottomRightTextColor?: string;
  enableDynamicColor?: boolean;
  styleOverride?: React.CSSProperties;
}

const AdvancedProgressBar: React.FC<AdvancedProgressBarProps> = (
  props: AdvancedProgressBarProps
) => {
  const { t } = useTranslation();

  let bgColor = props.leftbgcolor || "#823d90";

  const maxVal = props.customMaxValue || 100;
  let completed = (props.completed / maxVal) * 100;

  completed = Math.min(completed, 100);

  if (props.enableDynamicColor) {
    if (completed >= 100) {
      bgColor = "red";
    } else if (completed >= 80) {
      bgColor = "orange";
    } else {
      bgColor = "green";
    }
  }

  return (
    <div className="advanced-progress-bar" style={props.styleOverride}>
      <div className="advanced-progress-bar__info-container--top">
        {props.infoTopLeft && (
          <div
            className="advanced-progress-bar__info advanced-progress-bar__info--left"
            style={{ color: props.topLeftTextColor || "#000" }}
          >
            {props.infoTopLeft}
          </div>
        )}

        {props.infoTopRight && (
          <div
            className="advanced-progress-bar__info advanced-progress-bar__info--right"
            style={{ color: props.topRightTextColor || "#000" }}
          >
            {props.infoTopRight}
          </div>
        )}
      </div>
      <div
        className={`advanced-progress-bar__track advanced-progress-bar__track--${props.barSize}`}
        style={{ backgroundColor: props.rightbgcolor || "#e0e0de" }}
      >
        <div
          className="advanced-progress-bar__filler"
          style={{
            width: `${completed}%`,
            backgroundColor: bgColor,
          }}
        ></div>

        {props.worldMedian && (
          <>
            <div
              className="advanced-progress-bar__average-text"
              style={{
                left: `${props.worldMedian}%`,
                fontWeight: "bold",
              }}
            >
              {t("analyticsPages.average")}
            </div>
            <div
              className="advanced-progress-bar__average-line"
              style={{
                left: `${props.worldMedian}%`,
              }}
            ></div>
          </>
        )}
      </div>

      <div className="advanced-progress-bar__info-container--bottom">
        {props.infoBottomLeft && (
          <div
            className="advanced-progress-bar__info advanced-progress-bar__info--left"
            style={{ color: props.bottomLeftTextColor || "#000" }}
          >
            {props.infoBottomLeft}
          </div>
        )}

        {props.infoBottomRight && (
          <div
            className="advanced-progress-bar__info advanced-progress-bar__info--right"
            style={{ color: props.bottomRightTextColor || "#000" }}
          >
            {props.infoBottomRight}
          </div>
        )}
      </div>
    </div>
  );
};

export default AdvancedProgressBar;
