import "./legal-pages.scss";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";

function Privacy() {
    const {t, i18n} = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    useEffect(() => {
        const handleLanguageChange = () => {
            setLanguage(i18n.language);
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    return (
        <div className="content-page--legal">
            <h1>{t('privacy.title')}</h1>
            <h3>{t('privacy.effectiveDate')}</h3>
            <h3>{t('privacy.lastUpdated')}</h3>

            <p>{t('privacy.intro1')}</p>
            <p>{t('privacy.intro2')}</p>

            <h2>{t('privacy.collection.title')}</h2>
            <p>{t('privacy.collection.text')}</p>

            <ul>
                <li>
                    <strong>{t('privacy.collection.registration.title')}</strong> {t('privacy.collection.registration.text')}
                </li>
                <li><strong>{t('privacy.collection.children.title')}</strong> {t('privacy.collection.children.text')}
                </li>
                <li><strong>{t('privacy.collection.device.title')}</strong> {t('privacy.collection.device.text')}</li>
            </ul>

            <h2>{t('privacy.use.title')}</h2>
            <p>{t('privacy.use.text')}</p>

            <ul>
                <li>{t('privacy.use.registration')}</li>
                <li>{t('privacy.use.device')}</li>
            </ul>

            <h2>{t('privacy.otherData.title')}</h2>
            <p>{t('privacy.otherData.text')}</p>

            <ul>
                <li>
                    <strong>{t('privacy.otherData.gameProgress.title')}</strong> {t('privacy.otherData.gameProgress.text')}
                </li>
                <li><strong>{t('privacy.otherData.accountID.title')}</strong> {t('privacy.otherData.accountID.text')}
                </li>
                <li>
                    <strong>{t('privacy.otherData.gameplayStats.title')}</strong> {t('privacy.otherData.gameplayStats.text')}
                </li>
            </ul>

            <p>{t('privacy.otherData.conclusion')}</p>

            <h2>{t('privacy.sdk.title')}</h2>
            <p>{t('privacy.sdk.text1')}</p>
            <p>{t('privacy.sdk.text2')}</p>

            <h2>{t('privacy.security.title')}</h2>
            <p>{t('privacy.security.intro')}</p>

            <ul>
                <li><strong>{t('privacy.security.encryption.title')}</strong> {t('privacy.security.encryption.text')}
                </li>
                <li>
                    <strong>{t('privacy.security.accessControl.title')}</strong> {t('privacy.security.accessControl.text')}
                </li>
                <li><strong>{t('privacy.security.monitoring.title')}</strong> {t('privacy.security.monitoring.text')}
                </li>
                <li>
                    <strong>{t('privacy.security.incidentResponse.title')}</strong> {t('privacy.security.incidentResponse.text')}
                </li>
            </ul>

            <p>{t('privacy.security.conclusion')}</p>

            <h2>{t('privacy.sharing.title')}</h2>
            <p>{t('privacy.sharing.text1')}</p>
            <p>{t('privacy.sharing.text2')}</p>

            <h2>{t('privacy.updates.title')}</h2>
            <p>{t('privacy.updates.text1')}</p>
            <p>{t('privacy.updates.text2')}</p>

            <h2>{t('privacy.rights.title')}</h2>
            <p>{t('privacy.rights.text1')}</p>
            <p>{t('privacy.rights.text2')} <a href="mailto:contact@wytopia.org">{t('privacy.rights.email')}</a>.</p>
            <p>{t('privacy.rights.text3')}</p>
            <p>{t('privacy.rights.text4')}</p>
            <p>{t('privacy.conclusion')}</p>
        </div>
    );
}

export default Privacy;
