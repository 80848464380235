import React, { useEffect, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import "./screen-time-history.scss";

import { DailyDetail } from "../../../interfaces/analytics-interfaces/screen-time-interface";
import AdvancedProgressBar from "../../progress-bar/advanced-progress-bar";
import { CircleBlackIconDetails } from "../../../constants/analytics-constants";

interface ScreenTimeHistoryProps {
  dailyDetails: { [date: string]: DailyDetail };
  maxSessionTime: number;
  rowsToShow: number;
  extraBottomComponent: React.ReactNode;
}

const ScreenTimeHistory: React.FC<ScreenTimeHistoryProps> = (
  props: ScreenTimeHistoryProps
) => {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const [limitedRenderedComponents, setLimitedRenderedComponents] = useState(
    []
  );

  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (!props.dailyDetails) {
      setErrorMessage(t("analyticsPages.screen_time_page.no_data_available"));
      return;
    }

    const newRenderedComponents = [];
    let dateBeginNoActivity = null;
    let dateEndNoActivity = null;

    if (props?.dailyDetails) {
      const sortedDates = Object.keys(props.dailyDetails).sort((a, b) => {
        return moment(b).diff(moment(a));
      });
      setTotalRows(sortedDates.length);

      if (sortedDates && sortedDates.length > 0) {
        sortedDates.forEach((date, index) => {
          const detail = props.dailyDetails[date];
          const { sessionTimeInMinutes, sessions } = detail;

          const dayName = t(
            `days.${moment(date).format("dddd").toLowerCase()}`
          ).substring(0, 3);
          const monthName = t(
            `months.${moment(date).format("MMMM").toLowerCase()}`
          ).substring(0, 3);
          const readableDate = `${dayName} ${moment(date).format(
            "D"
          )} ${monthName} ${moment(date).format("YYYY")}`;

          if (sessionTimeInMinutes === 0 && sessions === 0) {
            if (!dateBeginNoActivity) {
              dateBeginNoActivity = readableDate;
            }
            dateEndNoActivity = readableDate;
          } else {
            if (dateBeginNoActivity) {
              const isSingleDay = dateBeginNoActivity === dateEndNoActivity;
              const noActivityIntro = isSingleDay
                ? t("analyticsPages.screen_time_page.no_activity_the")
                : t("analyticsPages.screen_time_page.no_activity_between");
              const noActivityDate = isSingleDay
                ? `${dateBeginNoActivity}`
                : `${dateBeginNoActivity} - ${dateEndNoActivity}`;

              newRenderedComponents.push(
                <div
                  className="screen-time-history__no-activity"
                  key={`no-activity-wrapper-${index}`}
                >
                  <div>
                    <img
                      src={CircleBlackIconDetails.src}
                      alt={CircleBlackIconDetails.alt}
                    />
                  </div>
                  <div>
                    <p className="screen-time-history__no-activity--text-intro">
                      {noActivityIntro}
                    </p>
                    <p className="screen-time-history__no-activity--text-date">
                      {noActivityDate}
                    </p>
                  </div>
                </div>
              );
              dateBeginNoActivity = null;
              dateEndNoActivity = null;
            }

            newRenderedComponents.push(
              <AdvancedProgressBar
                key={`activity-${index}`}
                completed={Math.round(
                  ((sessionTimeInMinutes || 0) / props.maxSessionTime) * 100
                )}
                barSize="small"
                infoTopLeft={<p>{readableDate}</p>}
                infoBottomLeft={
                  <p>
                    {t("analyticsPages.screen_time_page.number_of_visits")} ={" "}
                    {sessions}
                  </p>
                }
                infoBottomRight={
                  <p>
                    {t("analyticsPages.screen_time_page.duration")} ={" "}
                    {sessionTimeInMinutes}{" "}
                    {t("analyticsPages.screen_time_page.minutes_short")} (
                    {Math.round(
                      ((sessionTimeInMinutes || 0) / props.maxSessionTime) * 100
                    )}
                    %)
                  </p>
                }
                enableDynamicColor={true}
              />
            );
          }
        });
      }
      if (dateBeginNoActivity) {
        const isSingleDay = dateBeginNoActivity === dateEndNoActivity;
        const noActivityIntro = isSingleDay
          ? t("analyticsPages.screen_time_page.no_activity_the")
          : t("analyticsPages.screen_time_page.no_activity_between");
        const noActivityDate = isSingleDay
          ? `${dateBeginNoActivity}`
          : `${dateBeginNoActivity} - ${dateEndNoActivity}`;

        newRenderedComponents.push(
          <div
            className="screen-time-history__no-activity"
            key={`no-activity-final`}
          >
            <div>
              <img
                src={CircleBlackIconDetails.src}
                alt={CircleBlackIconDetails.alt}
              />
            </div>
            <div>
              <p className="screen-time-history__no-activity--text-intro">
                {noActivityIntro}
              </p>
              <p className="screen-time-history__no-activity--text-date">
                {noActivityDate}
              </p>
            </div>
          </div>
        );
      }
    }

    setLimitedRenderedComponents(
      newRenderedComponents.slice(0, props.rowsToShow)
    );
  }, [props.rowsToShow]);

  return (
    <div>
      {errorMessage ? (
        <div className="error-message">{errorMessage}</div>
      ) : null}

      {limitedRenderedComponents}
      {/* {props.rowsToShow < totalRows ? (
        <div>{props.extraBottomComponent}</div>
      ) : null} */}
    </div>
  );
};

export default ScreenTimeHistory;
