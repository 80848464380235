// React and Third-Party Libraries
import React, {createContext, useContext, useState, useEffect} from "react";

// API Helpers
import {
    fetchChildGameProgressesApi,
    fetchChildContentProgressesApi,
    ChildProgressesRequestBody,
} from "../../helper/apiHelper/child-progresses-api";

import {
    fetchChildSkillHistoryApi,
    ChildSkillHistoryRequestBody,
} from "../../helper/apiHelper/child-skill-history-api";

import {
    fetchSkillsApi,
    fetchSkillsStatisticsApi,
    fetchContentSkillsApi,
    SkillsRequestBody,
} from "../../helper/apiHelper/skill-api";

import {
    fetchContentsApi,
    fetchContentsFeedbackApi,
    fetchContentsStatisticsApi,
    ContentsRequestBody,
} from "../../helper/apiHelper/content-api";

import {
    fetchReferentAccountDetailsApi,
    ReferentAccountDetailsRequestBody,
} from "../../helper/apiHelper/parent-api";

import {
    fetchChildActivityTimeApi,
    fetchChildFeedbacksApi,
    fetchChildQuizAnswersApi,
    fetchChildSessionTimeApi,
    fetchAllChildSessionTimeApi,
    fetchGameSettingsApi,
    ChildIdRequestBody,
} from "../../helper/apiHelper/child-api";

import {
    fetchAvatarsApi,
    fetchStickersApi,
    fetchItemsApi,
    fetchBadgesApi,
    fetchFriendsApi,
    fetchChildAvatarRewardsApi,
    fetchChildBadgeRewardsApi,
    fetchChildItemRewardsApi,
    fetchChildStickerRewardsApi,
    fetchChildFriendsApi,
    fetchChildTotalGoldsApi,
    fetchChildGoldBalancesApi,
    RewardsRequestBody,
} from "../../helper/apiHelper/rewards-api";

// Interfaces
import {
    AnalyticsContextType,
    AnalyticsProviderProps,
    ContentModel,
    ContentSkillModel,
    ContentStatisticModel,
    ContentsFeedbackModel,
    GameSettingsModel,
    SkillModel,
    SkillsStatisticModel,
} from "../../interfaces/analytics-interfaces/analytics-interface";
import {
    ChildActivityTimeModel,
    ChildContentProgressModel,
    ChildFeedbacksModel,
    ChildGameProgressModel,
    ChildQuizAnswersModel,
    ChildSessionTimeModel,
    ChildSkillHistoryModel,
} from "../../interfaces/child-interfaces/child-interface";
import {
    ReferentAccountModel,
} from "../../interfaces/referent-interfaces/parents-interface";
import {
    AvatarRewardModel,
    BadgeRewardModel,
    ItemRewardModel,
    StickerRewardModel,
    FriendRewardModel,
    ChildAvatarRewardModel,
    ChildBadgeRewardModel,
    ChildItemRewardModel,
    ChildStickerRewardModel,
    ChildFriendRewardModel,
    ChildTotalGoldModel,
    ChildGoldBalanceModel,
} from "../../interfaces/rewards-interfaces/rewards-interface";
import {AuthContext} from "../auth/auth-context";

const AnalyticsContext = createContext<AnalyticsContextType | null>(null);

export const useAnalytics = () => {
    const context = useContext(AnalyticsContext);
    if (!context) {
        throw new Error("useAnalytics must be used within an AnalyticsProvider");
    }
    return context;
};

export const AnalyticsProvider: React.FC<AnalyticsProviderProps> = ({
                                                                        children,
                                                                    }) => {
    // ========================
    // State Variables
    // ========================

    // Loading and Error States
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Error | null>(null);
    
    // Family Account Details
    const { referentData, updateReferentData } = useContext(AuthContext);
    const [referentAccountData, setReferentAccountData] = useState<ReferentAccountModel | null>(null);

    // Child Progress Data
    const [childGameProgressesData, setChildGameProgressesData] =
        useState<ChildGameProgressModel | null>(null);
    const [childContentProgressesData, setChildContentProgressesData] =
        useState<ChildContentProgressModel | null>(null);
    const [childSkillHistoryData, setChildSkillHistoryData] =
        useState<ChildSkillHistoryModel | null>(null);

    // Skills Data
    const [skillsLevel1Data, setSkillsLevel1Data] =
        useState<SkillModel | null>(null);
    const [skillsLevel2Data, setSkillsLevel2Data] =
        useState<SkillModel | null>(null);
    const [skillsLevel3Data, setSkillsLevel3Data] =
        useState<SkillModel | null>(null);
    const [skillsStatisticsData, setSkillsStatisticsData] =
        useState<SkillsStatisticModel | null>(null);

    // Content Data
    const [contentSkillsData, setContentSkillsData] =
        useState<ContentSkillModel | null>(null);
    const [contentsData, setContentsData] = useState<ContentModel | null>(null);
    const [contentsFeedbackData, setContentsFeedbackData] =
        useState<ContentsFeedbackModel | null>(null);
    const [contentStatisticsData, setContentStatisticsData] =
        useState<ContentStatisticModel | null>(null);

    // Child Activity Data
    const [childActivityTimeData, setChildActivityTimeData] =
        useState<ChildActivityTimeModel | null>(null);
    const [childQuizAnswersData, setChildQuizAnswersData] =
        useState<ChildQuizAnswersModel | null>(null);
    const [childFeedbacksData, setChildFeedbacksData] =
        useState<ChildFeedbacksModel | null>(null);
    const [childSessionTimeData, setChildSessionTimeData] =
        useState<ChildSessionTimeModel | null>(null);
    const [allChildSessionTimeData, setAllChildSessionTimeData] =
        useState<ChildSessionTimeModel | null>(null);

    // Game Settings Data
    const [gameSettingsData, setGameSettingsData] =
        useState<GameSettingsModel | null>(null);

    // Rewards Data
    const [childAvatarRewardsData, setChildAvatarRewardsData] =
        useState<ChildAvatarRewardModel | null>(null);
    const [childBadgeRewardsData, setChildBadgeRewardsData] =
        useState<ChildBadgeRewardModel | null>(null);
    const [childItemRewardsData, setChildItemRewardsData] =
        useState<ChildItemRewardModel | null>(null);
    const [childStickerRewardsData, setChildStickerRewardsData] =
        useState<ChildStickerRewardModel | null>(null);
    const [childFriendsRewardsData, setChildFriendsRewardsData] =
        useState<ChildFriendRewardModel | null>(null);

    const [avatarsData, setAvatarsData] = useState<AvatarRewardModel | null>(null);
    const [badgesData, setBadgesData] = useState<BadgeRewardModel | null>(null);
    const [itemsData, setItemsData] = useState<ItemRewardModel | null>(null);
    const [stickersData, setStickersData] = useState<StickerRewardModel | null>(null);
    const [friendsData, setFriendsData] = useState<FriendRewardModel | null>(null);

    // Gold Data
    const [childTotalGoldsData, setChildTotalGoldsData] = useState<ChildTotalGoldModel | null>(null);
    const [childGoldBalancesData, setChildGoldBalancesData] = useState<ChildGoldBalanceModel | null>(null);

    // ========================
    // Effect Hooks
    // ========================

    useEffect(() => {
        if (referentData) {
            setReferentAccountData({
                status: 200,
                statusMessage: "OK",
                object: referentData,
            });
        }
    }, [referentData]);

    // ========================
    // Fetch Functions
    // ========================

    // Family/Referent Account Fetch Function
    const fetchReferentAccountData = async (referentId: number, token: string): Promise<ReferentAccountModel | null> => {
        setIsLoading(true);
        try {
            const requestBody = { referentId, token };
            const result = await fetchReferentAccountDetailsApi(requestBody);

            setReferentAccountData(result as ReferentAccountModel);
            updateReferentData(result.object);
            setError(null);
            return result as ReferentAccountModel;
        } catch (error) {
            setError(error as Error);
            return null;
        } finally {
            setIsLoading(false);
        }
    };
    
    // Skills Fetch Functions
    const fetchSkillsLevel1 = async (languageId: number) => {
        setIsLoading(true);
        try {
            const requestBody: SkillsRequestBody = {
                Level: 1,
                LanguageId: languageId,
            };
            const result = await fetchSkillsApi(requestBody);
            setSkillsLevel1Data(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSkillsLevel2 = async (languageId: number) => {
        setIsLoading(true);
        try {
            const requestBody: SkillsRequestBody = {
                Level: 2,
                LanguageId: languageId,
            };
            const result = await fetchSkillsApi(requestBody);
            setSkillsLevel2Data(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSkillsLevel3 = async (languageId: number) => {
        setIsLoading(true);
        try {
            const requestBody: SkillsRequestBody = {
                Level: 3,
                LanguageId: languageId,
            };
            const result = await fetchSkillsApi(requestBody);
            setSkillsLevel3Data(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchSkillsStatistics = async () => {
        setIsLoading(true);
        try {
            const result = await fetchSkillsStatisticsApi();
            setSkillsStatisticsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    // Content Fetch Functions
    const fetchContentSkills = async () => {
        setIsLoading(true);
        try {
            const result = await fetchContentSkillsApi();
            setContentSkillsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchContents = async (languageId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ContentsRequestBody = {
                SourceLanguageId: languageId,
            };
            const result = await fetchContentsApi(requestBody);
            setContentsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchContentsFeedback = async () => {
        setIsLoading(true);
        try {
            const result = await fetchContentsFeedbackApi();
            setContentsFeedbackData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchContentStatistics = async () => {
        setIsLoading(true);
        try {
            const result = await fetchContentsStatisticsApi();
            setContentStatisticsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    // Child Progress Fetch Functions
    const fetchChildGameProgresses = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildProgressesRequestBody = {
                ChildId: childId,
            };
            const result = await fetchChildGameProgressesApi(requestBody);
            setChildGameProgressesData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChildContentProgresses = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildProgressesRequestBody = {
                ChildId: childId,
            };
            const result = await fetchChildContentProgressesApi(requestBody);
            setChildContentProgressesData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChildSkillHistory = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildSkillHistoryRequestBody = {
                ChildId: childId,
            };
            const result = await fetchChildSkillHistoryApi(requestBody);
            setChildSkillHistoryData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    // Child Activity Fetch Functions
    const fetchChildActivityTime = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildIdRequestBody = {
                ChildId: childId,
            };
            const result = await fetchChildActivityTimeApi(requestBody);
            setChildActivityTimeData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChildQuizAnswers = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildIdRequestBody = {
                ChildId: childId,
            };
            const result = await fetchChildQuizAnswersApi(requestBody);
            setChildQuizAnswersData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChildFeedbacks = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildIdRequestBody = {
                ChildId: childId,
            };
            const result = await fetchChildFeedbacksApi(requestBody);
            setChildFeedbacksData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChildSessionTime = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildIdRequestBody = {
                ChildId: childId,
            };
            const result = await fetchChildSessionTimeApi(requestBody);
            setChildSessionTimeData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchAllChildSessionTime = async () => {
        setIsLoading(true);
        try {
            const result = await fetchAllChildSessionTimeApi();
            setAllChildSessionTimeData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    // Game Settings Fetch Function
    const fetchGameSettings = async () => {
        setIsLoading(true);
        try {
            const result = await fetchGameSettingsApi();
            setGameSettingsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    // Rewards Fetch Functions

    // Child Rewards
    const fetchChildAvatarRewards = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildIdRequestBody = {
                ChildId: childId,
            };
            const result = await fetchChildAvatarRewardsApi(requestBody);
            setChildAvatarRewardsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChildBadgeRewards = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildIdRequestBody = {
                ChildId: childId,
            };
            const result = await fetchChildBadgeRewardsApi(requestBody);
            setChildBadgeRewardsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChildItemRewards = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildIdRequestBody = {
                ChildId: childId,
            };
            const result = await fetchChildItemRewardsApi(requestBody);
            setChildItemRewardsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChildStickerRewards = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildIdRequestBody = {
                ChildId: childId,
            };
            const result = await fetchChildStickerRewardsApi(requestBody);
            setChildStickerRewardsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChildFriendsRewards = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildIdRequestBody = {
                ChildId: childId,
            };
            const result = await fetchChildFriendsApi(requestBody);
            setChildFriendsRewardsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    // General Rewards
    const fetchAvatars = async () => {
        setIsLoading(true);
        try {
            const result = await fetchAvatarsApi();
            setAvatarsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchStickers = async (languageId: number) => {
        setIsLoading(true);
        try {        
            const requestBody: RewardsRequestBody = {
                LanguageId: languageId,
            };
            const result = await fetchStickersApi(requestBody);
            setStickersData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchItems = async (languageId: number) => {
        setIsLoading(true);
        try {
            const requestBody: RewardsRequestBody = {
                LanguageId: languageId,
            };
            const result = await fetchItemsApi(requestBody);
            setItemsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchBadges = async (languageId: number) => {
        setIsLoading(true);
        try {
            const requestBody: RewardsRequestBody = {
                LanguageId: languageId,
            };
            const result = await fetchBadgesApi(requestBody);
            setBadgesData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchFriends = async (languageId: number) => {
        setIsLoading(true);
        try {
            const requestBody: RewardsRequestBody = {
                LanguageId: languageId,
            };
            const result = await fetchFriendsApi(requestBody);
            setFriendsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChildTotalGolds = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildIdRequestBody = { ChildId: childId };
            const result = await fetchChildTotalGoldsApi(requestBody);
            setChildTotalGoldsData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };

    const fetchChildGoldBalances = async (childId: number) => {
        setIsLoading(true);
        try {
            const requestBody: ChildIdRequestBody = { ChildId: childId };
            const result = await fetchChildGoldBalancesApi(requestBody);
            setChildGoldBalancesData(result);
            setError(null);
        } catch (error) {
            setError(error as Error);
        } finally {
            setIsLoading(false);
        }
    };


    // ========================
    // Return Context Provider
    // ========================

    return (
        <AnalyticsContext.Provider
            value={{
                // Account Data
                // analyticsAccountData,
                isLoading,
                error,

                // Parent Data
                referentAccountData,

                // Child Data
                childGameProgressesData,
                childContentProgressesData,
                childSkillHistoryData,
                childActivityTimeData,
                childQuizAnswersData,
                childFeedbacksData,
                childSessionTimeData,
                allChildSessionTimeData,

                // Skills Data
                skillsLevel1Data,
                skillsLevel2Data,
                skillsLevel3Data,
                skillsStatisticsData,

                // Content Data
                contentSkillsData,
                contentsData,
                contentsFeedbackData,
                contentStatisticsData,
                gameSettingsData,

                // Rewards Data
                childAvatarRewardsData,
                childBadgeRewardsData,
                childItemRewardsData,
                childStickerRewardsData,
                childFriendsRewardsData,
                avatarsData,
                stickersData,
                itemsData,
                badgesData,
                friendsData,

                // Gold Data
                childTotalGoldsData,
                childGoldBalancesData,

                // Fetch Functions

                // Family Account Fetch Function
                fetchReferentAccountData,

                // Child Fetch Functions
                fetchChildGameProgresses,
                fetchChildContentProgresses,
                fetchChildSkillHistory,
                fetchChildActivityTime,
                fetchChildQuizAnswers,
                fetchChildFeedbacks,
                fetchChildSessionTime,
                fetchAllChildSessionTime,

                // Skills Fetch Functions
                fetchSkillsLevel1,
                fetchSkillsLevel2,
                fetchSkillsLevel3,
                fetchSkillsStatistics,

                // Content Fetch Functions
                fetchContentSkills,
                fetchContents,
                fetchContentsFeedback,
                fetchContentStatistics,
                fetchGameSettings,

                // Rewards Fetch Functions
                fetchChildAvatarRewards,
                fetchChildBadgeRewards,
                fetchChildItemRewards,
                fetchChildStickerRewards,
                fetchChildFriendsRewards,
                fetchAvatars,
                fetchStickers,
                fetchItems,
                fetchBadges,
                fetchFriends,

                // Gold Fetch Functions
                fetchChildTotalGolds,
                fetchChildGoldBalances,
            }}
        >
            {children}
        </AnalyticsContext.Provider>
    );
};
