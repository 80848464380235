import ScreenTimeIconWhite from "../resources/images/icons/tps-ecran_blanc.png";
import HistoryIconWhite from "../resources/images/icons/historic_blanc.png";
import ActivitiesIconWhite from "../resources/images/icons/jeux-blanc.png";
import SkillMapIconWhite from "../resources/images/icons/spider_blanc.png";
import ProgressIconWhite from "../resources/images/icons/progres_blanc.png";
import RecommendationsIconWhite from "../resources/images/icons/reco_blanc.png";
import RewardsIconWhite from "../resources/images/icons/medaille_blanc.png";
import SearchSkillIconWhite from "../resources/images/icons/search_skill_blanc.png";
import SettingsIconWhite from "../resources/images/icons/config_blanc.png";

import ScreenTimeIconViolet from "../resources/images/icons/tps-ecran_violet.png";
import HistoryIconViolet from "../resources/images/icons/historic_violet.png";
import ActivitiesIconViolet from "../resources/images/icons/jeux_violet.png";
import SkillMapIconViolet from "../resources/images/icons/spider_violet.png";
import ProgressIconViolet from "../resources/images/icons/progres_violet.png";
import RecommendationsIconViolet from "../resources/images/icons/reco_violet.png";
import RewardsIconViolet from "../resources/images/icons/medaille_violet.png";
import SearchSkillIconViolet from "../resources/images/icons/search_skill_violet.png";
import SettingsIconViolet from "../resources/images/icons/config_violet.png";
import SearchIconViolet from "../resources/images/icons/loupe_violet.png";

import CircleWhiteIcon from "../resources/images/icons/rond_blanc.png";
import CircleBlackIcon from "../resources/images/icons/rond.png";

import { MenuItem } from "../interfaces/analytics-interfaces/analytics-interface";

import { IconProps } from "../components/detailCard/detail-card";
import DropdownIcon from "../resources/images/icons/dropdown.png";

export const DropdownIconDetails: IconProps = {
  src: DropdownIcon,
  alt: "Dropdown Icon",
};

export const SearchIconVioletDetails: IconProps = {
  src: SearchIconViolet,
  alt: "Search Icon",
};

export const ScreenTimeIconVioletDetails: IconProps = {
  src: ScreenTimeIconViolet,
  alt: "Screentime Icon",
};

export const ActivitiesIconVioletDetails: IconProps = {
  src: ActivitiesIconViolet,
  alt: "Activities Icon",
};

export const SkillMapIconVioletDetails: IconProps = {
  src: SkillMapIconViolet,
  alt: "SkillMap Icon",
};

export const ProgressIconVioletDetails: IconProps = {
  src: ProgressIconViolet,
  alt: "Progress Icon",
};

export const RecommendationsIconVioletDetails: IconProps = {
  src: RecommendationsIconViolet,
  alt: "Recommendations Icon",
};

export const HistoryIconVioletDetails: IconProps = {
  src: HistoryIconViolet,
  alt: "History Icon",
};

export const CircleWhiteIconDetails: IconProps = {
  src: CircleWhiteIcon,
  alt: "Circle Icon",
};

export const CircleBlackIconDetails: IconProps = {
  src: CircleBlackIcon,
  alt: "Circle Icon",
};

export const menuItems: MenuItem[] = [
  {
    label: "screen_time",
    route: "/analytics/screen-time",
    iconWhite: ScreenTimeIconWhite,
    iconViolet: ScreenTimeIconViolet,
  },
  {
    label: "history",
    route: "/analytics/history",
    iconWhite: HistoryIconWhite,
    iconViolet: HistoryIconViolet,
  },
  {
    label: "activities",
    route: "/analytics/activities",
    iconWhite: ActivitiesIconWhite,
    iconViolet: ActivitiesIconViolet,
  },
  {
    label: "skill_map",
    route: "/analytics/skill-map",
    iconWhite: SkillMapIconWhite,
    iconViolet: SkillMapIconViolet,
  },
  {
    label: "progress",
    route: "/analytics/progress",
    iconWhite: ProgressIconWhite,
    iconViolet: ProgressIconViolet,
  },
  {
    label: "recommendations",
    route: "/analytics/recommendations",
    iconWhite: RecommendationsIconWhite,
    iconViolet: RecommendationsIconViolet,
  },
  {
    label: "rewards",
    route: "/analytics/rewards",
    iconWhite: RewardsIconWhite,
    iconViolet: RewardsIconViolet,
  },
  {
    label: "search",
    route: "/analytics/search",
    iconWhite: SearchSkillIconWhite,
    iconViolet: SearchSkillIconViolet,
  },
  {
    label: "settings",
    route: "/analytics/settings",
    iconWhite: SettingsIconWhite,
    iconViolet: SettingsIconViolet,
  },
];
