import { useEffect, useRef, useState } from "react";
import {
  CategoryScale,
  Chart,
  RadarController,
  RadialLinearScale,
  Title,
} from "chart.js";
import { useTranslation } from "react-i18next";

import DetailCard, { IconProps } from "../../detailCard/detail-card";
import SkillMapTabs from "../skill-map/skill-map-tabs";
import {
  ButtonAllGrey,
  ButtonAll,
  ButtonGrey,
  ButtonBlue,
  ButtonRose,
  ButtonYellow,
  DefaultIcons,
  IconMap,
} from "../../../constants/skill-map-constants";
import {
  FilterType,
  Skill,
} from "../../../interfaces/skill-map-interfaces/skill-map-interface";
import SkillProgressBar from "../../skill-progress-bar/skill-progress-bar";
import {
  generateRadarChartConfig,
  createOrUpdateChart,
} from "../../../helper/chart-helper/chart-helper";
import { updateThemeColor } from "../../../helper/analytics-helpers/skill-map-helpers/theme-helper";
import { getBackArrowIcon } from "../../../helper/analytics-helpers/skill-map-helpers/skill-map-helper";

interface SkillMapCardProps {
  filteredData: Skill[];
  selectedParentIdByLevel: Record<number, number>;
  updateSelectedParentIdByLevel: (level: number, parentId: number) => void;
  filterType: FilterType;
  displayLevel: number;
  parentSkill: Skill;
}

export const SkillMapCard = (props: SkillMapCardProps) => {
  const { t, i18n } = useTranslation();

  const chartRef = useRef(null);
  const chartInstance = useRef<Chart | null>(null);

  const [skillThemeColor, setSkillThemeColor] = useState("#823d90");
  const [selectedStarIcons, setSelectedStarIcons] = useState<IconProps[]>([]);
  const [headerTitle, setHeaderTitle] = useState<string>("");
  const [skill1Title1, setSkill1Title1] = useState<string>(
    t("analyticsPages.skill_map_page.skill_1_level_2_title")
  );
  const [skill1Title2, setSkill1Title2] = useState<string>(
    t("analyticsPages.skill_map_page.skill_2_level_2_title")
  );
  const [skill1Title3, setSkill1Title3] = useState<string>(
    t("analyticsPages.skill_map_page.skill_3_level_2_title")
  );

  const [displayFilteredData, setDisplayFilteredData] = useState<Skill[]>([]);

  const processDataSkill = (data: Skill[]) => {
    return {
      data: data.map((item) => parseFloat(item.progress.toFixed(1))),
      labels: data.map((item) => item.name),
    };
  };

  useEffect(() => {
    const { data, labels } = processDataSkill(displayFilteredData);
    const maxData = Math.max(...data);
    const suggestedMax = maxData * 1.1;

    const truncatedLabels = labels.map((label) =>
      label.length > 14 ? `${label.slice(0, 14)}...` : label
    );

    const config = generateRadarChartConfig(
      data,
      truncatedLabels,
      skillThemeColor,
      suggestedMax
    );

    createOrUpdateChart(chartRef, chartInstance, config);
  }, [displayFilteredData, skillThemeColor]);

  useEffect(() => {
    setDisplayFilteredData(
      props.filteredData.filter((d) => d.level === props.displayLevel)
    );
  }, [props.filteredData]);

  useEffect(() => {
    const newHeaderTitle =
      props.parentSkill?.name.toUpperCase() ??
      t("analyticsPages.skill_map_page.skill_level_1_title");
    setHeaderTitle(newHeaderTitle);
  }, [props.parentSkill]);

  useEffect(() => {
    const newSelectedIcons: IconProps[] =
      IconMap[props.filterType] || DefaultIcons;
    setSelectedStarIcons(newSelectedIcons);

    const newThemeColor = updateThemeColor(props.filterType);
    setSkillThemeColor(newThemeColor);
  }, [props.filterType]);

  const renderProgressBars = (progressData: Array<Skill>) =>
    progressData.map((data, index) => {
      let color = skillThemeColor; // default color
      const colorArray = ["#59c0d1", "#e8318b", "#e6b10d"]; // blue, rose, yellow

      // if we're at level 1 altern colors
      if (
        Object.keys(props.selectedParentIdByLevel).length === 0 &&
        props.displayLevel === 1
      ) {
        color = colorArray[index % colorArray.length];
      }

      return (
        <SkillProgressBar
          key={`sk-l-${data.level}-id-${data.id}`}
          value={data.progress}
          skillData={data}
          themeColor={color}
          onClick={() => {
            props.updateSelectedParentIdByLevel(data.level, data.id);
          }}
        />
      );
    });

  const backArrowIcon = getBackArrowIcon(props.selectedParentIdByLevel);

  const handleBackClick = () => {
    const { selectedParentIdByLevel, updateSelectedParentIdByLevel } = props;
    const level2 = selectedParentIdByLevel[1];
    const level3 = selectedParentIdByLevel[2];

    if (level3 !== undefined) {
      updateSelectedParentIdByLevel(1, level2);
    } else {
      updateSelectedParentIdByLevel(null, null);
    }
  };

  return (
    <>
      <div className="analytics__stats-content--card">
        <DetailCard
          hasHeader
          headerTitle={headerTitle}
          hasSubtitle={false}
          // iconLeft={selectedStarIcons} // NOT ANYMORE
          themeColor={skillThemeColor}
          iconRight={
            props.displayLevel === 2 || props.displayLevel === 3
              ? [{ src: backArrowIcon, alt: "Back", onClick: handleBackClick }]
              : null
          }
          extraTopComponent={
            <SkillMapTabs
              buttons={[
                {
                  label: "allSkill1",
                  level: null,
                  parentId: null,
                  imageSrc: ButtonAllGrey,
                  activeImageSrc: ButtonAll,
                  size: "small",
                },
                {
                  label: skill1Title1,
                  level: 1,
                  parentId: 1,
                  imageSrc: ButtonGrey,
                  activeImageSrc: ButtonBlue,
                  size: "medium",
                },
                {
                  label: skill1Title2,
                  level: 1,
                  parentId: 2,
                  imageSrc: ButtonGrey,
                  activeImageSrc: ButtonRose,
                  size: "medium",
                },
                {
                  label: skill1Title3,
                  level: 1,
                  parentId: 3,
                  imageSrc: ButtonGrey,
                  activeImageSrc: ButtonYellow,
                  size: "medium",
                },
              ]}
              updateSelectedParentIdByLevel={
                props.updateSelectedParentIdByLevel
              }
              selectedParentIdByLevel={props.selectedParentIdByLevel}
            />
          }
        >
          <div className="detail-card__content--graph">
            <canvas ref={chartRef} width={400} height={400} />
          </div>

          <div className="detail-card__content--progress-bars">
            {renderProgressBars(displayFilteredData)}
          </div>
        </DetailCard>
      </div>
    </>
  );
};

export default SkillMapCard;
