import { ContentsWithSkills } from "../../../interfaces/recommendations-interfaces/recommendations-interface";
import {
  SkillLevel1ProgressData,
  SkillLevel2ProgressData,
  SkillLevel3ProgressData,
} from "../../../interfaces/skill-map-interfaces/skill-map-interface";

const createLookupTable = (skillsData) => {
  return skillsData?.reduce((acc, skill) => {
    acc[skill.id] = skill;
    return acc;
  }, {});
};

export const filterContentsBySkillsAndName = (
  searchTerm: string,
  contentsWithSkills: ContentsWithSkills[],
  skillLevel1ProgressData: SkillLevel1ProgressData[],
  skillLevel2ProgressData: SkillLevel2ProgressData[],
  skillLevel3ProgressData: SkillLevel3ProgressData[]
): ContentsWithSkills[] => {
  const searchTermLower = searchTerm.toLowerCase();
  const lookup1 = createLookupTable(skillLevel1ProgressData);
  const lookup2 = createLookupTable(skillLevel2ProgressData);
  const lookup3 = createLookupTable(skillLevel3ProgressData);

  return contentsWithSkills.filter((content) => {
    if (content.name.toLowerCase().includes(searchTermLower)) {
      return true;
    }

    return content.skills.some((skill) => {
      const skill1 = lookup1[skill.skillLevel1Id];
      const skill2 = lookup2[skill.skillLevel2Id];
      const skill3 = lookup3[skill.skillLevel3Id];
      return (
        skill1?.name.toLowerCase().includes(searchTermLower) ||
        skill2?.name.toLowerCase().includes(searchTermLower) ||
        skill3?.name.toLowerCase().includes(searchTermLower)
      );
    });
  });
};

export const extractUniqueSkillsFromContents = (
  contentsWithSkills: ContentsWithSkills[],
  skillLevel1ProgressData: SkillLevel1ProgressData[],
  skillLevel2ProgressData: SkillLevel2ProgressData[],
  skillLevel3ProgressData: SkillLevel3ProgressData[],
  searchTerm: string
) => {
  const lookup1 = createLookupTable(skillLevel1ProgressData);
  const lookup2 = createLookupTable(skillLevel2ProgressData);
  const lookup3 = createLookupTable(skillLevel3ProgressData);
  const uniqueSkillIds = new Set();

  const shouldConsiderSk1AndSk3 = searchTerm.length > 5;

  contentsWithSkills.forEach((content) => {
    content.skills.forEach((skill) => {
      uniqueSkillIds.add(skill.skillLevel2Id);
      if (shouldConsiderSk1AndSk3) {
        uniqueSkillIds.add(skill.skillLevel3Id);
        uniqueSkillIds.add(skill.skillLevel1Id);
      }
    });
  });

  const uniqueSkills = Array.from(uniqueSkillIds).map((id) => {
    return (
      lookup1[id as number] || lookup2[id as number] || lookup3[id as number]
    );
  });

  return uniqueSkills;
};

export const sortSkillsBySearchTerm = (uniqueSkills, searchTerm: string) => {
  return uniqueSkills.sort((a, b) => {
    const indexA = a.name.toLowerCase().indexOf(searchTerm.toLowerCase());
    const indexB = b.name.toLowerCase().indexOf(searchTerm.toLowerCase());

    if (indexA !== -1 && indexB !== -1) {
      return indexA - indexB;
    }

    if (indexA !== -1) {
      return -1;
    }
    if (indexB !== -1) {
      return 1;
    }

    return 0;
  });
};

export const parentIdToSk1IdMap = {
  1: [1, 2, 3, 4, 5],
  2: [6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
  3: [17, 18, 19, 20, 21, 22, 23, 24],
};

export const getSk1IdFromParentId = (parentId) => {
  for (const [sk1Id, parentIdList] of Object.entries(parentIdToSk1IdMap)) {
    if (parentIdList.includes(parentId)) {
      return parseInt(sk1Id);
    }
  }
  return null;
};
