import React from "react";
import {
  HistoryActivityProps,
  HistoryTableRowProps,
} from "../../../interfaces/analytics-interfaces/history-interface";

import "./history-table.scss";
import {
  CircleGreyIconDetails,
  CircleGreenIconDetails,
  CircleYellowIconDetails,
  CircleRedIconDetails,
} from "../../../constants/history-constants";
import { getThemeDetails } from "../../../helper/analytics-helpers/recommendations-helpers/recommendations-helper";

const Activity: React.FC<HistoryActivityProps> = (
  props: HistoryActivityProps
) => {
  return (
    <div className="activity-info-container">
      <img
        src={props.categoryIcon.src}
        alt={props.categoryIcon.alt}
        className="category-icon"
      />
      <div>
        <span
          className="activity-info-container__title"
          style={{ color: `#${props.themeColor}` }}
        >
          {props.title}
        </span>
      </div>
    </div>
  );
};

const HistoryTableRow: React.FC<HistoryTableRowProps> = ({
  activityTitle,
  activityFormat,
  activityCategoryIcon,
  activityTime,
  activityStatus,
  activityGoal,
  childactivityFeedback,
}) => {
  const themeDetails = getThemeDetails(activityCategoryIcon.alt);
  const themeColor = themeDetails.color;

  // Convertir le temps d'activité de secondes en minutes
  const activityTimeInMinutes = Math.ceil(activityTime / 60);

  // Trouver la couleur du cercle en fonction du statut de l'activité
  let statusIconDetails;
  switch (activityStatus) {
    case "unknown":
    case "todo":
      statusIconDetails = CircleGreyIconDetails;
      break;
    case "doing":
      statusIconDetails = CircleYellowIconDetails;
      break;
    case "done":
      statusIconDetails = CircleGreenIconDetails;
      break;
    default:
      statusIconDetails = CircleGreyIconDetails;
      break;
  }

  // Vérifier si l'objectif est atteint
  const [current, max] = activityGoal.split("/");
  const isGoalReached =
    activityGoal !== "0/0" && Number(current) >= Number(max);
  const goalStyle = isGoalReached ? { color: "green" } : {};

  // Feedback
  let feedbackIcons = [
    CircleGreyIconDetails,
    CircleGreyIconDetails,
    CircleGreyIconDetails,
  ];
  switch (childactivityFeedback) {
    case 1:
      feedbackIcons[0] = CircleGreenIconDetails;
      break;
    case 2:
      feedbackIcons[1] = CircleYellowIconDetails;
      break;
    case 3:
      feedbackIcons[2] = CircleRedIconDetails;
      break;
    default:
      break;
  }

  const displayGoal = activityGoal ? activityGoal : "0/0";

  return (
    <tr className="history-table__row">
      <td className="history-table__cell--activity">
        <Activity
          title={activityTitle}
          categoryIcon={activityCategoryIcon}
          themeColor={themeColor}
        />
      </td>
      <td
        className="history-table__cell--time"
        style={{ color: `#${themeColor}` }}
      >
        <p>{activityTimeInMinutes}</p>
      </td>
      <td className="history-table__cell--status">
        <img
          className="recommendation-table__header--icon"
          src={statusIconDetails.src}
          alt={statusIconDetails.alt}
        />
      </td>
      <td className="history-table__cell--goal">
        <p style={goalStyle}>{displayGoal}</p>
      </td>
      <td className="history-table__cell--feedback">
        {feedbackIcons.map((icon, index) => (
          <img
            key={index}
            className="recommendation-table__header--icon"
            src={icon.src}
            alt={icon.alt}
          />
        ))}
      </td>
    </tr>
  );
};

export default HistoryTableRow;
