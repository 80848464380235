import React from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import { IFormConfig } from '../../../pages/auth/account-validation';

import "./single-form.scss";

interface SingleFormProps extends IFormConfig {
  register: any;
  errors: any;
}

const SingleForm: React.FC<SingleFormProps> = ({
  labelText,
  inputType,
  inputValue,
  setValue,
  isRequired,
  isEditable,
  register,
  errors,
}) => {
  const { t, i18n } = useTranslation();

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="single-form-container">
      <label htmlFor={labelText}>{t("settingsPage." + labelText)} : </label>
      <input
        type={inputType}
        value={inputValue}
        onChange={handleChange}
        required={isRequired}
        disabled={!isEditable}
        id={labelText}
        {...register} // Si nécessaire, vous pouvez propager ces props ici
      />
    </div>
  );
};


export default SingleForm;
