import "./legal-pages.scss";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";

function Terms() {
    const {t, i18n} = useTranslation();
    const [language, setLanguage] = useState(i18n.language);

    useEffect(() => {
        const handleLanguageChange = () => {
            setLanguage(i18n.language);
        };

        i18n.on('languageChanged', handleLanguageChange);

        return () => {
            i18n.off('languageChanged', handleLanguageChange);
        };
    }, [i18n]);

    return (
        <div className="content-page--legal">
            <h1>{t('terms.title')}</h1>
            <h3>{t('terms.effectiveDate')}</h3>
            <h3>{t('terms.lastUpdated')}</h3>

            <h2>{t('terms.introduction.title')}</h2>
            <p>{t('terms.introduction.text')}</p>

            <h2>{t('terms.acceptance.title')}</h2>
            <p>{t('terms.acceptance.text1')}</p>
            <p>{t('terms.acceptance.text2')}</p>

            <h2>{t('terms.modifications.title')}</h2>
            <p>{t('terms.modifications.text')}</p>

            <h2>{t('terms.userAccounts.title')}</h2>
            <h3>{t('terms.userAccounts.accountCreation.title')}</h3>
            <p>{t('terms.userAccounts.accountCreation.text')}</p>

            <h3>{t('terms.userAccounts.accountSecurity.title')}</h3>
            <p>{t('terms.userAccounts.accountSecurity.text')}</p>

            <h3>{t('terms.userAccounts.accountTermination.title')}</h3>
            <p>
                {t('terms.userAccounts.accountTermination.text')} 
                <a href="mailto:contact@wytopia.org">contact@wytopia.org</a>
            </p>

            <h2>{t('terms.intellectualProperty.title')}</h2>
            <h3>{t('terms.intellectualProperty.contentOwnership.title')}</h3>
            <p>{t('terms.intellectualProperty.contentOwnership.text')}</p>
            <ul>
                <li>{t('terms.intellectualProperty.contentList.wytopiaOwned')}</li>
                <li>{t('terms.intellectualProperty.contentList.thirdParty')}</li>
                <li>{t('terms.intellectualProperty.contentList.contentUseRestrictions')}</li>
                <li>
                    {t('terms.intellectualProperty.contentList.permissionsDisclaimer')}
                    <a href="mailto:contact@wytopia.org">contact@wytopia.org</a>
                </li>
                <li>{t('terms.intellectualProperty.contentList.acknowledgmentSources')}</li>
            </ul>
            <p>
                {t('terms.intellectualProperty.contentAttributionLink')}
                <Link to="/content-attribution">Content Attribution page</Link>.
            </p>
            <h3>{t('terms.userFeedback.title')}</h3>
            <p>{t('terms.userFeedback.text')}</p>

            <h2>{t('terms.privacyPolicy.title')}</h2>
            <p>{t('terms.privacyPolicy.text')}</p>

            <h2>{t('terms.onlineSafety.title')}</h2>
            <h3>{t('terms.onlineSafety.communityStandards.title')}</h3>
            <p>{t('terms.onlineSafety.communityStandards.text')}</p>
            <h3>{t('terms.onlineSafety.parentalControls.title')}</h3>
            <p>{t('terms.onlineSafety.parentalControls.text')}</p>

            <h2>{t('terms.limitationsOfLiability.title')}</h2>
            <p>{t('terms.limitationsOfLiability.text')}</p>

            <h2>{t('terms.indemnification.title')}</h2>
            <p>{t('terms.indemnification.text')}</p>

            <h2>{t('terms.disputeResolution.title')}</h2>
            <p>{t('terms.disputeResolution.text')}</p>

            <h2>{t('terms.governingLaw.title')}</h2>
            <p>{t('terms.governingLaw.text')}</p>

            <h2>{t('terms.changesToService.title')}</h2>
            <p>{t('terms.changesToService.text')}</p>

            <h2>{t('terms.terminationOfService.title')}</h2>
            <p>{t('terms.terminationOfService.text')}</p>

            <h2>{t('terms.severability.title')}</h2>
            <p>{t('terms.severability.text')}</p>

            <h2>{t('terms.contactInformation.title')}</h2>
            <p>{t('terms.contactInformation.text')}
                <a href="mailto:contact@wytopia.org">contact@wytopia.org</a>.
            </p>
        </div>
    );
}

export default Terms;
