import { useEffect, useState } from "react";

import { useAnalytics } from "../pages/analytics/analytics-context";
import {
  UseContentFeedbackAverageOutput,
  ContentsFeedback,
} from "../interfaces/recommendations-interfaces/recommendations-interface";
import { calculateContentFeedback } from "../helper/analytics-helpers/recommendations-helpers/recommendations-helper";

export const useContentFeedbackAverage =
  (): UseContentFeedbackAverageOutput => {
    const { contentsFeedbackData, fetchContentsFeedback } = useAnalytics();

    const [allRequestsFetched, setAllRequestsFetched] =
      useState<boolean>(false);
    const [
      isProcessingContentFeedbackAverage,
      setIsProcessingContentFeedbackAverage,
    ] = useState<boolean>(false);
    const [contentsFeedback, setContentsFeedback] = useState<
      ContentsFeedback[]
    >([]);

    useEffect(() => {
      Promise.all([fetchContentsFeedback()]).then(() => {
        setAllRequestsFetched(true);
      });
    }, []);

    useEffect(() => {
      if (allRequestsFetched) {
        setIsProcessingContentFeedbackAverage(true);

        const calculatedContentsFeedback =
          calculateContentFeedback(contentsFeedbackData);

        setIsProcessingContentFeedbackAverage(false);
        setContentsFeedback(calculatedContentsFeedback);
      }
    }, [allRequestsFetched, contentsFeedbackData]);

    return {
      isProcessingContentFeedbackAverage:
        isProcessingContentFeedbackAverage || !allRequestsFetched,
      contentsFeedback,
    };
  };
