import { Chart } from "chart.js";
import chroma from "chroma-js";

export const createOrUpdateChart = (chartRef, chartInstance, config) => {
  if (chartRef.current) {
    const ctx = chartRef.current.getContext("2d");
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    chartInstance.current = new Chart(ctx, config);
  }
};

export const generateRadarChartConfig = (
  data: number[],
  labels: string[],
  skillThemeColor: string,
  suggestedMax: number
) => {
  return {
    type: "radar" as const,
    data: {
      labels,
      datasets: [
        {
          label: "",
          data,
          fill: true,
          backgroundColor: chroma(skillThemeColor).alpha(0.65).css(),
          borderColor: chroma(skillThemeColor).alpha(1).css(),
          pointRadius: 0,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        datalabels: {
          formatter: () => {
            return null;
          },
        },
        legend: {
          display: false,
        },
      },
      scales: {
        r: {
          suggestedMax,
          ticks: {
            display: false,
          },
        },
      },
      elements: {
        line: {
          borderWidth: 2,
        },
      },
    },
  };
};

export const generateBarChartConfig = (
  humanReadableLabels: string[],
  dataset: number[],
  colors: string[]
) => {
  return {
    labels: humanReadableLabels,
    datasets: [
      {
        label: "",
        data: dataset,
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 1,
        borderRadius: 10,
      },
    ],
  };
};

export const generateBarChartOptions = (dataset: number[]) => {
  return {
    plugins: {
      datalabels: {
        align: "center" as const,
        anchor: "center" as const,
        // offset: -20,
        color: "#FFFFFF",
        // color: "#000000",
        font: {
          size: 10,
          // weight: "bold" as const,
        },
        formatter: (value: number, context: any) => {
          const index = context.dataIndex;
          const prevValue = index === 0 ? 0 : dataset[index - 1];
          const diff = value - prevValue;

          if (value === 0) {
            return null;
          }
          return `+${diff}`;
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
};
