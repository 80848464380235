import URLS from "../../config/api-urls";

export interface ChildIdRequestBody {
  ChildId: number;
}

export const fetchChildActivityTimeApi = async (
  body: ChildIdRequestBody
): Promise<any> => {
  const url = URLS.POST_GetChildActivityTimes;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

export const fetchChildQuizAnswersApi = async (
  body: ChildIdRequestBody
): Promise<any> => {
  const url = URLS.POST_GetChildQuizHistories;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

export const fetchChildFeedbacksApi = async (
  body: ChildIdRequestBody
): Promise<any> => {
  const url = URLS.POST_GetChildFeedbacks;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

export const fetchGameSettingsApi = async (): Promise<any> => {
  const url = URLS.POST_GetContentGameSettings;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: null,
    });

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

export const fetchChildSessionTimeApi = async (
  body: ChildIdRequestBody
): Promise<any> => {
  const url = URLS.POST_GetChildSessionTime;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

export const fetchAllChildSessionTimeApi = async (): Promise<any> => {
  const url = URLS.POST_GetAllChildSessionTime;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: null,
    });

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};
