import DetailIconViolet from "../../../resources/images/icons/oeil_violet.png";
import DetailIconBlue from "../../../resources/images/icons/oeil_bleu.png";
import DetailIconRose from "../../../resources/images/icons/oeil_rose.png";
import DetailIconYellow from "../../../resources/images/icons/oeil_jaune.png";
import { FilterType } from "../../../interfaces/skill-map-interfaces/skill-map-interface";

export const updateThemeColor = (filterType: FilterType): string => {
  let newThemeColor = "#823d90";
  if (filterType === "Level1Skill1") {
    newThemeColor = "#59c0d1";
  } else if (filterType === "Level1Skill2") {
    newThemeColor = "#e8318b";
  } else if (filterType === "Level1Skill3") {
    newThemeColor = "#e6b10d";
  }

  return newThemeColor;
};

export const getDetailIconByThemeColor = (themeColor: string) => {
  switch (themeColor) {
    case "#823d90":
      return DetailIconViolet;
    case "#59c0d1":
      return DetailIconBlue;
    case "#e8318b":
      return DetailIconRose;
    case "#e6b10d":
      return DetailIconYellow;
    default:
      return DetailIconViolet;
  }
};
