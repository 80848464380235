import React, { useState } from "react";
// import { useController } from "react-hook-form";

import { useTranslation } from "react-i18next";

import "./select-form.scss";

const SelectForm = (props) => {
  const { t, i18n } = useTranslation();

  const { labelText, inputValue, choices, isRequired, setValue } = props;

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className="single-select-container">
      <label>{t("settingsPage." + labelText)} : </label>
      <select
        className="access-select"
        value={inputValue}
        onChange={handleChange}
      >
        {choices.map((choice, i) => (
          <option key={i} value={choice}>
            {t("settingsPage.selects." + choice.toLowerCase())} { }
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectForm;
