import URLS from "../../config/api-urls";

export interface ContentsRequestBody {
  SourceLanguageId: number;
}

export const fetchContentsApi = async (
  body: ContentsRequestBody
): Promise<any> => {
  const url = URLS.POST_GetContents;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    });

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

export const fetchContentsFeedbackApi = async (): Promise<any> => {
  const url = URLS.POST_GetContentsFeedbacks;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: null,
    });

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

export const fetchContentsStatisticsApi = async (): Promise<any> => {
  const url = URLS.POST_GetContentStatistics;
  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: null,
    });

    const result = await response.json();
    return result;
  } catch (error) {
    throw error;
  }
};

