import { useState } from "react";
import { useTranslation } from "react-i18next";

import "./footer-analytics.scss";

import gbFlagIcon from "../../resources/images/flags/united_kingdom.png";
import frFlagIcon from "../../resources/images/flags/france.png";
import deFlagIcon from "../../resources/images/flags/germany.png";

function FooterAnalytics() {
  const { i18n } = useTranslation();
  const [languageMenuOpen, setLanguageMenuOpen] = useState(false);

  const toggleLanguageMenu = () => {
    setLanguageMenuOpen(!languageMenuOpen);
  };

  const languageFlags = {
    en: gbFlagIcon,
    fr: frFlagIcon,
    de: deFlagIcon,
  };

  const renderLanguageButton = () => {
    return (
      <button className="btn-lang-analytics" onClick={toggleLanguageMenu}>
        <img src={languageFlags[i18n.language]} alt={i18n.language} />
        {languageMenuOpen && (
          <ul className="menu-user-lang">{renderLanguageOptions()}</ul>
        )}
      </button>
    );
  };

  const renderLanguageOptions = () => {
    const languages = ["fr", "en", "de"];
    return languages.map(
      (lang) =>
        i18n.language !== lang && (
          <li
            key={lang}
            className="menu-user-item-lang"
            onClick={() => {
              i18n.changeLanguage(lang);
              toggleLanguageMenu();
            }}
          >
            <img src={languageFlags[lang]} alt={lang} />
          </li>
        )
    );
  };

  return (
    <footer className="footer-analytics">
      <div className="footer-analytics-container">
        <div className="row">
          <div className="col-md-4">
            {/* <h3>Contact</h3>
            <p>
              Adresse : 12 rue du Test, 75000 Paris
              <br />
              Téléphone : 01 02 03 04 05
              <br />
              Email : contact@entreprise.com
            </p> */}
          </div>
          <div className="col-md-4">
            {/* <h3>Suivez-nous</h3>
            <ul className="social-icons">
              <li>
                <a href="#">
                  <i className="fa fa-twitter"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="#">
                  <i className="fa fa-linkedin"></i>
                </a>
              </li>
            </ul> */}
          </div>
          <div className="col-md-4">
            {/*  <h3>Newsletter</h3>
            <p>
              Inscrivez-vous à notre newsletter pour recevoir nos dernières
              actualités et offres spéciales.
            </p>
            <form>
              <div className="form-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Entrez votre adresse email"
                />
              </div>
              <button type="submit" className="btn btn-primary">
                S'inscrire
              </button>
            </form>*/}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <hr />
            <p className="copyright">
              Copyright &copy; Wytopia. Tous droits réservés.
            </p>
          </div>
          <div className="col-md-12 locale">{renderLanguageButton()}</div>
        </div>
      </div>
    </footer>
  );
}

export default FooterAnalytics;
