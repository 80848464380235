import { IconProps } from "../components/detailCard/detail-card";
import ScreenTimeIcon from "../resources/images/icons/tps-ecran_violet.png";
import SleepModeIcon from "../resources/images/icons/veille_violet.png";
import AlertIcon from "../resources/images/icons/cloche_violet.png";
import LockIcon from "../resources/images/icons/cadenas_violet.png";
import CheckIcon from "../resources/images/icons/check_blanc.png";

export const ScreenTimeIconDetails: IconProps = {
  src: ScreenTimeIcon,
  alt: "Screen time icon",
};
export const SleepModeIconDetails: IconProps = {
  src: SleepModeIcon,
  alt: "Sleep mode icon",
};
export const AlertIconDetails: IconProps = {
  src: AlertIcon,
  alt: "Alert icon",
};
export const LockIconDetails: IconProps = {
  src: LockIcon,
  alt: "Lock icon",
};
export const CheckIconDetails: IconProps = {
  src: CheckIcon,
  alt: "Check icon",
};
