import {
  MEDIA_FORMATS,
  GAME_FORMATS,
} from "../../constants/activities-constants";
import {
  HistoryData,
  MergedSkillContentData,
} from "../../interfaces/analytics-interfaces/history-interface";

export const filterAndSortSkillActivitiesData = (
  historyData: HistoryData[],
  historySkillProgressData: MergedSkillContentData[]
): MergedSkillContentData[] => {
  if (historyData.length === 0 || historySkillProgressData.length === 0) {
    return [];
  }

  const contentIdsFromHistory = historyData.map((h) => h.contentId);
  const filteredData = historySkillProgressData.filter((m) =>
    contentIdsFromHistory.includes(m.contentId)
  );

  const uniqueSkills: { [key: string]: MergedSkillContentData } = {};
  filteredData.forEach((data) => {
    const uniqueKey = `${data.skillId}-${data.skillLevel}`;
    if (!uniqueSkills[uniqueKey]) {
      uniqueSkills[uniqueKey] = data;
    }
  });

  const uniqueSkillsArray = Object.values(uniqueSkills);
  return uniqueSkillsArray.sort((a, b) => b.skillProgress - a.skillProgress);
};

export const getGameVsMediaProgress = (historyData: HistoryData[]) => {
  const gameActivities = historyData.filter(
    (data) => data.activityFormat === "Games" || data.activityFormat === "Game"
  );

  const gamePercentage = Math.round(
    (gameActivities.length / historyData.length) * 100
  );
  const mediaPercentage = 100 - gamePercentage;

  return {
    gamePercentage,
    mediaPercentage,
  };
};

export const calculateCompletionPercentage = (
  historyData: HistoryData[],
  conditionCheck: (data: HistoryData) => boolean
): number => {
  const completedActivities = historyData.filter(conditionCheck);
  const totalActivities = historyData.length;
  return (completedActivities.length / totalActivities) * 100;
};

export const aggregateSkillsByContentFormat = (
  skillProgressData: MergedSkillContentData[],
  contentFormats: string[]
) => {
  const filteredSkills = skillProgressData.filter((data) =>
    contentFormats.includes(data.contentFormat)
  );

const aggregatedSkills = filteredSkills.reduce((accumulator, current) => {
  if (accumulator[current.contentCategory]) {
    accumulator[current.contentCategory].skillProgress += current.skillProgress;
    accumulator[current.contentCategory].itemCount =
      (accumulator[current.contentCategory].itemCount || 0) + 1;
  } else {
    accumulator[current.contentCategory] = { ...current, itemCount: 1 };
  }
  return accumulator;
}, {});


  const totalSkillProgress = Object.values(aggregatedSkills).reduce<number>(
    (total, current: any) => total + current.skillProgress,
    0
  );

  const adjustedSkills = (Object.values(aggregatedSkills) as any[]).map(
    (skill: any) => ({
      ...skill,
      skillProgress: (skill.skillProgress / totalSkillProgress) * 100,
    })
  );

  return adjustedSkills.sort((a, b) => b.skillProgress - a.skillProgress);
};

export const getCorrectQuizAndMediaSkillData = (
  historyData: HistoryData[],
  skillProgressData: MergedSkillContentData[]
) => {
  const correctQuizPercentage = calculateCompletionPercentage(
    historyData,
    (data) =>
      data.activityQuizExpectedAnswers &&
      data.childQuizCorrectAnswers === data.activityQuizExpectedAnswers
  );
  const mediaSkills = aggregateSkillsByContentFormat(
    skillProgressData,
    MEDIA_FORMATS
  );

  return { correctQuizPercentage, mediaSkills };
};

export const getCompletedGameAndGameSkillData = (
  historyData: HistoryData[],
  skillProgressData: MergedSkillContentData[]
) => {
  const completedGamesPercentage = calculateCompletionPercentage(
    historyData,
    (data) =>
      data.activityGameLevelToReach &&
      data.childGameProgressLevel === data.activityGameLevelToReach
  );
  const gameSkills = aggregateSkillsByContentFormat(
    skillProgressData,
    GAME_FORMATS
  );

  return { completedGamesPercentage, gameSkills };
};
