import { useEffect, useState } from "react";
import { useAnalytics } from "../analytics-context";
import "./admin.scss";

const WBL = [
  39,
  48,
  184,
  224,
  ...Array.from({ length: 11 }, (_, i) => 229 + i),
];
const ABL = [185, 186, 197, 241];
const GBL = [3, 44, 71, 177, 211, 222, 227];

const CombinedBlacklist = [...WBL, ...ABL, ...GBL];

const Admin = () => {
  const {
    isLoading,
    error,
    fetchAllChildSessionTime,
    allChildSessionTimeData,
  } = useAnalytics();
  const [allRequestsFetched, setAllRequestsFetched] = useState<boolean>(false);
  const [connectedUserDay, setConnectedUserDay] = useState("");
  const [connectedUserWeek, setConnectedUserWeek] = useState("");
  const [connectedUserMonth, setConnectedUserMonth] = useState("");
  const [connectedUserYear, setConnectedUserYear] = useState("");

  useEffect(() => {
    Promise.all([fetchAllChildSessionTime()]).then(() => {
      setAllRequestsFetched(true);
    });
  }, []);

  const isWithinPeriod = (date, days) => {
    const now = new Date().getTime();
    const sessionDate = new Date(date).getTime();
    return (now - sessionDate) / (1000 * 3600 * 24) <= days;
  };

  const formatUserStats = (userSet) => {
    return `${userSet.size} (${[...userSet].join(", ")})`;
  };

  useEffect(() => {
    if (
      allChildSessionTimeData &&
      Array.isArray(allChildSessionTimeData.object)
    ) {
      const uniqueUsersToday = new Set();
      const uniqueUsersThisWeek = new Set();
      const uniqueUsersThisMonth = new Set();
      const uniqueUsersThisYear = new Set();

      allChildSessionTimeData.object.forEach((data) => {
        if (!CombinedBlacklist.includes(data.childId)) {
          if (isWithinPeriod(data.startDateSession, 1)) {
            uniqueUsersToday.add(data.childId);
          }
          if (isWithinPeriod(data.startDateSession, 7)) {
            uniqueUsersThisWeek.add(data.childId);
          }
          if (isWithinPeriod(data.startDateSession, 30)) {
            uniqueUsersThisMonth.add(data.childId);
          }
          if (isWithinPeriod(data.startDateSession, 365)) {
            uniqueUsersThisYear.add(data.childId);
          }
        }
      });

      setConnectedUserDay(formatUserStats(uniqueUsersToday));
      setConnectedUserWeek(formatUserStats(uniqueUsersThisWeek));
      setConnectedUserMonth(formatUserStats(uniqueUsersThisMonth));
      setConnectedUserYear(formatUserStats(uniqueUsersThisYear));
    }
  }, [allChildSessionTimeData]);

  if (!allRequestsFetched) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content-page--admin">
      <h1>Stats :</h1>
      <p>Today: {connectedUserDay}</p>
      <p>This Week: {connectedUserWeek}</p>
      <p>This Month: {connectedUserMonth}</p>
      <p>This Year: {connectedUserYear}</p>
    </div>
  );
};

export default Admin;
