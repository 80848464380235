import React from "react";
import {useTranslation} from "react-i18next";
import {RotatingTriangles} from "react-loader-spinner";

import {useAnalytics} from "../analytics-context";

import "./rewards.scss";
import "../analytics.scss";

import goldCoinImage from "../../../resources/images/rewards/golds.png";
import cityMapImage from "../../../resources/images/rewards/map_minia.png";

import DetailCard from "../../../components/detailCard/detail-card";
import {useRewardData} from "../../../hooks/use-reward-data";
import {
    ChildBaseModel,
} from "../../../interfaces/child-interfaces/child-interface";
import RewardContainer from "../../../components/analytics/reward/reward-container";
import {getLanguageNumber} from "../../../helper/common-helpers/language-helper";
import DoubleDetailCard from "../../../components/detailCard/detail-card-double";
import {ReferentBaseModel} from "../../../interfaces/referent-interfaces/parents-interface";

interface RewardsProps {
    currentChildSelected: ChildBaseModel | null;
    referentData: ReferentBaseModel | null;
}
const Rewards: React.FC<RewardsProps> = (props: RewardsProps) => {
    const { t, i18n } = useTranslation();
    const actualLanguage = i18n.language;
    const languageNumber = getLanguageNumber(actualLanguage);
    const { isLoading, error } = useAnalytics();

    const childId = props.currentChildSelected?.childId || null;

    const {
        isProcessingRewardData,
        contentRewards,
        childRewards,
        goldEarned,
        goldUsed,
        percentageCompleted,
        error: rewardError,
    } = useRewardData({
        childId: childId!,
        actualLanguage: languageNumber,
    });

    // Function to calculate percentage and format text
    const getProgressText = (unlocked: number, total: number) => {
        const percentage = total > 0 ? Math.round((unlocked / total) * 100) : 0;
        return `${unlocked}/${total} (${percentage}%)`;
    };

    if (!childId) {
        return <p>{t("analyticsPages.rewards_page.select_child")}</p>;
    }
    
    return (
        <>
            {error || rewardError ? (
                <p style={{ color: "red" }}>
                    Error: {error?.message || rewardError?.message}
                </p>
            ) : null}
            {isLoading || isProcessingRewardData ? (
                <div style={{ alignSelf: "center" }}>
                    <RotatingTriangles
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="rotating-triangels-loading"
                        wrapperClass="rotating-triangels-wrapper"
                        colors={["#018cd5", "#de2781", "#f19700"]}
                    />
                </div>
            ) : (
                <div className="analytics__stats-content">
                    
                    {/* Double DetailCard for Golds and City Progression */}
                    <div className="analytics__stats-content--double-card">
                        <DoubleDetailCard
                            leftCardProps={{
                                hasHeader: true,
                                headerTitle: t("analyticsPages.rewards_page.gold"),
                                themeColor: "#4CAF50",
                                children: (
                                    <div className="gold-balance-content">
                                        <img
                                            className="gold-balance-content__coin"
                                            src={goldCoinImage}
                                            alt="Coin"
                                        />
                                        {/* Current Gold Balance */}
                                        <p className="gold-balance-content__current-balance">
                                            {goldEarned + Math.abs(goldUsed)}
                                        </p>
                                        {/* Gold Earned and Used */}
                                        <p className="gold-balance-content__earned">+ {goldEarned}</p>
                                        <p className="gold-balance-content__used">- {Math.abs(goldUsed)}</p>
                                    </div>
                                ),
                            }}
                            rightCardProps={{
                                hasHeader: true,
                                headerTitle: "City 1",
                                themeColor: "#823d90",
                                children: (
                                    <div className="city-progress-content">
                                        <img
                                            className="city-progress-content__image"
                                            src={cityMapImage}
                                            alt="City"
                                        />
                                        <p className="city-progress-content__percentage">
                                            {percentageCompleted}% {t("analyticsPages.rewards_page.completed")}
                                        </p>
                                    </div>
                                ),
                            }}
                        />
                    </div>
                    
                    {/* DetailCard pour les Badges */}
                    <div className="analytics__stats-content--card">
                        <DetailCard
                            hasHeader={true}
                            headerTitle={t("analyticsPages.rewards_page.badges_title")}
                            themeColor="#823d90"
                            iconRight={
                                <div className="rewards-progress">
                                    {getProgressText(
                                        childRewards.unlockedBadges.length,
                                        contentRewards.badges.length
                                    )}
                                </div>
                            }
                        >
                            <RewardContainer
                                items={childRewards.unlockedBadges.map((badge) => ({
                                    id: badge.id,
                                    name: badge.title,
                                    imageSrc: badge.path,
                                }))}
                                noRewardsText={t("analyticsPages.rewards_page.no_rewards_badges")}
                            />
                        </DetailCard>
                    </div>


                    {/* DetailCard pour les Amis */}
                    <div className="analytics__stats-content--card">
                        <DetailCard
                            hasHeader={true}
                            headerTitle={t("analyticsPages.rewards_page.friends_title")}
                            themeColor="#823d90"
                            iconRight={
                                <div className="rewards-progress">
                                    {getProgressText(
                                        childRewards.unlockedFriends.length,
                                        contentRewards.friends.length
                                    )}
                                </div>
                            }
                        >
                            <RewardContainer
                                items={childRewards.unlockedFriends.map((friend) => {
                                    const avatar = contentRewards.avatars.find(
                                        (avatar) => avatar.avatarID === friend.avatarId
                                    );
                                    return {
                                        id: friend.id,
                                        name: friend.name,
                                        imageSrc: avatar?.path || '',
                                    };
                                })}
                                noRewardsText={t("analyticsPages.rewards_page.no_rewards_friends")}
                            />
                        </DetailCard>
                    </div>


                    {/* DetailCard pour les Items */}
                    <div className="analytics__stats-content--card">
                        <DetailCard
                            hasHeader={true}
                            headerTitle={t("analyticsPages.rewards_page.items_title")}
                            themeColor="#823d90"
                            iconRight={
                                <div className="rewards-progress">
                                    {getProgressText(
                                        childRewards.unlockedItems.length,
                                        contentRewards.items.length
                                    )}
                                </div>
                            }
                        >
                            <RewardContainer
                                items={childRewards.unlockedItems.map((item) => ({
                                    id: item.id,
                                    name: item.title,
                                    imageSrc: item.path,
                                }))}
                                noRewardsText={t("analyticsPages.rewards_page.no_rewards_items")}
                            />
                        </DetailCard>
                    </div>

                    {/* DetailCard pour les Avatars */}
                    <div className="analytics__stats-content--card">
                        <DetailCard
                            hasHeader={true}
                            headerTitle={t("analyticsPages.rewards_page.avatars_title")}
                            themeColor="#823d90"
                            iconRight={
                                <div className="rewards-progress">
                                    {getProgressText(
                                        childRewards.unlockedAvatars.length,
                                        contentRewards.avatars.length
                                    )}
                                </div>
                            }
                        >
                            <RewardContainer
                                items={childRewards.unlockedAvatars.map((avatar) => ({
                                    id: avatar.avatarID,
                                    name: avatar.name,
                                    imageSrc: avatar.path,
                                    showName: false,
                                }))}
                                noRewardsText={t("analyticsPages.rewards_page.no_rewards_avatars")}
                            />
                        </DetailCard>
                    </div>

                    {/* DetailCard pour les Stickers */}
                    <div className="analytics__stats-content--card">
                        <DetailCard
                            hasHeader={true}
                            headerTitle={t("analyticsPages.rewards_page.stickers_title")}
                            themeColor="#823d90"
                            iconRight={
                                <div className="rewards-progress">
                                    {getProgressText(
                                        childRewards.unlockedStickers.length,
                                        contentRewards.stickers.length
                                    )}
                                </div>
                            }
                        >
                            <RewardContainer
                                items={childRewards.unlockedStickers.map((sticker) => ({
                                    id: sticker.id,
                                    name: sticker.title,
                                    imageSrc: sticker.path,
                                }))}
                                noRewardsText={t("analyticsPages.rewards_page.no_rewards_stickers")}
                            />
                        </DetailCard>
                    </div>

                </div>
            )
            }
        </>
    )
        ;
};

export default Rewards;
