const API_URL = "https://wytopiaapi2.azurewebsites.net";

const URLS = {
  //Referent
  POST_NewParent: API_URL + "/api/NewParent",
  POST_UpdateParent: API_URL + "/api/UpdateParent",
  POST_GetReferent: API_URL + "/api/GetParent",

  // Login
  POST_ConnectWeb: API_URL + "/api/ConnectWeb",
  POST_AskResetPasswordParent: API_URL + "/api/ResetPassword",
  POST_AskResetPasswordChild: API_URL + "/api/ResetChildPassword",
  POST_ReinitPasswordParent: API_URL + "/api/ReinitPassword",
  POST_ReinitPasswordChild: API_URL + "/api/ReinitChildPassword",
  POST_ResendParentEmail: API_URL + "/api/ResendParentEmail",

  // Contents
  POST_GetSkillsStatistics: API_URL + "/api/GetSkillsStatistics",
  POST_GetContentSkills: API_URL + "/api/GetContentSkills",
  POST_GetContents: API_URL + "/api/GetContents",
  POST_GetContentsFeedbacks: API_URL + "/api/GetContentsFeedbacks",
  POST_GetContentGameSettings: API_URL + "/api/GetContentGameSettings",
  POST_GetContentStatistics: API_URL + "/api/GetContentStatistics",
  
  // Child
  POST_NewChild: API_URL + "/api/NewChild",
  POST_UpdateChild: API_URL + "/api/UpdateChild",
  POST_GetChilds: API_URL + "/api/GetChilds",
  POST_Skills: API_URL + "/api/GetSkills",
  POST_ChildGameProgresses: API_URL + "/api/GetChildGameProgresses",
  POST_GetChildContentProgresses: API_URL + "/api/GetChildContentProgresses",
  POST_ChildSkillHistory: API_URL + "/api/GetChildSkillHistorical",
  POST_NewChildRecommendation: API_URL + "/api/NewChildRecommendation",
  POST_GetChildActivityTimes: API_URL + "/api/GetChildActivityTimes",
  POST_GetChildQuizHistories: API_URL + "/api/GetChildQuizHistories",
  POST_GetChildFeedbacks: API_URL + "/api/GetChildFeedbacks",
  POST_GetChildSessionTime: API_URL + "/api/GetChildSessionTime",
  POST_GetAllChildSessionTime: API_URL + "/api/GetAllChildSessionTime",
  // rewards
  POST_GetAvatars: API_URL + "/api/GetAvatars",
  POST_GetBadges: API_URL + "/api/GetBadges",
  POST_GetItems: API_URL + "/api/GetItems",
  POST_GetStickers: API_URL + "/api/GetStickers",
  POST_GetFriends: API_URL + "/api/GetFriends",
  POST_GetChildAvatarRewards: API_URL + "/api/GetChildAvatarRewards",
  POST_GetChildBadgeRewards: API_URL + "/api/GetChildBadgeRewards",
  POST_GetChildItemRewards: API_URL + "/api/GetChildItemRewards",
  POST_GetChildStickerRewards: API_URL + "/api/GetChildStickerRewards",
  POST_GetChildFriends: API_URL + "/api/GetChildFriends",
  // golds
  POST_GetChildTotalGolds: API_URL + "/api/GetChildTotalGolds",
  POST_GetChildGoldBalances: API_URL + "/api/GetChildGoldBalances",
  // settings
  POST_DeleteChild: API_URL + "/api/DeleteChild", 

};

export default URLS;
