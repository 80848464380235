import { IconProps } from "../../../components/detailCard/detail-card";
import {
  ContentsWithSkills,
  SkillData,
} from "../../../interfaces/recommendations-interfaces/recommendations-interface";
import {
  SendIconRedDetails,
  SendIconBlueDetails,
} from "../../../constants/recommendation-constants";
import {
  GAME_FORMATS,
  MEDIA_FORMATS,
} from "../../../constants/activities-constants";

export function aggregateSkillsByLevel1Id(skills: SkillData[]): SkillData[] {
  const skillMap = new Map<number, number>();

  skills.forEach((skill) => {
    const currentPoint = skillMap.get(skill.skillLevel1Id) || 0;
    skillMap.set(skill.skillLevel1Id, currentPoint + skill.skillPoint);
  });

  const aggregatedSkills: SkillData[] = Array.from(skillMap).map(
    ([skillLevel1Id, skillPoint]) => ({
      skillLevel1Id,
      skillLevel2Id: 0,
      skillLevel3Id: 0,
      skillPoint,
    })
  );

  return aggregatedSkills;
}

export const findMostLaggingLevel2Skills = (
  skillLevel2Data,
  skillLevel3Data,
  skillsStatisticsData
) => {
  const level2Gaps = [];

  skillLevel2Data.forEach((level2Skill) => {
    const relatedStats = skillsStatisticsData.object.filter(
      (stat) => stat.parentId === level2Skill.id
    );

    let averageMedian = 0;
    if (relatedStats.length > 0) {
      averageMedian =
        relatedStats.reduce((acc, stat) => acc + (stat.skillMed || 0), 0) /
        relatedStats.length;
    } else {
    }

    const childPoints = skillLevel3Data.reduce(
      (acc, level3Skill) =>
        level3Skill.parentId === level2Skill.id
          ? acc + level3Skill.points
          : acc,
      0
    );

    const gap = childPoints - averageMedian;

    level2Gaps.push({ ...level2Skill, gap });
  });

  const sortedSkills = level2Gaps.sort((a, b) => b.gap - a.gap);
  return sortedSkills;
};

export const groupContentsWithSkills = (contents, skills) => {
  return contents.map((content) => {
    const relatedSkills = skills.filter(
      (skill) => skill.contentId === content.contentId
    );
    return {
      contentId: content.contentId,
      name: content.title,
      format: content.format,
      category: content.typeName,
      locationId: content.locationId,
      skills: relatedSkills.map((skill) => ({
        skillLevel1Id: skill.skillLevel1Id,
        skillLevel2Id: skill.skillLevel2Id,
        skillLevel3Id: skill.skillLevel3Id,
        skillPoint: skill.skillPoint,
      })),
    };
  });
};

export const filterContentsWithoutName = (
  groupedData: ContentsWithSkills[]
): ContentsWithSkills[] => {
  return groupedData.filter(
    (content) => content.name !== null && content.name !== ""
  );
};

export const filterContentsWithoutLocationId = (
  groupedData: ContentsWithSkills[]
): ContentsWithSkills[] => {
  return groupedData.filter((content) => content.locationId !== 0);
};

export const calculateContentFeedback = (contentsFeedbackData) => {
  const contentFeedbackAggregation: {
    [key: number]: { total: number; count: number };
  } = {};

  contentsFeedbackData?.object?.forEach((feedback) => {
    const { contentId, value } = feedback;
    const transformedValue = value === 1 ? 10 : value === 2 ? 5 : 0;

    if (!contentFeedbackAggregation[contentId]) {
      contentFeedbackAggregation[contentId] = { total: 0, count: 0 };
    }

    contentFeedbackAggregation[contentId].total += transformedValue;
    contentFeedbackAggregation[contentId].count += 1;
  });

  return Object.keys(contentFeedbackAggregation).map((contentIdStr) => {
    const contentId = parseInt(contentIdStr, 10);
    const { total, count } = contentFeedbackAggregation[contentId];
    const average = total / count;
    const percentage = Math.round((average / 10) * 100);

    return { contentId, value: average, percentage };
  });
};

export const getFeedbackColor = (feedback: number) => {
  if (feedback >= 60) return "green";
  if (feedback >= 30 && feedback < 60) return "orange";
  return "red";
};

export interface ThemeDetails {
  color: string;
  iconDetails: IconProps;
}

export const getThemeDetails = (activityCategory: string): ThemeDetails => {
  if (GAME_FORMATS.some((format) => activityCategory.includes(format))) {
    return {
      color: "335faa",
      iconDetails: SendIconBlueDetails,
    };
  } else if (
    MEDIA_FORMATS.some((format) => activityCategory.includes(format))
  ) {
    return {
      color: "e7431e",
      iconDetails: SendIconRedDetails,
    };
  }
};
