import React from "react";

import "./history-item.scss";

import {
  CategoryGameIconDetails,
  CategoryVideoIconDetails,
} from "../../../constants/recommendation-constants";
import {
  ThemeDetails,
  getThemeDetails,
} from "../../../helper/analytics-helpers/recommendations-helpers/recommendations-helper";
import { GAME_FORMATS } from "../../../constants/activities-constants";

type HistoryItemProps = {
  thumbnailSrc: string;
  category: string;
  title: string;
};

const HistoryItem: React.FC<HistoryItemProps> = (props: HistoryItemProps) => {
  const themeDetails: ThemeDetails = getThemeDetails(props.category);

  const iconDetails = GAME_FORMATS.includes(props.category)
    ? CategoryGameIconDetails
    : CategoryVideoIconDetails;

  const truncatedTitle = props.title.substring(0, 20);

  return (
    <div className="history-item">
      <img
        className="history-item__icon"
        src={iconDetails.src}
        alt={iconDetails.alt}
      />
      <img
        className="history-item__thumbnail"
        src={props.thumbnailSrc}
        alt="Thumbnail"
      />
      <span
        className="history-item__title"
        style={{ color: `#${themeDetails?.color}` }}
      >
        {truncatedTitle}
      </span>
    </div>
  );
};

export default HistoryItem;
