import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter as Router} from "react-router-dom";

// translation
import "./i18n";

// components
import App from "./App";

// styles
import "./index.css";
import {AuthProvider} from "./pages/auth/auth-context";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <Router>
        <AuthProvider>
            <App/>
        </AuthProvider>
    </Router>
    // </React.StrictMode>
);
