import React from "react";
import { useTranslation } from "react-i18next";
import "./settings-notifications.scss";
import TitleSwitch from "./notifs/title-switch";
import { SettingsNotificationsProps } from "../../../interfaces/settings-interfaces/settings-analytics-interface";

const SettingsNotifications: React.FC<SettingsNotificationsProps> = ({
                                                                         updateNotificationPreference,
                                                                         receiveEventEmails,
                                                                     }) => {
    const { t } = useTranslation();

    // Handle the toggle switch action to update the email notification preference
    const handleNotificationToggle = (isActive: boolean) => {
        updateNotificationPreference(isActive);
    };

    return (
        <div className="analytics-settings-notifications">
            <div className="explanatory-text">
                <span>{t("analyticsPages.settings_page.parent_notifications_title")}</span>
                <br />
            </div>
            <TitleSwitch
                name={t("analyticsPages.settings_page.parent_notifications_text")}
                isTitle={true}
                onSwitchChange={handleNotificationToggle} // Handles the toggle switch action
                sectionChecked={receiveEventEmails} // Current state of the notification switch
            />
        </div>
    );
};

export default SettingsNotifications;
